import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CustomDatepickerI18nService } from '../../services/custom-datepicker-i18n.service';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: [
    './date-filter.component.scss',
    "../../../../assets/sass/libs/datepicker.scss"
  ],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService },
  ],
})
export class DateFilterComponent implements OnInit, OnChanges {
  @Output() dateSelected: EventEmitter<{ selectedDate: string }> = new EventEmitter();
  @Input() reportType: string;

  selectedDate: string;
  showCalendar: boolean = false;
  hoveredDate: NgbDateStruct;
  pickedDate: NgbDateStruct;
  model: NgbDateStruct;
  date: { year: number; month: number };
  displayMonths = 1;
  navigation = "select";
  disabled = true;

  constructor() {
    this.initializeDate();
  }

  private initializeDate() {
    const currentDate = moment();
    if (this.reportType === 'voyage_assigne') {
      const tomorrow = currentDate.add(1, 'day');
      this.selectedDate = tomorrow.format('YYYY-MM-DD');
      this.pickedDate = this.toNgbDate(tomorrow.toDate());
    } else {
      this.selectedDate = currentDate.format('YYYY-MM-DD');
      this.pickedDate = this.toNgbDate(currentDate.toDate());
    }
  }

  ngOnInit() {
    this.initializeDate();
    this.emitSelectedDate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reportType) {
      this.initializeDate();
      this.emitSelectedDate();
    }
  }

  private emitSelectedDate() {
    this.dateSelected.emit({
      selectedDate: this.selectedDate
    });
  }

  toNgbDate(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  formatDate(date: NgbDateStruct): string {
    if (!date) {
      return "";
    }
    return moment({
      year: date.year,
      month: date.month - 1,
      day: date.day,
    }).format("DD-MM-YYYY");
  }

  isDisabled = (date: NgbDateStruct, current: { month: number }): boolean => {
   
    if (date.month !== current.month) {
      return true;
    }

    const currentDate = moment().startOf('day');
    const dateToCheck = moment({
      year: date.year,
      month: date.month - 1,
      day: date.day
    }).startOf('day');

    if (this.reportType === 'voyage_assigne') {
      return dateToCheck.isSameOrBefore(currentDate);
    }

    return false;
  }

  onDateChange(date: NgbDateStruct) {
    if (!date) return;

    const selectedMoment = moment({
      year: date.year,
      month: date.month - 1,
      day: date.day,
    });

    if (this.reportType === 'voyage_assigne') {
      const tomorrow = moment().add(1, 'day').startOf('day');
      if (selectedMoment.isBefore(tomorrow)) {
        this.pickedDate = this.toNgbDate(tomorrow.toDate());
        this.selectedDate = tomorrow.format('YYYY-MM-DD');
      } else {
        this.pickedDate = date;
        this.selectedDate = selectedMoment.format('YYYY-MM-DD');
      }
    } else {
      this.pickedDate = date;
      this.selectedDate = selectedMoment.format('YYYY-MM-DD');
    }

    this.emitSelectedDate();
  }

  areDatesEqual(date1: NgbDateStruct, date2: NgbDateStruct): boolean {
    return date1 && date2 && 
           date1.year === date2.year && 
           date1.month === date2.month && 
           date1.day === date2.day;
  }

  isHovered = (date: NgbDateStruct) =>
    this.pickedDate && 
    this.hoveredDate && 
    this.areDatesEqual(this.hoveredDate, date);

  isFrom = (date: NgbDateStruct) => 
    this.pickedDate && 
    this.areDatesEqual(date, this.pickedDate);

  isTo = (date: NgbDateStruct) => 
    this.hoveredDate && 
    this.areDatesEqual(date, this.hoveredDate);

  openCalendar() {
    this.showCalendar = true;
  }

  selectToday() {
    this.model = this.toNgbDate(new Date());
  }
}