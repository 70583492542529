import { formatDate } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from "@angular/core";
import {NgbCalendar, NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays, subMonths } from "date-fns";
import * as moment from "moment";
import {CustomDatepickerI18nService} from '../../services/custom-datepicker-i18n.service';

const now = new Date();

// Helper functions for date comparison
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;

@Component({
  selector: "app-period-date-filter",
  templateUrl: "./period-date-filter.component.html",
  styleUrls: [
    "./period-date-filter.component.scss",
    "../../../../assets/sass/libs/datepicker.scss",
  ],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService },
  ],
})
export class PeriodDateFilterComponent implements OnInit, OnDestroy {
  @Output() dateRangeSelected: EventEmitter<{
    startDate: string;
    endDate: string;
  }> = new EventEmitter();
  @Input() currentWeek: boolean = false;

  @Input() lastMonthVisible: boolean = true;
  @Input() thisMonthVisible: boolean = true;
  @Input() last30DaysVisible: boolean = true;

  private languageSubscription: Subscription;
  ranges: Array<{label: string, value: NgbDateStruct[], visible?: boolean}> = [];
  
  startDate = moment().format("YYYY-MM-DD");
  endDate = moment().format("YYYY-MM-DD");
  dateRange: NgbDateStruct[] | null = null;
  showCalendar: boolean = false;
  
  model: NgbDateStruct;
  date: { year: number; month: number };
  displayMonths = 2;
  navigation = "select";
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  
  selectedRange: { label: string; value: NgbDateStruct[] };

  constructor(
    private calendar: NgbCalendar,
    public translateService: TranslateService
  ) {
    moment.updateLocale('fr', {
      week: {
        dow: 1, 
        doy: 4
      }
    });
    
    moment.updateLocale('en', {
      week: {
        dow: 1, 
        doy: 4
      }
    });
  }
  

  ngOnInit() {
    this.initializeRanges();
    
    // Subscribe to language changes
    this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
      this.initializeRanges();
      this.updateSelectedRange();
    });

    // Initialize selectedRange
    if (this.currentWeek) {
      this.selectedRange = this.ranges[1]; // "This Week"
    } else {
      this.selectedRange = this.ranges[0]; // "Today"
    }

    const today = new Date();
    this.model = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate()
    };

    this.emitDateRange();
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  private initializeRanges() {
    const currentLanguage = this.translateService.currentLang;
    
    this.ranges = [
      {
        label: currentLanguage === "fr" ? "Aujourd'hui" : "Today",
        value: [this.toNgbDate(new Date()), this.toNgbDate(new Date())],
      },
      {
        label: currentLanguage === "fr" ? "Cette semaine" : "This Week",
        value: [
          this.toNgbDate(startOfWeek(new Date(), { weekStartsOn: 1 })),
          this.toNgbDate(endOfWeek(new Date(), { weekStartsOn: 1 }))
        ],
      },
      {
        label: currentLanguage === "fr" ? "Hier" : "Yesterday",
        value: [
          this.toNgbDate(subDays(new Date(), 1)),
          this.toNgbDate(subDays(new Date(), 1)),
        ],
      },
      {
        label: currentLanguage === "fr" ? "Les 7 derniers jours" : "Last 7 Days",
        value: [
          this.toNgbDate(subDays(new Date(), 6)),
          this.toNgbDate(new Date()),
        ],
      },
      {
        label: currentLanguage === "fr" ? "Les 30 derniers jours" : "Last 30 Days",
        value: [
          this.toNgbDate(subDays(new Date(), 29)),
          this.toNgbDate(new Date()),
        ],
        visible: this.last30DaysVisible
      },
      {
        label: currentLanguage === "fr" ? "Ce mois-ci" : "This Month",
        value: [
          this.toNgbDate(startOfMonth(new Date())),
          this.toNgbDate(endOfMonth(new Date())),
        ],
        visible: this.thisMonthVisible
      },
      {
        label: currentLanguage === "fr" ? "Le mois dernier" : "Last Month",
        value: [
          this.toNgbDate(startOfMonth(subMonths(new Date(), 1))),
          this.toNgbDate(endOfMonth(subMonths(new Date(), 1))),
        ],
        visible: this.lastMonthVisible
      },
      {
        label: currentLanguage === "fr" ? "Période personnalisée" : "Custom Range",
        value: [],
      },

      {
        label: currentLanguage === "fr" ? "Jour personnalisé" : "Custom Day",
        value: [],
      },

    ];


  }

  private updateSelectedRange() {
    if (!this.selectedRange) return;
    
    // Find the equivalent range in the new language
    const oldIndex = this.ranges.findIndex(r => 
      r.value[0]?.year === this.selectedRange.value[0]?.year &&
      r.value[0]?.month === this.selectedRange.value[0]?.month &&
      r.value[0]?.day === this.selectedRange.value[0]?.day &&
      r.value[1]?.year === this.selectedRange.value[1]?.year &&
      r.value[1]?.month === this.selectedRange.value[1]?.month &&
      r.value[1]?.day === this.selectedRange.value[1]?.day
    );
    
    if (oldIndex !== -1) {
      this.selectedRange = this.ranges[oldIndex];
    } else {
      this.selectedRange = this.ranges[0]; // Default to first option if not found
    }
  }

  private emitDateRange() {
    if (this.selectedRange?.value?.length === 2) {
      const formattedFromDate = formatDate(
        new Date(
          this.selectedRange.value[0].year,
          this.selectedRange.value[0].month - 1,
          this.selectedRange.value[0].day
        ),
        "yyyy-MM-dd",
        "en-US"
      );
      const formattedToDate = formatDate(
        new Date(
          this.selectedRange.value[1].year,
          this.selectedRange.value[1].month - 1,
          this.selectedRange.value[1].day
        ),
        "yyyy-MM-dd",
        "en-US"
      );

      this.dateRangeSelected.emit({
        startDate: formattedFromDate,
        endDate: formattedToDate,
      });
    }
  }

  onRangeClick(
    selectedRange: { label: string; value: NgbDateStruct[] },
    event: Event
  ): void {
    event.preventDefault();
    event.stopPropagation();
  
    this.selectedRange = selectedRange;
    if (selectedRange.label !== (this.translateService.currentLang === "fr" ? "Période personnalisée" : "Custom Range") &&
        selectedRange.label !== (this.translateService.currentLang === "fr" ? "Jour personnalisé" : "Custom Day")) {
      this.emitDateRange();
    }
  
    this.showCalendar = selectedRange.label === (this.translateService.currentLang === "fr" ? "Période personnalisée" : "Custom Range") ||
                        selectedRange.label === (this.translateService.currentLang === "fr" ? "Jour personnalisé" : "Custom Day");
  }

  formatDateRange(dateRange: NgbDateStruct[]): string {
    if (!dateRange || dateRange.length !== 2) {
      return "";
    }

    const startDate = moment({
      year: dateRange[0].year,
      month: dateRange[0].month - 1,
      day: dateRange[0].day,
    }).format("DD-MM-YYYY");
    const endDate = moment({
      year: dateRange[1].year,
      month: dateRange[1].month - 1,
      day: dateRange[1].day,
    }).format("DD-MM-YYYY");
    return `${startDate} > ${endDate}`;
  }

  isHovered = (date: NgbDateStruct) =>
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    after(date, this.fromDate) &&
    before(date, this.hoveredDate);

  isInside = (date: NgbDateStruct) => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = (date: NgbDateStruct) => equals(date, this.fromDate);
  isTo = (date: NgbDateStruct) => equals(date, this.toDate);

  onDateChange(date: NgbDateStruct) {


    if (this.selectedRange?.label === (this.translateService.currentLang === "fr" ? "Jour personnalisé" : "Custom Day")) {
      this.fromDate = date;
      this.toDate = date;
      
      const customDay = {
        label: this.translateService.currentLang === "fr" ? "Jour personnalisé" : "Custom Day",
        value: [date, date]
      };
      
      this.selectedRange = customDay;
      this.emitDateRange();
      return;
    }
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.translateService.currentLang === "fr" ? 
        this.selectedRange.label === "Période personnalisée" : 
        this.selectedRange.label === "Custom Range") {
      const customRange = {
        label: this.translateService.currentLang === "fr" ? "Période personnalisée" : "Custom Range",
        value: [this.fromDate, this.toDate]
      };

      
      
      if (this.fromDate && this.toDate) {
        this.selectedRange = customRange;
        this.emitDateRange();
      }
    }

    if (this.translateService.currentLang === "fr" ? 
      this.selectedRange.label === "Jour personnalisé" : 
      this.selectedRange.label === "Custom Day") {
      this.fromDate = date;
      this.toDate = date;
    
    const customDay = {
      label: this.translateService.currentLang === "fr" ? "Jour personnalisé" : "Custom Day",
      value: [date, date]
    };
    
    this.selectedRange = customDay;
    this.emitDateRange();
    return;
  }

  if (!this.fromDate && !this.toDate) {
    this.fromDate = date;
  } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
    this.toDate = date;
  } else {
    this.toDate = null;
    this.fromDate = date;
  }
  }

  private toNgbDate(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }
  
  isDisabled(date: NgbDateStruct, current: { month: number }) {
    return date.month !== current.month;
  }

  isRangeVisible(range: { label: string; value: NgbDateStruct[]; visible?: boolean; }): boolean {
    if(range.visible === null || range.visible === undefined) {
      return true;
    }

    return range.visible;
  }

}
