import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";
import {
  NgbDatepicker,
  NgbDateStruct,
  NgbPopover,
  NgbPopoverConfig,
  NgbTimeStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { noop } from "rxjs";
import { AbstractFormElement } from "../abstract-form-elements";
import { DateTimeModel } from "app/shared/models/date-time.model";
import { NotyService } from "app/shared/services/noty.service";

@Component({
  selector: "app-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.scss"],
})
export class DatepickerComponent
  extends AbstractFormElement
  implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges
{
  @Input() dateString: string;
  minDate = { year: 1900, month: 1, day: 1 }; // Minimum date (January 1, 1970)
  @Input() inputDatetimeFormat = "dd/MM/yyyy";
  @Input() hourStep = 1;
  @Input() minuteStep = 15;
  @Input() secondStep = 30;
  @Input() seconds = true;

  showTimePickerToggle = false;

  datetime: DateTimeModel = new DateTimeModel({
    hour: new Date().getHours(),
    minute: new Date().getMinutes(),
    second: 0,
  });
  firstTimeAssign = true;

  @ViewChild(NgbDatepicker, { static: true })
  private dp: NgbDatepicker;
  // ngControl: NgControl;
  @ViewChild(NgbPopover, { static: true })
  private popover: NgbPopover;
  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;

  constructor(
    private config: NgbPopoverConfig,
    private notyService: NotyService
  ) {
    super();
    config.autoClose = "outside";
    config.placement = "auto";
  }

  ngOnInit(): void {
    // this.ngControl = this.inj.get(NgControl);
  }

  ngAfterViewInit(): void {
    this.popover?.hidden.subscribe(() => {
      this.showTimePickerToggle = false;
    });
  }

  writeValue(newModel: string) {
    if (newModel) {
      this.datetime = Object.assign(
        this.datetime,
        DateTimeModel.fromLocalString(newModel)
      );
      this.dateString = newModel;
      this.setDateStringModel();
    } else {
      this.datetime = new DateTimeModel();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleDateTimeState($event) {
    if (!this.datetime?.day) {
      this.notyService.displayNotification(
        "Please select a day first",
        "warning"
      );
      return;
    }
    this.showTimePickerToggle = !this.showTimePickerToggle;
    $event.stopPropagation();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputChange($event: any) {
    const value = $event.target.value;
    const dt = DateTimeModel.fromLocalString(value);

    if (dt) {
      this.datetime = dt;
      this.setDateStringModel();
    } else if (value.trim() === "") {
      this.datetime = new DateTimeModel();
      this.dateString = "";
      this.onChange(this.dateString);
    } else {
      this.onChange(value);
    }
  }

  onDateChange($event: string | NgbDateStruct) {
    const date = new DateTimeModel($event);

    if (!date) {
      // this.dateString = this.dateString;
      return;
    }

    if (!this.datetime) {
      this.datetime = date;
    }

    this.datetime.year = date.year;
    this.datetime.month = date.month;
    this.datetime.day = date.day;

    const adjustedDate = new Date(this.datetime.toString());
    if (this.datetime.timeZoneOffset !== adjustedDate.getTimezoneOffset()) {
      this.datetime.timeZoneOffset = adjustedDate.getTimezoneOffset();
    }

    this.setDateStringModel();
  }

  onTimeChange(event: NgbTimeStruct) {
    this.datetime.hour = event.hour;
    this.datetime.minute = event.minute;
    this.datetime.second = event.second;

    this.setDateStringModel();
  }

  setDateStringModel() {
    this.dateString = this.datetime.toString().split("+")[0];

    if (!this.firstTimeAssign) {
      this.onChange(this.dateString);
    } else {
      // Skip very first assignment to null done by Angular
      if (this.dateString !== null) {
        this.firstTimeAssign = false;
      }
    }
    this.ngModelChange.emit(this.dateString);
  }

  inputBlur() {
    this.onTouched();
  }

  openPopOver(popover: NgbPopover) {
    popover.open();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control.currentValue.value) {
      const date = new Date(changes.control.currentValue.value);
      this.datetime = new DateTimeModel({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds(),
      });
      this.setDateStringModel();
    }
  }

  getToday(): NgbDateStruct {
    const date = new Date();
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }
}
