<div class="modal-body">
    <div class="tracking-map" style="width: 100%; height: 480px"></div>
    <div class="bg-light-grey p-2 position-data rounded" *ngIf="animationPosition">
        Date : <span class="font-weight-bold">{{animationPosition.date | date:'dd/MM/yyyy'}}</span>  <br>
        Heure : <span class="font-weight-bold">{{animationPosition.date | date:'HH:mm:ss'}}</span>  <br>
        Vitesse : <span class="font-weight-bold">{{animationPosition.vitesse}}</span> Km/h
    </div>
    <div class="form-group" *ngIf="isHistoric">
        <div class="d-flex justify-content-center mt-2">
            <button type="button" class="btn round btn-sm btn-social-icon bg-dark mr-2" (click)="getArchiveData(200)"
                *ngIf="!isAnimationRunning"><i class="fa-solid fa-play font-small-3"></i></button>
            <button type="button" class="btn round btn-sm btn-social-icon bg-dark mr-2"
                (click)="stopTrajectoryAnimation()" *ngIf="isAnimationRunning"><i
                    class="fa-solid fa-stop font-small-3"></i></button>
            <button type="button" class="btn round btn-sm btn-social-icon bg-dark mr-2" (click)="getArchiveData()"><i
                    class="fa-solid fa-rotate-left font-small-3"></i></button>
        </div>
        <div (mousedown)="mouseDown()">
            <nouislider *ngIf="archivedPositions" [min]="0" [max]="archivedPositions.length" [step]="1"
                [(ngModel)]="animationPositionIndex" (ngModelChange)="onSliderPositionChange($event)"></nouislider>
        </div>

    </div>
</div>