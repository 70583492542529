<div *ngIf="collectionSize > 0" class="pagination-w">
    <div class="float-right d-inline-flex" [ngClass]="class">
        <ngb-pagination
                [maxSize]="3"
                [pageSize]="pageSize"
                [collectionSize]="collectionSize"
                [(page)]="currentPage"
                (pageChange)="onPageChange($event)"
                style="margin-left: 5px;">
        </ngb-pagination>
    </div>
    <div class="float-right d-inline-flex">
        <select
                class="custom-select"
                style="width: auto"
                name="pageSize"
                [(ngModel)]="pageSize"
                (ngModelChange)="onPageSizeChange($event)">
            <option  [ngValue]="10">10 / page</option>
            <option *ngIf="(showDynamicOptions && collectionSize > 10) || (showDynamicOptions && collectionSize > 15)" [ngValue]="20">20 / page</option>
            <option *ngIf="showDynamicOptions && collectionSize > 20" [ngValue]="40">40 / page</option>
            <option *ngIf="showDynamicOptions && collectionSize > 40" [ngValue]="60">60 / page</option>
        </select>
    </div>
</div>
