<div class="modal-header">
    <h4 class="modal-title">{{"DUPLICATE_TITLE" | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
      <i class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body">
    <p>{{"DUPLICATE_MESSAGE" | translate}} <br>
    <strong> {{"DUPLICATE_VALIDATION_QUESTION" | translate}} </strong> ?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="save()">   <i class="ft-check-square mr-1"></i>{{"DUPLICATE" | translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">  <i class="ft-x mr-1"></i>{{"CANCEL" |
      translate}}</button>
  </div>
