<!--
<div class="kpi-widget" [style.background-color]="backgroundColor">
  <div class="kpi-header">
    <h3 class="kpi-title">{{ title | translate }}</h3>
  </div>
  <div class="kpi-content">
    <div class="kpi-value">{{ value }}</div>
    <div class="kpi-unit">{{ unit }}</div>
  </div>
</div>
-->


<div class="card-container">
    <div class="card h-100 border-0 rounded-lg" [style.background-color]="backgroundColor">
        <div class="card-body p-4">
            <div class="d-flex flex-column">
                <div class="mb-1">
                    <h3 class="display-6 fw-bold text-white mb-0">
                        {{ value }}
                        <small class="fs-6 text-white">{{ unit }}</small>
                    </h3>
                </div>
                <span class="text-uppercase text-white fs-sm letter-spacing-1">
            {{ title | translate }}
          </span>
            </div>
        </div>
    </div>
</div>

