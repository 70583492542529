import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-add-permission-modal",
  templateUrl: "./add-permission-modal.component.html",
  styleUrls: ["./add-permission-modal.component.scss"],
})
export class AddPermissionModalComponent implements OnInit {
  @Input() resource: string;
  @Input() parentResource: string;
  @Input() isParent: boolean; // New input to determine if the node is a parent
  permissionForm: FormGroup;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.permissionForm = this.fb.group({
      roleName: ["", Validators.required],
      roleCode: ["", Validators.required],
      roleRoute: ["", Validators.required],
      isParent: [this.isParent], // Initialize the isParent field
    });

    this.permissionForm.get("roleName")?.valueChanges.subscribe((value) => {
      this.updateRoleCodeAndRoute(value);
    });

    this.permissionForm.get("isParent")?.valueChanges.subscribe((isParent) => {
      this.isParent = isParent;
      this.updateRoleCodeAndRoute(
        this.permissionForm.get("roleName")?.value || ""
      );
    });
  }

  updateRoleCodeAndRoute(name: string): void {
    if (name) {
      const code = this.generateCode(name);
      const route = this.isParent
        ? this.generateParentRoute(code)
        : this.generateRoute(code);

      this.permissionForm.patchValue({
        roleCode: code,
        roleRoute: route,
      });
    }
  }

  generateParentRoute(code: string): string {
    // For parent nodes, generate a route that includes all parent nodes in the path
    return `${this.parentResource}/${this.resource}/${code}`;
  }

  generateRoute(code: string): string {
    // For terminal nodes, generate a route based on the code
    return `${this.parentResource}/${this.resource}`;
  }

  /**
   * Generates a code from a given name by converting it to lowercase and replacing spaces with hyphens.
   * @param {string} name - The name to be converted into a code.
   * @returns {string} The generated code.
   */
  generateCode(name: string): string {
    return name.toLowerCase().replace(/\s+/g, "-");
  }

  /**
   * Submits the form if it is valid and closes the modal with the form values.
   * @returns {void}
   */
  onSubmit(): void {
    if (this.permissionForm.valid) {
      this.activeModal.close(this.permissionForm.value);
    } else {
    }
  }
}
