import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
// @ts-ignore
import L from 'leaflet';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CrudService} from '../../../../../shared/services/crud.service';
import {OPN_BASE_URL} from '../../../../../shared/global/var';
import {NotyService} from '../../../../../shared/services/noty.service';
import {error} from 'protractor';
import {ZoomService} from '../../../../../shared/services/zoom.service';

@Component({
    selector: 'app-route-trip-map',
    templateUrl: './route-trip-map.component.html',
    styleUrls: ['./route-trip-map.component.scss']
})
export class RouteTripMapComponent implements OnInit, OnDestroy {
    private map = L.map;
    private markers: any[] = [];
    private cancelMarkers: any[] = [];
    private routeLine: any;

    @Input() stations: any[] = [];
    @Input() shape : any[] = [];
    @Input() createItinerary = false;
    @Input() trip: any;
    @Input() itinerary: any;
    itineraryForm: FormGroup;
    showRemove : boolean = false;


    langue = localStorage.getItem("langue");


    constructor(
        private _elementRef: ElementRef,
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private notyService: NotyService,
        private crudService: CrudService,
        private zoomService: ZoomService
    ) {
    }

    ngOnInit(): void {
        // reorder the stations array using the order property = stationOrder
        this.showRemove = this.createItinerary;
        this.stations.sort((a, b) => a.stationOrder - b.stationOrder);
        this.initMap(); // Initialize the map
        this.markers = []; // Initialize the markers array

        // Loop through the stations and add markers
        for (const station of this.stations) {
            this.addMarkers([station.station.lat, station.station.lon], station.station.name, station.status);
        }

        // Fit the map bounds to the markers
       //this.fitMapToMarkers();
        // zoom in on last marker
      //  this.map.setView([this.stations[0].station.lat, this.stations[0].station.lon], 15);
        if(this.createItinerary && this.itinerary) {
            this.initForm();
        }
    }


    /**
     * Lifecycle hook that is called when the component is destroyed.
     * Clears the map by removing all markers and the route line.
     */
    ngOnDestroy(): void {
        this.clearMap();
    }

    /**
     * Initializes the map and sets its view to a default center and zoom level.
     * Adds a tile layer to the map and draws the route using OSRM.
     */
    initMap(): void {
        const el = this._elementRef.nativeElement.querySelector('.leaflet-map');
        this.map = L.map(el, {
            center: [34.551117, 9.369019],
             zoom: 5,
             minZoom: 5
        });
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attributionControl: false,
        }).addTo(this.map);

        // Ensure map is fully loaded before drawing the route
        this.map.on('load', () => {
            if (this.shape.length === 0) {
                this.drawRouteOSRM(this.stations.map(station => station.station)).then(r => r);
            } else {
                this.drawShape();
            }
        });

        // Trigger the 'load' event
        this.map.whenReady(() => this.map.fire('load'));
    }


    /**
     * Adds a marker to the map at the specified latitude and longitude.
     * The marker is customized with a bus stop icon and a popup displaying the station name.
     *
     * @param {any} latlng - The latitude and longitude of the marker.
     * @param {string} stationName - The name of the station to display in the popup.
     * @param stationStatus - The status of the station
     */
    addMarkers(latlng: any, stationName: string, stationStatus = 1): void {
        const initialIconSize: [number, number] = [25, 41];
        const iconUrl = "./assets/img/leaflet/bus-stop.png";
        const marker = this.zoomService.createResizableIcon(this.map, latlng, iconUrl, stationName, initialIconSize);
        // Add a popup to the marker and open it
        marker.bindPopup(stationName).openPopup();
        this.markers.push(marker);
        if(stationStatus === 3) {
            const initialCancelIconSize: [number, number] = [15, 20];
            const cancelIconUrl = './assets/img/markers/cancel.png';
            const cancelIcon = L.icon({
                iconUrl: './assets/img/markers/cancel.png',
                iconSize: [15, 20],
            })
            const cancelMarker = this.zoomService.createResizableIcon(this.map, latlng, cancelIconUrl, '', initialCancelIconSize);
            cancelMarker.bindPopup(stationName).openPopup();
            this.cancelMarkers.push(cancelMarker);
        }

    }

    /**
     * Draws a route on the map using the OSRM API.
     * Fetches the route data from OSRM and draws a polyline on the map.
     * Adjusts the map bounds to fit the route.
     *
     * @param {Object[]} stations - An array of station objects with latitude and longitude properties.
     * @returns {Promise<void>} A promise that resolves when the route is drawn.
     */
    async drawRouteOSRM(stations: { lat: number; lon: number }[]): Promise<void> {
        if (stations.length < 2) {
            return;
        }

        const coordinates = stations
            .map((station) => `${station.lon},${station.lat}`)
            .join(';');
        const url = `/route/v1/driving/${coordinates}?overview=full&geometries=geojson`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.code !== 'Ok') {
                console.error('OSRM request failed:', data);
                return;
            }

            const route = data.routes[0];

            const routeCoordinates = route.geometry.coordinates.map((coord: any[]) => [
                coord[1],
                coord[0],
            ]);

            if (this.routeLine) {
                this.map.removeLayer(this.routeLine);
            }
            this.routeLine = L.polyline(routeCoordinates, {
                color: 'blue',
                weight: 5,
            }).addTo(this.map);

            // Fit the map to the bounds of the route
            this.map.fitBounds(this.routeLine.getBounds());
        } catch (error) {
            console.error('Error fetching OSRM route:', error);
        }
    }

    /**
     * Clears the map by removing all markers and the route line.
     * Resets the markers array and sets the route line to null.
     */
    clearMap(): void {
        this.markers.forEach((marker) => this.map.removeLayer(marker));
        this.markers = [];
        if (this.routeLine) {
            this.map.removeLayer(this.routeLine);
            this.routeLine = null;
        }
    }

    getDuration(station: any, stationList: any[]): string {
        // get the sum of the duration of the previous stations
        const index = stationList.indexOf(station);
        let duration = 0;
        for (let i = 0; i < index + 1; i++) {
            if(stationList[i].duration) {
                duration += stationList[i].duration;
            }
        }
        return this.formatDuration(duration);
    }

    formatDuration(duration: number): string {
        const hours = Math.floor(duration / 60);
        const minutes = Math.floor(duration % 60);
        return `${hours}h ${minutes}m`;
    }

    drawShape(): void {
        if (this.shape.length > 0) {
            // Collect all latlng points
           // const latlngs = this.shape.map(point => L.latLng(point.lat, point.lon));

            // Remove the previous route line if it exists
            if (this.routeLine) {
                this.map.removeLayer(this.routeLine);
            }

            // Create and add the new route line
            this.routeLine = L.polyline(this.shape, {
                color: 'blue',
                weight: 5,
            }).addTo(this.map);

            this.map.fitBounds(this.routeLine.getBounds());
        }
    }


    closeModal(): void {
        this.activeModal.dismiss('Modal closed');
    }

    removeStation(station: any): void {
        const index = this.stations.findIndex(s => s.id === station.id);

        if (index !== -1) {
            // Remove the station from the stations array
            this.stations.splice(index, 1);

            // Remove the corresponding marker and cancel marker safely
            if (this.markers[index]) {
                this.map.removeLayer(this.markers[index]);
            }
            this.markers.splice(index, 1);
            this.cancelMarkers.splice(index, 1);

            // Update the `showRemove` flag if no stations have status 3
            this.showRemove = this.stations.some(s => s.status === 3);
            this.reorderStations();

            // Get updated distances for the remaining stations
            const stationCoordinates = this.stations.map(s => ({
                lat: s.station.lat,
                lon: s.station.lon
            }));

            if (stationCoordinates.length >= 2) {
                this.getNewDistances(stationCoordinates);
            } else {
                console.warn('Not enough stations to calculate distances.');
            }
        } else {
            console.warn('Station not found:', station);
        }
    }

    async getNewDistances(stations: { lat: number; lon: number }[]): Promise<void> {
        // Prepare OSRM API coordinates string
        const coordinates = stations
            .map(station => `${station.lon},${station.lat}`)
            .join(';');

        const url = `/route/v1/driving/${coordinates}?overview=full&geometries=geojson`;

        try {
            // Fetch route data from OSRM API
            const response = await fetch(url);
            const data = await response.json();

            if (data.code === 'Ok' && data.routes?.[0]?.legs) {
                const legsData = data.routes[0].legs.map((leg) => ({
                    distance: leg.distance,
                    duration: leg.duration,
                }));
                this.updateStationDistances(legsData);
            } else {
                console.error('OSRM request failed:', data.message || data);
            }
        } catch (error) {
            console.error('Error fetching OSRM route:', error);
        }
    }

    updateStationDistances(legsData: { distance: number; duration: number }[]): void {
        if (legsData.length < this.stations.length - 1) {
            console.warn('Mismatch between stations and legsData.');
            return;
        }

        this.stations.forEach((station, index) => {
            if (index === 0) {
                station.distance = 0;
                station.duration = 0;
            } else {
                const distanceInKm = (legsData[index - 1].distance / 1000).toFixed(2);
                const durationInMin = (legsData[index - 1].duration / 60).toFixed(2);

                station.distance = parseFloat(distanceInKm);
                station.duration = parseFloat(durationInMin);
            }
        });
        // Update form groups for itinerary stations
        this.itineraryStationsArray.at(0).patchValue({ distance: 0, duration: 0 });

        for (let i = 1; i <= legsData.length; i++) {
            const stationFormGroup = this.itineraryStationsArray.at(i) as FormGroup;
            const distanceInKm = (legsData[i - 1].distance / 1000).toFixed(2);
            const durationInMin = (legsData[i - 1].duration / 60).toFixed(2);
            stationFormGroup.patchValue({
                distance: parseFloat(distanceInKm),
                duration: parseFloat(durationInMin),
            });
        }
    }



    reorderStations() {
        this.stations.forEach((station, index = 0) => {
            station.stationOrder = index + 1;
        })
    }

    initForm() {
        this.itineraryForm = this.fb.group({
            itineraryLib: ['', Validators.required],
            direction: [''],
            routeId: [''],
            itineraryNo: [''],
            distance: [''],
            avgDuration: [''],
            nbrOfStations: [''],
            itineraryStations: this.fb.array([])
        });
    }

    createStationFormGroup(station: any) {
        return this.fb.group({
            itineraryId: [station.itineraryId],
            stationId: [station.stationId],
            station: [station.station],
            stationOrder: [station.stationOrder],
            status: [station.status],
            stoppingTime: [station.stoppingTime],
            distance: [station.distance],
            duration: [station.duration]
        });
    }


    get itineraryStationsArray() {
        return this.itineraryForm.get('itineraryStations') as FormArray;
    }
    async saveItinerary() {

        if (this.itineraryForm.valid && this.stations.length > 0) {
            try {
                // Clear existing stations first
                while (this.itineraryStationsArray.length) {
                    this.itineraryStationsArray.removeAt(0);
                }

                // Add each station to the form array
                this.stations.forEach(station => {
                    this.itineraryStationsArray.push(this.createStationFormGroup(station));
                });

                this.stations = this.itineraryStationsArray.value;

                const returnSuffix = this.itinerary.direction === 1 ? '-A' : '-R';
                const itineraryName = this.itineraryForm.get('itineraryLib').value;
                const itineraryNumber = await this.getItineraryNumber();

                // Update form values
                this.itineraryForm.patchValue({
                    routeId: this.itinerary.routeId,
                    itineraryLib: itineraryName + returnSuffix,
                    itineraryNo: itineraryNumber,
                    nbrOfStations: this.stations.length,
                    distance: this.stations.reduce((sum, station) => sum + station.distance, 0),
                    avgDuration: this.stations.reduce((sum, station) => sum + station.duration, 0),
                    direction: this.itinerary.direction
                });
                // Here you can add your save logic
                // await this.saveToBackend(this.itineraryForm.value);

                this.crudService
                    .post(OPN_BASE_URL + "/itinerary/add", this.itineraryForm.value)
                    .subscribe((data: any) => {

                        this.stations.forEach((station) => {
                            this.crudService
                                .post(OPN_BASE_URL + "/itinerary-station/add", {
                                    ...station,
                                    itineraryId: data.id
                                })
                                .subscribe((data: any) => {
                                    const successMessage = this.langue === "fr"
                                        ? "Itineraire ajouté avec succès"
                                        : "Itinerary added successfully";
                                    this.notyService.displayNotification(successMessage, "success");
                                });
                        });
                        const response = data;

                        const routeCoordinates = this.shape.map((coord) => ({
                            lat: coord.lat,
                            lon: coord.lon
                    }));

                this.getDistances(routeCoordinates).then((data) => {
                    this.saveItineraryShape(response.id, data);
                });


            });

            } catch (error) {
                console.error('Error saving itinerary:', error);
                // Handle error appropriately
            }
        } else {
            console.error('Form is invalid or no stations present');
            // Mark all fields as touched to trigger validation messages
            Object.keys(this.itineraryForm.controls).forEach(key => {
                const control = this.itineraryForm.get(key);
                control?.markAsTouched();
            });
        }

        this.closeModal();
    }

    getItineraryNumber(): Promise<number> {
        return new Promise((resolve) => {
            if (this.itinerary) {
                this.crudService
                    .getOne(OPN_BASE_URL + "/itinerary/by-route", this.itinerary.routeId)
                    .subscribe((data: any) => {
                        if (data) {
                            resolve(data.length + 1);
                        } else {
                            resolve(0);
                        }
                    });
            } else {
                resolve(0);
            }
        });
    }


    async getDistances(positions: { lat: number; lon: number }[], returnShape = false): Promise<{ lat: number; lon: number; km: number }[]> {
        let distances: { lat: number; lon: number; km: number }[] = [];

        if (returnShape) {
            // For the reverse shape, we calculate distances from the end back to the start
            let s = { ...positions[positions.length - 1], km: 0 }; // Start with the last position
            distances.push(s);

            for (let i = positions.length - 1; i > 0; i--) {
                const distance = this.getDistance(
                    positions[i].lat,
                    positions[i].lon,
                    positions[i - 1].lat,
                    positions[i - 1].lon
                );
                s = { ...positions[i - 1], km: distances[distances.length - 1].km + distance };
                distances.push(s);
            }
        } else {
            // For the normal shape, calculate as before
            let s = { ...positions[0], km: 0 };
            distances.push(s);

            for (let i = 0; i < positions.length - 1; i++) {
                const distance = this.getDistance(
                    positions[i].lat,
                    positions[i].lon,
                    positions[i + 1].lat,
                    positions[i + 1].lon
                );
                s = { ...positions[i + 1], km: distances[i].km + distance };
                distances.push(s);
            }
        }

        return distances;
    }

    getDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
        const R = 6371; // Radius of the earth in km
        const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
        const dLon = this.deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.deg2rad(lat1)) *
            Math.cos(this.deg2rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in km
    }

    deg2rad(deg: number): number {
        return deg * (Math.PI / 180);
    }

    saveItineraryShape(itineraryId: string, shapeData: any) {
    this.crudService
      .post(OPN_BASE_URL + `/shape/create/multiple/${itineraryId}`, shapeData)
      .subscribe(
        () => {
          console.info("Shape saving initiated.");
        },
        (error) => {
          console.error("Error initiating shape saving:", error);
        }
      );
  }


    protected readonly Validators = Validators;
}
