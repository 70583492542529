import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import {Subject, Subscription} from 'rxjs';
import { Router, NavigationEnd } from "@angular/router";
import {filter, takeUntil} from 'rxjs/operators';
import { Title } from "@angular/platform-browser";
import {CompanySettingsService} from './modules/company-settings/services/company-settings.service';
import {NgSelectConfig} from '@ng-select/ng-select';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();
  subscription: Subscription;
  profileConfig: any;
  acronym: string;
  logoUrl: string;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private titleService: Title,
    private settingsService: CompanySettingsService,
    private config: NgSelectConfig
  ) {
      this.translate.stream('COMMON.NO_ITEMS').subscribe(translation => {
          this.config.notFoundText = translation;
      });
  }

    ngOnInit() {
        // Handle route changes
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            takeUntil(this.destroy$)
        ).subscribe(() => {
            window.scrollTo(0, 0);
            this.updateTitle(this.router.url.split('?')[0]);
        });

        // Subscribe to company settings
        this.settingsService.profileConfig$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(config => {
            if (config) {
                this.profileConfig = config;
                this.acronym = config.acronym || '';
                this.logoUrl = config.logo || '/assets/img/bus-svgrepo-com.png';
                this.updateFavicon(this.logoUrl);
                this.updateTitle(this.router.url.split('?')[0]);
            }
        });

        // Initial load of company settings
        this.settingsService.getAllCompanySettings().subscribe();
    }

  ngAfterViewInit() {}

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private updateFavicon(logoUrl: string) {
        const favicon: HTMLLinkElement | null = document.querySelector('#app-favicon');
        if (favicon) {
            favicon.href = logoUrl;
        }
    }

    private updateTitle(currentPageTitle: string) {
        currentPageTitle = currentPageTitle
            .replace('/', '')
            .replace('pages/', '')
            .replace(/-/g, ' ')
            .replace(/\b\w/g, l => l.toUpperCase());

        this.titleService.setTitle(`${currentPageTitle} - ${this.acronym}`);
    }
}
