<div class="modal-header">
  <h5 class="modal-title">{{ 'REGENERATE_TRIPS' | translate }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
    <i class="fa-solid fa-xmark"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="regenerationForm">
    <div class="mb-3">
      <label for="startDate" class="form-label">{{ 'FROM' | translate }}</label>
      <input id="startDate" type="date" formControlName="startDate" class="form-control">
    </div>
    <div class="mb-3">
      <label for="endDate" class="form-label">{{ 'TO' | translate }}</label>
      <input id="endDate" type="date" formControlName="endDate" class="form-control">
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="regenerateTrips()">{{ 'REGENERATE' | translate }}</button>
  <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">{{ 'CANCEL' | translate }}</button>
</div>
