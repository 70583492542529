import { CrudService } from "app/shared/services/crud.service";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OPN_BASE_URL } from "app/shared/global/var";
import { catchError, tap } from "rxjs/operators";
import { throwError } from "rxjs";
import { NotyService } from "app/shared/services/noty.service";

interface GroupedInstance {
  date: string;
  instances: any[];
  isCollapsed: boolean;
}

@Component({
  selector: "app-export-assignment-modal",
  templateUrl: "./export-assignment-modal.component.html",
  styleUrls: ["./export-assignment-modal.component.scss"],
})
export class ExportAssignmentModalComponent implements OnInit {
  checkAvailabilityResponse: any;

  @Output() itemUpdated: EventEmitter<void> = new EventEmitter<void>();
  @Input() data;
  nbrOfTrips: number;
  distinctDrivers: Set<number>;
  distinctLineNumbers: Set<number>;
  nbrOfDays: number;
  nbrOfDrivers: number;
  nbrOfLines: number;
  missingInstances;
  instancesToExport;
  formViewData: any;
  reviewViewData: any;
  exportViewData: any;
  isLoading: boolean = false;
  sameSchedule: boolean;
  isNextDisabled: boolean = true;

  // public isNextDisabled: boolean = true;
  public apiResponseCode: number | null = null;
  // public viewState: "form" | "export" = "form";
  public viewState: "form" | "export" | "review" = "form";
  reviewStateInstancesToExport: any[] = [];

  matchedInstancesToExport: any[] = [];

  exportForm: FormGroup;
  tripForm: FormGroup;

  groupedInstances: GroupedInstance[] = [];
  isCollapsed: boolean[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private notyService: NotyService,
    private crudService: CrudService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  /**
   * Initializes the component by setting up form controls, transforming data,
   * and subscribing to value changes of specific form controls.
   * Handles the initialization of grouped instances, form groups, and event subscriptions.
   */

  ngOnInit(): void {
    this.groupedInstances = this.transformInstancesByDate();
    this.exportForm = new FormGroup({
      startDate: new FormControl(""),
      reaffect: new FormControl(false),
      deleteDriverAssignmentCheckbox: new FormControl(false),
      deleteReceiverAssignmentCheckbox: new FormControl(false),
      deleteBusAssignmentCheckbox: new FormControl(false),
    });
    // Initialize collapse state for each group
    this.isCollapsed = this.transformInstancesByDate().map(() => false);

    // Initialize data related to trips
    this.initializeData();

    // Subscribe to changes in the startDate control
    this.exportForm.get("startDate").valueChanges.subscribe((startDate) => {
      if (startDate) {
        this.onNextClick(startDate);
      }
    });
    // Subscribe to changes in the deleteDriverAssignmentCheckbox control
    this.exportForm
      .get("deleteDriverAssignmentCheckbox")
      .valueChanges.subscribe((checked) => {
        this.instancesToExport.forEach((instance) => {
          instance.overrideDriver = checked;
        });
      });
    // Subscribe to changes in the deleteReceiverAssignmentCheckbox control
    this.exportForm
      .get("deleteReceiverAssignmentCheckbox")
      .valueChanges.subscribe((checked) => {
        this.instancesToExport.forEach((instance) => {
          instance.overrideReceiver = checked;
        });
      });
    // Subscribe to changes in the deleteBusAssignmentCheckbox control
    this.exportForm
      .get("deleteBusAssignmentCheckbox")
      .valueChanges.subscribe((checked) => {
        this.instancesToExport.forEach((instance) => {
          instance.overrideBus = checked;
        });
      });
  }

  /**
   * Initializes trip-related data, including the number of trips, distinct drivers,
   * line numbers, and unique dates.
   */
  initializeData() {
    this.nbrOfTrips = this.data.size;
    this.distinctDrivers = new Set<number>();
    this.distinctLineNumbers = new Set<number>();
    const uniqueDates = new Set<string>();

    this.data.forEach((trip: any) => {
      if (trip.driver && trip.driver.id) {
        this.distinctDrivers.add(trip.driver.id);
        this.nbrOfDrivers = this.distinctDrivers.size;
      }
      if (trip.lineNumber) {
        this.distinctLineNumbers.add(trip.lineNumber);
        this.nbrOfLines = this.distinctLineNumbers.size;
      }
      if (trip.plannedDeparture) {
        const date = new Date(trip.plannedDeparture).toDateString();
        uniqueDates.add(date);
      }
    });

    this.nbrOfDays = uniqueDates.size;
  }

  /**
   * Handles the next button click event, verifies the trip schedule using the provided start date,
   * and processes the API response.
   *
   * @param startDate The start date to verify the trip schedule.
   */

  onNextClick(startDate: any) {
    if (!startDate) {
      console.error("Start date is not provided.");
      return;
    }
    const date = new Date(
      Date.UTC(startDate.year, startDate.month - 1, startDate.day)
    );
    if (isNaN(date.getTime())) {
      console.error("Invalid start date:", startDate);
      return;
    }
    const formattedStartDate = date.toISOString().split("T")[0];

    const url = OPN_BASE_URL + "/trips-instance/same-schedule";
    // const apiUrl = `${OPN_BASE_URL}/trips-instance/instances-in-range`;
    const days = this.nbrOfDays;
    const instancesToCheck = Array.from(this.data);

    this.crudService
      .verifyTripSchedule(url, formattedStartDate, days, instancesToCheck)
      .subscribe(
        (response: any) => {
          const groupedInstancesToCheck = this.groupByIndex(instancesToCheck);

          // Group and flatten instancesToExport
          const flattenedInstancesToExport = [].concat(
            ...Object.keys(response.body.instances).map(
              (key) => response.body.instances[key]
            )
          );

          const groupedInstancesToExport = this.groupByIndex(
            flattenedInstancesToExport
          );

          // Filter instancesToExport based on matching lineNumber in instancesToCheck

          const { matchedInstances, missingInstances } =
            this.matchAndIdentifyMissingInstances(
              groupedInstancesToCheck,
              groupedInstancesToExport
            );

          this.instancesToExport = matchedInstances;
          this.missingInstances = missingInstances;

          if (!missingInstances || missingInstances.length === 0) {
            this.sameSchedule = true;
            this.isNextDisabled = this.instancesToExport.length === 0;
          } else {
            this.sameSchedule = false;
            this.isNextDisabled = false;
          }

          if (this.instancesToExport.length === 0) {
            this.isNextDisabled = true;
          }
        },
        (error) => {
          console.error("API Error:", error);
          this.isNextDisabled = true;
        }
      );
  }

  /**
   * Groups instances by their index, identified by the unique planned departure date.
   *
   * @param instances The list of instances to group.
   * @return An object containing instances grouped by their index.
   */

  groupByIndex(instances: any[]): { [key: number]: any[] } {
    let index = 0;
    const groupedByIndex: { [key: number]: any[] } = {};
    const datesSeen = new Set<string>();

    instances.forEach((instance) => {
      const date = instance.plannedDeparture.split("T")[0];
      if (!datesSeen.has(date)) {
        datesSeen.add(date);
        index++;
      }
      if (!groupedByIndex[index]) {
        groupedByIndex[index] = [];
      }
      groupedByIndex[index].push(instance);
    });

    return groupedByIndex;
  }

  /**
   * Filters and matches instances from grouped instances to check and export.
   * Identifies missing instances based on the line number and direction.
   *
   * @param groupedInstancesToCheck Instances to check, grouped by index.
   * @param groupedInstancesToExport Instances to export, grouped by index.
   * @return A map containing matched instances and missing instances.
   */

  filterInstances(
    groupedInstancesToCheck: { [key: number]: any[] },
    groupedInstancesToExport: { [key: number]: any[] }
  ): any[] {
    const filteredInstances: any[] = [];

    for (const index in groupedInstancesToExport) {
      const checkInstances = groupedInstancesToCheck[index] || [];
      const exportInstances = groupedInstancesToExport[index] || [];

      const filteredForIndex = exportInstances.filter((exportInstance) =>
        checkInstances.some(
          (checkInstance) =>
            checkInstance.lineNumber === exportInstance.lineNumber
        )
      );

      filteredInstances.push(...filteredForIndex);
    }

    return filteredInstances;
  }

  /**
   * Matches and identifies missing instances between two groups of instances.
   *
   * @param groupedInstancesToCheck - An object containing arrays of instances grouped by an index. These are the instances to check.
   * @param groupedInstancesToExport - An object containing arrays of instances grouped by an index. These are the instances to compare against.
   * @returns An object containing arrays of matched instances and missing instances.
   */

  matchAndIdentifyMissingInstances(
    groupedInstancesToCheck: { [key: number]: any[] },
    groupedInstancesToExport: { [key: number]: any[] }
  ): { matchedInstances: any[]; missingInstances: any[] } {
    const matchedInstances: any[] = [];
    const missingInstances: any[] = [];

    for (const index in groupedInstancesToCheck) {
      const checkInstances = groupedInstancesToCheck[index] || [];
      const exportInstances = groupedInstancesToExport[index] || [];

      checkInstances.forEach((checkInstance) => {
        const matchingInstance = exportInstances.find(
          (exportInstance) =>
            exportInstance.lineNumber === checkInstance.lineNumber &&
            exportInstance.lineDirection === checkInstance.lineDirection
        );

        if (matchingInstance) {
          // If a matching instance is found, add it to matchedInstances
          matchedInstances.push(matchingInstance);
        } else {
          // If no matching instance is found, add the checkInstance to missingInstances
          missingInstances.push(checkInstance);
        }
      });
    }

    return { matchedInstances, missingInstances };
  }

  /**
   * Matches and assigns data from original instances to export instances.
   *
   * @param dataGroupedByIndex - An object containing arrays of original instances grouped by an index.
   * @param instancesToExportGroupedByIndex - An object containing arrays of instances to export grouped by an index.
   */

  matchAndAssignData(
    dataGroupedByIndex: any,
    instancesToExportGroupedByIndex: any
  ): void {
    let instancesToKeep: any[] = [];

    for (const index in instancesToExportGroupedByIndex) {
      const originalInstances = dataGroupedByIndex[index] || [];

      const exportInstances = instancesToExportGroupedByIndex[index];

      // Filter exportInstances and keep only those with a matching instance
      const filteredInstances = exportInstances.filter((exportInstance) => {
        const matchingInstance = originalInstances.find((origInstance) => {
          return origInstance.lineDirection === exportInstance.lineDirection;
        });

        if (matchingInstance) {
          // Keep existing driver if already assigned, otherwise assign from matchingInstance
          if (!exportInstance.driver || !exportInstance.driver.matricule) {
            exportInstance.driver = { ...matchingInstance.driver };
          }

          // Keep existing receiver if already assigned, otherwise assign from matchingInstance
          if (!exportInstance.receiver || !exportInstance.receiver.matricule) {
            exportInstance.receiver = { ...matchingInstance.receiver };
          }

          // Keep existing bus if already assigned, otherwise assign from matchingInstance
          if (!exportInstance.bus || !exportInstance.bus.vehicleNumber) {
            exportInstance.bus = { ...matchingInstance.bus };
          }

          return true; // Keep this instance in the filtered list
        } else {
          return false; // Remove this instance from the list
        }
      });

      // Add filtered instances to the list of instances to keep
      instancesToKeep = instancesToKeep.concat(filteredInstances);
    }

    // Update instancesToExport with only the instances that have matches
    this.instancesToExport = instancesToKeep;
  }

  /**
   * Compares the time components of two ISO 8601 date-time strings.
   *
   * @param dateTime1 - The first date-time string in ISO 8601 format.
   * @param dateTime2 - The second date-time string in ISO 8601 format.
   * @returns `true` if the time components are identical, otherwise `false`.
   */

  compareDateTimes(dateTime1: string, dateTime2: string): boolean {
    const time1 = dateTime1.split("T")[1]; // Extract the time part (e.g., "09:30:00")
    const time2 = dateTime2.split("T")[1]; // Extract the time part (e.g., "09:30:00")

    return time1 === time2; // Compare the time parts
  }

  /**
   * Transforms the instances to export by grouping them by their planned departure dates.
   *
   * @returns An array of objects, each containing a date, an array of instances for that date, and a collapsed state.
   */

  transformInstancesByDate(): any[] {
    if (this.instancesToExport) {
      const grouped = this.instancesToExport.reduce((acc, instance) => {
        const date = instance.plannedDeparture.split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(instance);
        return acc;
      }, {} as { [key: string]: any[] });

      return Object.keys(grouped).map((date) => ({
        date,
        instances: grouped[date],
        isCollapsed: false, // Initially, all groups are open
      }));
    }
    return [];
  }

  /**
   * Toggles the collapsed state of a group of instances at a given index.
   *
   * @param index - The index of the group to toggle.
   */

  toggleCollapse(index: number) {
    this.groupedInstances[index].isCollapsed =
      !this.groupedInstances[index].isCollapsed;
  }

  /**
   * Retrieves the day of the week from a given date string.
   *
   * @param dateString - A string representing a date.
   * @returns The name of the day of the week in French corresponding to the given date.
   */

  getDayOfWeek(dateString: string): string {
    const days = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    const date = new Date(dateString);
    return days[date.getDay()];
  }
  /**
   * Returns the view to the form state.
   */

  returnToForm() {
    this.viewState = "form";
  }

  /**
   * Handles the action when the "Next" button is clicked. Transitions the view state and processes instances for export.
   */
  onNextClicked() {
    if (this.viewState === "form") {
      this.formViewData = {
        groupedInstances: this.groupedInstances,
        instancesToExport: this.instancesToExport,
      };
      this.viewState = "review";

      this.groupedInstances = this.transformInstancesByDate();
    } else if (this.viewState === "review") {
      this.reviewViewData = {
        groupedInstances: this.groupedInstances,
        instancesToExport: this.instancesToExport,
      };
      // Store the current state of instancesToExport before modifications
      this.reviewStateInstancesToExport = JSON.parse(
        JSON.stringify(this.instancesToExport)
      );

      this.isLoading = true;
      this.viewState = "export";

      const { groupedByIndex: dataGroupedByIndex } = this.groupInstancesByIndex(
        Array.from(this.data)
      );
      const { groupedByIndex: instancesToExportGroupedByIndex } =
        this.groupInstancesByIndex(this.instancesToExport);

      this.matchAndAssignData(
        dataGroupedByIndex,
        instancesToExportGroupedByIndex
      );

      // Call checkAvailabilityForInstances after updating instancesToExport
      this.checkAvailabilityForInstances();
    }
  }

  /**
   * Returns to the previous view state, restoring any modifications made to the instances.
   */

  returnToPrevious() {
    if (this.viewState === "export") {
      // Restore the state of instancesToExport from before matchAndAssign and checkAvailability
      this.instancesToExport = JSON.parse(
        JSON.stringify(this.reviewStateInstancesToExport)
      );
      this.viewState = "review";
      this.groupedInstances = this.transformInstancesByDate();
    } else if (this.viewState === "review") {
      this.checkAvailabilityResponse = null;
      this.viewState = "form";
    }
  }

  /**
   * Groups instances by an index based on their planned departure dates.
   *
   * @param instances - An array of instances to group.
   * @returns An object containing grouped instances and a mapping from date to index.
   */

  groupInstancesByIndex(instances: any[]): any {
    let index = 1;
    const groupedByIndex: { [key: number]: any[] } = {};

    const dateToIndexMap: { [key: string]: number } = {};

    instances.forEach((instance) => {
      const date = instance.plannedDeparture.split("T")[0];
      if (!(date in dateToIndexMap)) {
        dateToIndexMap[date] = index++;
      }
    });

    instances.forEach((instance) => {
      const date = instance.plannedDeparture.split("T")[0];
      const instanceIndex = dateToIndexMap[date];

      if (!groupedByIndex[instanceIndex]) {
        groupedByIndex[instanceIndex] = [];
      }
      groupedByIndex[instanceIndex].push(instance);
    });

    return { groupedByIndex, dateToIndexMap };
  }

  /**
   * Checks the availability of instances by calling an API and updates the instances based on the response.
   */

  checkAvailabilityForInstances(): void {
    const url = OPN_BASE_URL + "/trips-instance/check-all-availabilities";

    // Prepare the data for the API
    const startDate = (this.instancesToExport[0] as any)?.plannedDeparture;
    const endDate = (
      this.instancesToExport[this.instancesToExport.length - 1] as any
    )?.estimatedArrival;

    // Call the API
    this.crudService
      .checkAvailabilityForTrips(
        url,
        this.instancesToExport,
        startDate,
        endDate
      )
      .pipe(
        tap((response: any) => {
          if (response.status === 200) {
            this.checkAvailabilityResponse = response.body;

            this.instancesToExport = (response.body as any[]).map(
              (instance: any) => {
                const existingInstance =
                  this.instancesToExport.find((i) => i.id === instance.id) ||
                  {};
                return {
                  ...instance,
                  overrideDriver: existingInstance.overrideDriver ?? false,
                  overrideBus: existingInstance.overrideBus ?? false,
                  overrideReceiver: existingInstance.overrideReceiver ?? false,
                  overrideDriverDayOff:
                    existingInstance.overrideDriverDayOff ?? false,
                  overrideReceiverDayOff:
                    existingInstance.overrideReceiverDayOff ?? false,
                };
              }
            );

            this.groupedInstances = this.transformInstancesByDate();
            this.isLoading = false;
            this.cdr.detectChanges();
          }
        }),
        catchError((error) => {
          console.error("Error during availability checks:", error);
          return throwError(error);
        })
      )
      .subscribe();
  }

  /**
   * Saves the grouped instances by calling the update API and closes the active modal.
   */

  onSave(): void {
    this.crudService
      .updateGroupedInstances(
        OPN_BASE_URL + "/trips-instance/update-grouped",
        this.groupedInstances
      )
      .subscribe(
        (response) => {
          this.itemUpdated.emit();
          this.activeModal.close();
          this.notyService.displayNotification(
            "Trips updated successfully",
            "success"
          );
        },
        (error) => {
          console.error("Erreur lors de la mise à jour des instances", error);
          this.notyService.displayNotification("Failed update", "error");
        }
      );
  }

  /**
   * Removes the driver from the given trip and updates the instances to export.
   *
   * @param trip The trip object from which the driver should be removed.
   */
  removeDriver(trip: any) {
    trip.removedDriver = trip.driver;
    trip.driver = null;
    this.updateInstancesToExport({
      ...trip,
      plannedDeparture: trip.plannedDeparture,
    });
  }

  /**
   * Restores the previously removed driver to the given trip and updates the instances to export.
   *
   * @param trip The trip object to which the driver should be restored.
   */
  undoRemoveDriver(trip: any) {
    trip.driver = trip.removedDriver;
    trip.removedDriver = null;
    this.updateInstancesToExport({
      ...trip,
      plannedDeparture: trip.plannedDeparture,
    });
  }

  /**
   * Removes the bus from the given trip and updates the instances to export.
   *
   * @param trip The trip object from which the bus should be removed.
   */
  removeBus(trip: any) {
    trip.removedBus = trip.bus;
    trip.bus = null;
    this.updateInstancesToExport({
      ...trip,
      plannedDeparture: trip.plannedDeparture,
    });
  }

  /**
   * Restores the previously removed bus to the given trip and updates the instances to export.
   *
   * @param trip The trip object to which the bus should be restored.
   */

  undoRemoveBus(trip: any) {
    trip.bus = trip.removedBus;
    trip.removedBus = null;
    this.updateInstancesToExport({
      ...trip,
      plannedDeparture: trip.plannedDeparture,
    });
  }

  /**
   * Removes the receiver from the given trip and updates the instances to export.
   *
   * @param trip The trip object from which the receiver should be removed.
   */

  removeReceiver(trip: any) {
    trip.removedReceiver = trip.receiver;
    trip.receiver = null;
    this.updateInstancesToExport({
      ...trip,
      plannedDeparture: trip.plannedDeparture,
    });
  }

  /**
   * Restores the previously removed receiver to the given trip and updates the instances to export.
   *
   * @param trip The trip object to which the receiver should be restored.
   */
  undoRemoveReceiver(trip: any) {
    trip.receiver = trip.removedReceiver;
    trip.removedReceiver = null;
    this.updateInstancesToExport({
      ...trip,
      plannedDeparture: trip.plannedDeparture,
    });
  }

  /**
   * Updates the instances to export with the latest changes made to the given trip.
   *
   * @param updatedTrip The trip object with the latest changes to be reflected in the instances to export.
   */

  updateInstancesToExport(updatedTrip: any): void {
    // Find the date of the updated trip
    const updatedTripDate = new Date(
      updatedTrip.plannedDeparture
    ).toDateString();

    this.instancesToExport = this.instancesToExport.map((instance: any) => {
      // Only update instances with matching idTrip AND date
      if (
        instance.idTrip === updatedTrip.idTrip &&
        new Date(instance.plannedDeparture).toDateString() === updatedTripDate
      ) {
        return { ...instance, ...updatedTrip };
      }
      return instance;
    });

    const collapseState = this.groupedInstances.map(
      (group) => group.isCollapsed
    );

    this.groupedInstances = this.transformInstancesByDate();

    this.groupedInstances.forEach((group, index) => {
      if (index < collapseState.length) {
        group.isCollapsed = collapseState[index];
      }
    });

    this.cdr.detectChanges();
  }

  /**
   * Toggles the overrideDriver flag for the given trip.
   *
   * @param trip The trip object for which the overrideDriver flag should be toggled.
   * @param checked The new value of the overrideDriver flag.
   */

  toggleOverrideDriver(trip: any, checked: boolean): void {
    trip.overrideDriver = checked;
  }
  /**
   * Toggles the overrideReceiverDayOff flag for the given trip.
   *
   * @param trip The trip object for which the overrideReceiverDayOff flag should be toggled.
   * @param checked The new value of the overrideReceiverDayOff flag.
   */

  toggleOverrideReceiverDayOff(trip: any, checked: boolean): void {
    trip.overrideReceiverDayOff = checked;
  }

  /**
   * Toggles the overrideDriverDayOff flag for the given trip.
   *
   * @param trip The trip object for which the overrideDriverDayOff flag should be toggled.
   * @param checked The new value of the overrideDriverDayOff flag.
   */

  toggleOverrideDriverDayOff(trip: any, checked: boolean): void {
    trip.overrideDriverDayOff = checked;
  }
  /**
   * Toggles the overrideReceiver flag for the given trip.
   *
   * @param trip The trip object for which the overrideReceiver flag should be toggled.
   * @param checked The new value of the overrideReceiver flag.
   */

  toggleOverrideReceiver(trip: any, checked: boolean): void {
    trip.overrideReceiver = checked;
  }
  /**
   * Toggles the overrideBus flag for the given trip.
   *
   * @param trip The trip object for which the overrideBus flag should be toggled.
   * @param checked The new value of the overrideBus flag.
   */

  toggleOverrideBus(trip: any, checked: boolean): void {
    trip.overrideBus = checked;
  }

  // tooltip overlapping Trpis
  toggleOverlappingTrips(
    popover,
    overlappingTrigger: string,
    currentTrip: any
  ) {
    let overlappings = [];

    let overlappingMsg = "";
    // Driver_On_Leave
    // Driver_Already_Affected
    // Assignation_OK
    // Assigned
    // Not_Assigned

    let assignationEmpty = false;

    if (this.checkAvailabilityResponse) {
      // verification done
      //  get the overlapping trips
      let tripOverlapping = this.checkAvailabilityResponse.find(
        (t) => t.id == currentTrip.id
      );

      if (tripOverlapping) {
        switch (overlappingTrigger) {
          case "Driver":
            {
              if (!currentTrip.driver) {
                overlappingMsg = "Not_Assigned";
                assignationEmpty = true;
              } else {
                if (tripOverlapping.driverConflict) {
                  overlappingMsg = "Already_Affected";
                  overlappings = tripOverlapping.driverOverlappingTrips;
                } else if (tripOverlapping.driverDayOff) {
                  overlappingMsg = "Driver_On_Leave";
                  assignationEmpty = true;
                } else {
                  overlappingMsg = "Assignation_OK";
                }

                assignationEmpty = false;
              }
            }
            break;
          case "Receiver":
            {
              if (!currentTrip.receiver) {
                overlappingMsg = "Not_Assigned";
                assignationEmpty = true;
              } else {
                if (tripOverlapping.receiverConflict) {
                  overlappingMsg = "Already_Affected";
                  overlappings = tripOverlapping.receiverOverlappingTrips;
                } else if (tripOverlapping.receiverDayOff) {
                  overlappingMsg = "Receiver_On_Leave";
                  assignationEmpty = true;
                } else {
                  overlappingMsg = "Assignation_OK";
                }
                assignationEmpty = false;
              }
            }
            break;
          case "Bus":
            {
              if (!currentTrip.bus) {
                overlappingMsg = "Not_Assigned";
                assignationEmpty = true;
              } else {
                if (tripOverlapping.busConflict) {
                  overlappingMsg = "Already_Affected";
                  overlappings = tripOverlapping.busOverlappingTrips;
                } else {
                  overlappingMsg = "Assignation_OK";
                }
                assignationEmpty = false;
              }
            }
            break;

          default:
        }
      }
      if (popover.isOpen()) {
        popover.close();
      } else {
        popover.open({
          overlappings,
          overlappingMsg,
          assignationEmpty,
          overlappingTrigger,
        });
      }
    } // verification not yet
    else {
      if (popover.isOpen()) {
        popover.close();
      } else {
        switch (overlappingTrigger) {
          case "Driver":
            {
              if (currentTrip.driver) {
                overlappingMsg = "Assigned";
                assignationEmpty = false;
              } else {
                overlappingMsg = "Not_Assigned";
                assignationEmpty = true;
              }
            }
            break;
          case "Receiver":
            {
              if (currentTrip.receiver) {
                overlappingMsg = "Assigned";
                assignationEmpty = false;
              } else {
                overlappingMsg = "Not_Assigned";
                assignationEmpty = true;
              }
            }
            break;
          case "Bus":
            {
              if (currentTrip.bus) {
                overlappingMsg = "Assigned";
                assignationEmpty = false;
              } else {
                overlappingMsg = "Not_Assigned";
                assignationEmpty = true;
              }
            }
            break;

          default:
        }
        popover.open({
          overlappings,
          overlappingMsg,
          assignationEmpty,
          overlappingTrigger,
        });
      }
    }
  }
}
