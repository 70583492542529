import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OPN_BASE_URL } from 'app/shared/global/var';
import { CrudService } from 'app/shared/services/crud.service';
import { NotyService } from 'app/shared/services/noty.service';
import { forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-duplicate-itinerary',
  templateUrl: './duplicate-itinerary.component.html',
  styleUrls: ['./duplicate-itinerary.component.scss']
})
export class DuplicateItineraryComponent implements OnInit {
  
  @Input() itinerary: any;
  @Input() itinerariesLength;
  @Output() itineraryDuplicated: EventEmitter<any> = new EventEmitter<any>();
  
  constructor( public activeModal: NgbActiveModal,
    private crudService: CrudService, // Assurez-vous d'importer le service
    private notyService: NotyService 
  ) { }

  ngOnInit(): void {
   
  
  }

  save() {
    // Créer une copie de l'itinéraire avec un nouveau libellé
    const duplicatedItinerary = {
      ...this.itinerary,
      itineraryLib: `${this.itinerary.itineraryLib} (Copie)`,
      id: null, // Supprimer l'ID pour forcer un nouvel enregistrement
      itineraryNo:this.itinerariesLength + 1

    };

    // Supprimer l'ID des stations pour qu'elles soient créées comme nouvelles
    if (duplicatedItinerary.itineraryStations) {
      duplicatedItinerary.itineraryStations = duplicatedItinerary.itineraryStations.map(station => {
        const { id, ...stationWithoutId } = station;
        return stationWithoutId;
      });
    }

    // Appeler le service pour ajouter le nouvel itinéraire
    this.crudService.post(OPN_BASE_URL + "/itinerary/add", duplicatedItinerary)
      .pipe(
        switchMap((response: any) => {
          const itineraryId = response.id;

          // Ajouter les stations du nouvel itinéraire
          const addStationObservables = duplicatedItinerary.itineraryStations.map(
            (station) =>
              this.crudService.post(OPN_BASE_URL + "/itinerary-station/add", {
                ...station,
                itineraryId: itineraryId,
              })
          );

          // Exécuter l'ajout de toutes les stations
          return forkJoin([of(response), ...addStationObservables]);
        })
      )
      .subscribe({
        next: (responses) => {
          // Fermer le modal
          this.itineraryDuplicated.emit(responses[0]);
          this.activeModal.close();
          
          // Afficher une notification de succès
          this.notyService.displayNotification(
            "Itinerary duplicated successfully",
            "success"
          );
        },
        error: (error) => {
          console.error("Error duplicating itinerary:", error);
          
          // Afficher une notification d'erreur
          this.notyService.displayNotification(
            "Failed to duplicate itinerary",
            "error"
          );
        }
      });
  }

}
