import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-kpi-widget',
  templateUrl: './kpi-widget.component.html',
  styleUrls: ['./kpi-widget.component.scss']
})
export class KpiWidgetComponent {
  @Input() title: string = '';
  @Input() value: string = '';
  @Input() unit: string = '';
  @Input() backgroundColor: string = '#ffd6d6'; 
}