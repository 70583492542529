import { CrudService } from "app/shared/services/crud.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  BASE_API_FLEET,
  M_BASE_API,
  M_BASE_URL,
  OPN_BASE_URL,
} from "app/shared/global/var";
import { Vehicle, WorkRequest } from "../../models/work-request";
import { AuthService } from "app/shared/auth/auth.service";
import { NotyService } from "app/shared/services/noty.service";
import { WorkRequestService } from "../../services/work-request.service";
import { ImageModalComponent } from "../image-modal/image-modal.component";
import { VehiclePositionComponent } from "../vehicle-position/vehicle-position.component";

import { ConfirmationModalComponent } from "app/shared/modals/confirmation-modal/confirmation-modal.component";
import swal from "sweetalert2";
import { use } from "echarts";
import { Router } from "@angular/router";
const MAX_IMAGE_UPLOAD_LIMIT = 3; // Maximum number of images allowed
const MAX_TOTAL_IMAGE_SIZE_MB = 20; // Maximum total size of all images
const MAX_SINGLE_IMAGE_SIZE_MB = 10; // Maximum size of a single image

interface Operation {
  id: number;
  operationName: string;
  workOrder: WorkOrder;
}

interface WorkOrder {
  idWO: number;
  workOrderNumber: string;
  workOrderDate: Date;
}

interface Items {
  id: number;
  name: string;
}
interface ItemsVehicle {
  id: number;
  name: string;
  type: number;
}
interface MaintenanceType {
  id: number;
  name: string;
  categoryId: number;
}
@Component({
  selector: "app-add-work-request",
  templateUrl: "./add-work-request.component.html",
  styleUrls: [
    "./add-work-request.component.scss",
    "../../../../../../assets/sass/libs/select.scss",
  ],
})
export class AddWorkRequestComponent implements OnInit {
  @Input() type: string;
  @Input() data: WorkRequest;
  applicants: Items[] = [];
  vehicleType: Items[] = [];
  vehicles: ItemsVehicle[] = [];
  filtredVehicleType: Items[] = [];
  filtredVehicles: Items[] = [];
  appointments: any[] = []; // Add this line for appointments
  selectedTypeInterventionType = null;
  selectedTypeInterventionNature = null;
  interventionCategories: any[] = [];
  constraintConfig: any;
  maintenanceTypes: MaintenanceType[] = [];
  filteredMaintenanceTypes: MaintenanceType[] = [];
  selectedMaintenanceType: MaintenanceType | null = null;
  categoryName: string = "";
  showCategorySelect = false;
  selectedApplicant: any;
  selectedType: any = null;
  selectedFiles: any[] = [];
  notValid: boolean = false;
  notValidOperation: boolean = false;
  isError: boolean = false;
  enPanne: boolean = false;
  formGroup: FormGroup;
  interventionTypes = [];
  interventionNatures = [];
  filteredInterventionNatures = [];
  filteredInterventionTypes = [];
  connectedUserFullName: string;
  connectedUserName: string;
  enlargedImageSrc: string;
  maxImageUploadLimit = MAX_IMAGE_UPLOAD_LIMIT;
  maxTotalImageSizeMB = MAX_TOTAL_IMAGE_SIZE_MB;
  maxSingleImageSizeMB = MAX_SINGLE_IMAGE_SIZE_MB;
  errorMessage: string = "";
  allVehicles: any;
  showOperationsTable: boolean = false;
  langue = localStorage.getItem("langue");
  vehicleDetails: any;
  isDetailsView: boolean = false;
  operationHasDate: boolean = false;
  private currentOperation: any = null;
  @Output() itemRejected: EventEmitter<void> = new EventEmitter<void>();
  @Output() operationCreated: EventEmitter<void> = new EventEmitter<void>();
  @Output() requestValidated: EventEmitter<void> = new EventEmitter<void>();
  state = [
    {
      id: 1,
      nameEn: "Waiting for validation",
      nameFr: "En attente de validation",
      color: "#616D89",
      bgName: "secondary",
    },
    {
      id: 2,
      nameEn: "In process",
      nameFr: "En attente de traitement",
      color: "#f51c0c",
      bgName: "danger",
    },
    {
      id: 3,
      nameEn: "Rejected",
      nameFr: "Rejeté",
      color: "#0a0a0a",
      bgName: "black",
    },
    {
      id: 4,
      nameEn: "In Progress",
      nameFr: "En cours de traitement",
      color: "#a169ff",
      bgName: "primary",
    },
    {
      id: 5,
      nameEn: "Processed",
      nameFr: "Traité",
      color: "#084f02",
      bgName: "succes",
    },
    {
      id: 6,
      nameEn: "Partially Processed",
      nameFr: "Partiellement Traité",
      color: "#1890ff",
      bgName: "info",
    },
  ];

  workOrders: WorkOrder[] = [
    {
      idWO: 1,
      workOrderNumber: "WO-2024-001",
      workOrderDate: new Date("2024-01-15"),
    },
    {
      idWO: 2,
      workOrderNumber: "WO-2024-002",
      workOrderDate: new Date("2024-01-20"),
    },
    {
      idWO: 3,
      workOrderNumber: "WO-2024-003",
      workOrderDate: new Date("2024-02-01"),
    },
  ];

  newOperationForm: FormGroup;
  showAddOperation = false;
  editingIndex: number | null = null;
  showImmobilizationToggle: boolean;
  currentMaintenanceType: number;

  constructor(
    public activeModal: NgbActiveModal,
    private notyService: NotyService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private crudService: CrudService,
    private authService: AuthService,
    private workRequestService: WorkRequestService
  ) {
    this.initNewOperationForm();
  }

  ngOnInit(): void {
    this.fetchVehicleTypes();
    this.connectedUserFullName = this.authService.getUserFullNameFromToken();
    this.connectedUserName = this.authService.getUsernameFromToken();
    this.fetchVehicles(this.connectedUserName);
    this.getSettings();
    this.fetchInterventionCategories();
    const now = new Date();
    const timezoneOffset = now.getTimezoneOffset() * 60000;
    const today = now.toISOString().substring(0, 10);
    const localDate = new Date(now.getTime() - timezoneOffset)
      .toISOString()
      .substring(0, 16);
    this.formGroup = this.formBuilder.group({
      requestDate: [localDate, Validators.required],
      requestType: ["Repair", Validators.required],
      description: ["", Validators.required],
      urgency: [null, Validators.required],
      applicant: [this.connectedUserFullName, Validators.required],
      vehicleTypeId: [null, Validators.required],
      vehiclesId: [null, Validators.required],
      interventionCategoryId: [null],
      renewalDate: [""],
      files: this.formBuilder.array([]),
      state: [1, Validators.required],
      operationsArray: this.formBuilder.array([]),
    });
    this.formGroup.get("requestType").valueChanges.subscribe((value) => {
      this.updateInterventionCategory(value);
    });

    if ((this.type === "update" || this.type === "details") && this.data) {
      this.loadDataIntoForm();
      this.fetchInterventionTypes();
      this.fetchInterventionNatures();
      if (this.data.state >= 2 && this.data.state !== 3) {
        this.showOperationsTable = true;
      }

      if (this.data.interventionCategory) {
        this.categoryName = this.data.interventionCategory.name;
        this.fetchMaintenanceTypesByCategory(this.data.interventionCategoryId);
      }
    }
  }

  onInterventionTypeChange(event: any): void {
    this.filteredInterventionNatures = this.interventionNatures.filter(
      (it) => it.interventionTypeId == event.id
    );
  }

  onInterventionNatureChange(event: any): void {
    const selectedNature = this.interventionNatures.find(
      (nature) => nature.id === event.id
    );
    if (selectedNature) {
      this.newOperationForm
        .get("interventionTypeId")
        .setValue(selectedNature.interventionTypeId, {
          emitEvent: false,
        });
    }
  }

  private fetchInterventionTypes(): void {
    const url = `${M_BASE_URL}/interventionType`;
    this.crudService.getAll(url).subscribe(
      (data: any) => {
        this.interventionTypes = data.data.map((item) => ({
          id: item.id,
          name: item.name,
          type: item.type,
        }));
      },
      (error) => console.error("Error fetching intervention types:", error)
    );
  }

  private fetchInterventionNatures(): void {
    const url = `${M_BASE_URL}/interventionNature/nature`;
    this.crudService.getAll(url).subscribe(
      (data: any) => {
        this.interventionNatures = data;
        this.filteredInterventionNatures = [...this.interventionNatures];
      },
      (error) => console.error("Error fetching intervention natures:", error)
    );
  }

  get operationsArray() {
    return this.formGroup.get("operationsArray") as FormArray;
  }

  initNewOperationForm() {
    this.newOperationForm = this.formBuilder.group({
      maintenanceTypeId: [
        { value: null, disabled: false },
        Validators.required,
      ],
      interventionTypeId: [
        { value: null, disabled: false },
        Validators.required,
      ],
      interventionNatureId: [
        { value: null, disabled: false },
        Validators.required,
      ],
      operationDescription: [
        { value: "", disabled: false },
        Validators.required,
      ],
      workOrderDate: [{ value: null, disabled: false }],
      isImmobilized: [{ value: false, disabled: false }],
      immobilizationReason: [{ value: "", disabled: false }],
    });
  }
  viewOperationDetails(index: number) {
    this.isDetailsView = true;
    this.showAddOperation = true;
    this.editingIndex = index;
    const operation = this.operationsArray.at(index);
    this.currentOperation = { ...operation.value }; // Store complete operation data
    // Set form values before disabling fields to ensure they retain values
    this.newOperationForm.patchValue({
      maintenanceTypeId: operation.value.maintenanceTypeId,
      interventionTypeId: operation.value.interventionTypeId,
      interventionNatureId: operation.value.interventionNatureId,
      operationDescription: operation.value.operationDescription,
      workOrderDate: operation.value.workOrderDate,
      isImmobilized: operation.value.isImmobilized,
      immobilizationReason: operation.value.immobilizationReason,
    });

    // Now disable fields
    this.newOperationForm.get("maintenanceTypeId").disable();
    this.newOperationForm.get("interventionTypeId").disable();
    this.newOperationForm.get("interventionNatureId").disable();
    this.newOperationForm.get("operationDescription").disable();

    // Enable only date and immobilization fields
    this.newOperationForm.get("workOrderDate").enable();
    this.newOperationForm.get("isImmobilized").enable();
    this.newOperationForm.get("immobilizationReason").enable();

    this.operationHasDate = !!operation.value.datePrevuWorkOrder;
  }

  fetchMaintenanceTypesByCategory(categoryId: number) {
    const url = `${M_BASE_URL}/maintenanceType/category/${categoryId}`;
    this.crudService.getAll(url).subscribe(
      (data: any) => {
        this.maintenanceTypes = data.data;
        this.filterMaintenanceTypes();
      },
      (error) => console.error("Error fetching maintenance types:", error)
    );
  }

  // Add method to filter maintenance types based on conditions
  filterMaintenanceTypes() {
    if (!this.constraintConfig || !this.data) return;

    const requestType = this.data.requestType;

    if (requestType === "Maintenance") {
      if (this.data.scheduledTaskId) {
        // Maintenance Systématique
        if (this.constraintConfig.maintenance_systematique) {
          this.filteredMaintenanceTypes = this.maintenanceTypes.filter(
            (mt) => mt.id === this.constraintConfig.maintenance_systematique.id
          );
          this.newOperationForm.patchValue({
            maintenanceTypeId: this.filteredMaintenanceTypes[0]?.id,
          });
          this.currentMaintenanceType = this.filteredMaintenanceTypes[0]?.id
        } else {
          // Fallback: show all maintenance types if systematique is null
          this.filteredMaintenanceTypes = this.maintenanceTypes;
        }
      } else {
        // Maintenance Conditionnelle
        if (this.constraintConfig.maintenance_conditionnelle) {
          this.filteredMaintenanceTypes = this.maintenanceTypes.filter(
            (mt) =>
              mt.id === this.constraintConfig.maintenance_conditionnelle.id
          );
          this.newOperationForm.patchValue({
            maintenanceTypeId: this.filteredMaintenanceTypes[0]?.id,
          });
          this.currentMaintenanceType = this.filteredMaintenanceTypes[0]?.id
        } else {
          // Fallback: show all maintenance types if conditionnelle is null
          this.filteredMaintenanceTypes = this.maintenanceTypes;
        }
      }
    } else if (requestType === "Repair") {
      // For repairs, show all maintenance types for curative category
      this.filteredMaintenanceTypes = this.maintenanceTypes;
    }

    // If no config found or no matches, show all maintenance types for the category
    if (
      !this.filteredMaintenanceTypes ||
      this.filteredMaintenanceTypes.length === 0
    ) {
      this.filteredMaintenanceTypes = this.maintenanceTypes;
    }
  }

  toggleAddOperation() {
    this.isDetailsView = false;
    this.showAddOperation = true;
    this.editingIndex = null;
    this.operationHasDate = false;
    this.newOperationForm.reset();
    this.newOperationForm.patchValue({
      maintenanceTypeId: this.currentMaintenanceType
  });
    // enable all main fields
    this.newOperationForm.get("maintenanceTypeId").enable();
    this.newOperationForm.get("interventionTypeId").enable();
    this.newOperationForm.get("interventionNatureId").enable();
    this.newOperationForm.get("operationDescription").enable();

    // Hide and disable date and immobilization fields
    this.newOperationForm.get("workOrderDate").disable();
    this.newOperationForm.get("isImmobilized").disable();
    this.newOperationForm.get("immobilizationReason").disable();
  }

  editOperation(index: number) {
    this.isDetailsView = false;
    this.showAddOperation = true;
    this.editingIndex = index;
    const operation = this.operationsArray.at(index);

    // Enable main fields
    this.newOperationForm.get("maintenanceTypeId").enable();
    this.newOperationForm.get("interventionTypeId").enable();
    this.newOperationForm.get("interventionNatureId").enable();
    this.newOperationForm.get("operationDescription").enable();

    // Disable date and immobilization fields
    this.newOperationForm.get("workOrderDate").disable();
    this.newOperationForm.get("isImmobilized").disable();
    this.newOperationForm.get("immobilizationReason").disable();

    this.newOperationForm.patchValue(operation.value);
  }
  getStateNameById(id: number): string {
    const matchedState = this.state.find((st) => st.id === id);
    const language = localStorage.getItem("langue") || "en"; // Langue par défaut: anglais
    if (matchedState) {
      return language === "fr" ? matchedState.nameFr : matchedState.nameEn;
    }
    return "";
  }

  getStatusColor(statusId: number): string {
    const status = this.state.find((s) => s.id === statusId);
    return status ? status.color : "grey";
  }

  cancelOperation() {
    this.showAddOperation = false;
    this.editingIndex = null;
    this.isDetailsView = false;
    this.operationHasDate = false;
    this.newOperationForm.reset();
    this.newOperationForm.patchValue({
      maintenanceTypeId: this.currentMaintenanceType
  });
  }

  saveOperation() {
    if (this.newOperationForm.valid) {
      let operationData: any;
      const formValue = this.newOperationForm.value; // Get values including disabled fields
      console.log(this.currentOperation)
      console.log(formValue);
      if (this.isDetailsView) {
        // In details view, combine original data with only the updated date/immobilization fields
        operationData = {
          id: this.editingIndex !== null ? this.currentOperation.id : null,
          workRequestId: this.data?.id || null,
          natureInterventionId: this.currentOperation.interventionNatureId,
          nameNatureIntervention: this.getNatureName(
            this.currentOperation.interventionNatureId
          ),
          typeInterventionId: this.currentOperation.interventionTypeId,
          nameTypeIntervention: this.getTypeName(this.currentOperation.interventionTypeId),
          typeMaintenanceId: this.currentOperation.maintenanceTypeId,
          nameTypeMaintenance: this.getMaintenanceTypeName(
            this.currentOperation.maintenanceTypeId
          ),
          description: this.currentOperation.operationDescription,
          datePrevuWorkOrder: formValue.workOrderDate
            ? new Date(formValue.workOrderDate)
            : null,
          isImmobilized: formValue.isImmobilized,
          immobilizationReason: formValue.isImmobilized
            ? formValue.immobilizationReason
            : null,
          userId: this.connectedUserName,
        };
      } else {
        // Regular add/edit operation
        operationData = {
          id: this.editingIndex !== null ? this.operationsArray.at(this.editingIndex).value.id : null,
          workRequestId: this.data?.id || null,
          natureInterventionId: formValue.interventionNatureId,
          nameNatureIntervention: this.getNatureName(
            formValue.interventionNatureId
          ),
          typeInterventionId: formValue.interventionTypeId,
          nameTypeIntervention: this.getTypeName(formValue.interventionTypeId),
          typeMaintenanceId: formValue.maintenanceTypeId,
          nameTypeMaintenance: this.getMaintenanceTypeName(
            formValue.maintenanceTypeId
          ),
          description: formValue.operationDescription,
          datePrevuWorkOrder: null, // These fields not available in add/edit
          isImmobilized: false,
          immobilizationReason: null,
          status: 1,
          userId: this.connectedUserName,
          workOrderId:
            this.editingIndex !== null
              ? this.currentOperation?.workOrderId
              : null,
        };
      }

      // Determine if it's an update or create
      const apiCall = operationData.id
        ? this.workRequestService.updateOperation(
            operationData.id,
            operationData
          )
        : this.workRequestService.createOperation(operationData);

      apiCall.subscribe({
        next: (result) => {
          this.notValidOperation = false;
          const updatedOperationFormGroup = this.formBuilder.group({
            ...formValue,
            id: result.id,
            workOrderId: result.workOrderId,
            interventionNatureId: result.natureInterventionId,
            nameInterventionNature: result.nameNatureIntervention,
            interventionTypeId: result.typeInterventionId,
            nameTypeIntervention: result.nameTypeIntervention,
            typeMaintenanceId: result.typeMaintenanceId,
            nameTypeMaintenance: result.nameTypeMaintenance,
            status: result.status,
            userId: result.userId,
            workOrderNumber: result.workOrderNumber,
          });

          if (this.editingIndex !== null) {
            // Update existing operation
            this.operationsArray
              .at(this.editingIndex)
              .patchValue(updatedOperationFormGroup.value);
            this.operationCreated.emit();
          } else {
            // Add new operation
            this.operationsArray.push(updatedOperationFormGroup);
            this.operationCreated.emit();
          }

          // Reset form and UI
          this.newOperationForm.reset();
          this.newOperationForm.patchValue({
            maintenanceTypeId: result.typeMaintenanceId,
          });
          this.showAddOperation = false;
          this.editingIndex = null;
          this.isDetailsView = false;
          this.currentOperation = null;

          const successMessage =
            this.langue === "fr"
              ? "Opération créée avec succès "
              : "Operation Created Successfully";
          this.notyService.displayNotification(successMessage, "success");
        },
        error: (error) => {
          const errorMessage =
            this.langue === "fr"
              ? "Échec de créer operation"
              : "Failed to create operation";
          this.notyService.displayNotification(errorMessage, "error");
          console.error("Operation save error", error);
        },
      });
    } else {
      this.notValidOperation = true;
    }
  }

  getOperationFormTitle(): string {
    if (this.isDetailsView) {
      return "OPERATION_DETAILS";
    }
    return this.editingIndex !== null ? "EDIT_OPERATION" : "NEW_OPERATION";
  }

  getMaintenanceTypeName(id: number): string {
    const maintenanceType = this.maintenanceTypes.find((mt) => mt.id === id);
    return maintenanceType ? maintenanceType.name : "";
  }
  removeOperation(index: number) {
    const operationToRemove = this.operationsArray.at(index);
    const operationId = operationToRemove.value.id;

    // Check if the operation has an ID (previously saved)
    if (operationId) {
      // Show confirmation dialog
      const confirmDelete = confirm(
        this.langue === "fr"
          ? "Êtes-vous sûr de vouloir supprimer cette opération ?"
          : "Are you sure you want to delete this operation?"
      );

      if (confirmDelete) {
        // Call API to delete operation
        this.workRequestService.deleteOperation(operationId).subscribe({
          next: () => {
            // Remove from form array
            this.operationsArray.removeAt(index);

            // Show success notification
            const successMessage =
              this.langue === "fr"
                ? "Opération supprimée avec succès"
                : "Operation deleted successfully";

            this.notyService.displayNotification(successMessage, "success");
          },
          error: (error) => {
            // Handle deletion error
            const errorMessage =
              this.langue === "fr"
                ? "Échec de la suppression de l'opération"
                : "Failed to delete operation";

            this.notyService.displayNotification(errorMessage, "error");
            console.error("Operation deletion error", error);
          },
        });
      }
    } else {
      // If operation hasn't been saved to backend, just remove from form
      this.operationsArray.removeAt(index);
    }
  }

  getNatureName(id: number): string {
    const operation = this.interventionNatures.find((op) => op.id === id);
    return operation ? operation.description : "";
  }
  getTypeName(id: number): string {
    const operation = this.interventionTypes.find((op) => op.id === id);
    return operation ? operation.name : "";
  }

  getWorkOrderNumber(id: number): string {
    const workOrder = this.workOrders.find((wo) => wo.idWO === id);
    return workOrder
      ? `${workOrder.workOrderNumber} - ${workOrder.workOrderDate}`
      : "";
  }

  loadDataIntoForm(): void {
    const formatDate = (datetime: string | Date | null | undefined): string => {
      if (!datetime) return "";
      try {
        const date = new Date(datetime);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
          console.warn("Invalid date:", datetime);
          return "";
        }

        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() - userTimezoneOffset)
          .toISOString()
          .split("T")[0];
      } catch (error) {
        console.error("Error formatting date:", error);
        return "";
      }
    };

    const formatDatetime = (datetime: string | null | undefined): string => {
      if (!datetime) return "";

      try {
        // Use Date constructor directly, which is more flexible
        const date = new Date(datetime);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
          console.warn("Invalid datetime:", datetime);
          return "";
        }

        return date.toISOString().slice(0, 16);
      } catch (error) {
        console.error("Error formatting datetime:", error);
        return "";
      }
    };

    const formattedRequestDate = formatDatetime(this.data.requestDate);

    // First, fetch the vehicle details
    this.crudService
      .getOne<any>(
        BASE_API_FLEET + "/vehicles",
        this.data.vehiclesId.toString()
      )
      .subscribe((vehicleData) => {
        this.vehicleDetails = vehicleData;
        this.enPanne = this.vehicleDetails.status === 3;

        // Set the vehicle type based on the vehicle's model
        const vehicleTypeId = this.vehicleDetails.model.id;

        // Populate the form with initial data
        this.formGroup.patchValue({
          requestDate: formattedRequestDate,
          requestType: this.data.requestType,
          renewalDate: this.data.dateExecution,
          description: this.data.description,
          urgency: this.data.urgency,
          applicant: this.data.applicant,
          vehicleTypeId: vehicleTypeId,
          state: this.data.state,
        });

        // After a short delay to ensure vehicles are fetched, set the specific vehicle
        this.formGroup.patchValue({
          vehiclesId: this.data.vehiclesId,
        });
      });

    // Handle file upload form array
    const filesArray = this.formGroup.get("files") as FormArray;
    filesArray.clear();
    this.data.files.forEach((file) => {
      filesArray.push(this.formBuilder.control(file));
    });

    // Disable the form if state > 1, but keep appointment field enabled
    if (this.data.state >= 1 && this.type === "details") {
      this.formGroup.disable();
      this.formGroup.get("files").disable();
    }

    if (this.data.state >= 1 && this.data.state < 5) {
      this.formGroup.get("renewalDate").enable();
    }
    if (this.data.state >= 5) {
      this.formGroup.get("renewalDate").disable();
    }

    if (this.data && this.data.id) {
      this.workRequestService
        .getOperationsByWorkRequest(this.data.id)
        .subscribe({
          next: (operations) => {
            // Clear existing operations
            this.operationsArray.clear();

            // Populate operations
            if (operations && operations.length > 0) {
              operations.forEach((operation) => {
                this.operationsArray.push(
                  this.formBuilder.group({
                    id: operation.id,
                    interventionNatureId: operation.natureInterventionId,
                    nameInterventionNature: operation.nameNatureIntervention,
                    interventionTypeId: operation.typeInterventionId,
                    nameTypeIntervention: operation.nameTypeIntervention,
                    maintenanceTypeId: operation.typeMaintenanceId,
                    nameTypeMaintenance: operation.nameTypeMaintenance,
                    operationDescription: operation.description,
                    isImmobilized: operation.isImmobilized,
                    immobilizationReason: operation.immobilizationReason,
                    workOrderDate: operation.datePrevuWorkOrder
                      ? new Date(operation.datePrevuWorkOrder)
                          .toISOString()
                          .substring(0, 16)
                      : null,
                    workOrderId: operation.workOrderId,
                    workOrderNumber: operation.workOrderNumber, // Add this if your API returns it
                    status: operation.status,
                    userId: operation.userId,
                  })
                );
              });
            }
          },
          error: (error) => {
            console.error("Error fetching operations:", error);
            const errorMessage =
              this.langue === "fr"
                ? "Échec de récupération des opérations"
                : "Failed to fetch operations";
            this.notyService.displayNotification(errorMessage, "error");
          },
        });
    }
  }

  isImageFile(file: any): boolean {
    return file.type.startsWith("image/");
  }

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    const filesArray = this.formGroup.get("files") as FormArray;

    // Reset error messages
    this.isError = false;
    this.errorMessage = "";

    // Check total number of files
    if (filesArray.length + files.length > MAX_IMAGE_UPLOAD_LIMIT) {
      this.isError = true;
      this.errorMessage =
        this.langue === "fr"
          ? `Vous ne pouvez télécharger que ${MAX_IMAGE_UPLOAD_LIMIT} images maximum.`
          : `You can upload a maximum of ${MAX_IMAGE_UPLOAD_LIMIT} images.`;
      return;
    }

    // Calculate current total image size
    let currentTotalSize = this.calculateCurrentTotalImageSize(filesArray);
    let newFilesToAdd: string[] = [];

    // First, validate all files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check individual file size
      const fileSizeMB = file.size / 1024 / 1024;
      if (fileSizeMB > MAX_SINGLE_IMAGE_SIZE_MB) {
        this.isError = true;
        this.errorMessage =
          this.langue === "fr"
            ? `La taille d'un image ne doit pas dépasser ${MAX_SINGLE_IMAGE_SIZE_MB} Mo.`
            : `Individual image size should not exceed ${MAX_SINGLE_IMAGE_SIZE_MB} MB.`;
        return;
      }

      // Check total image size including new files
      if (currentTotalSize + fileSizeMB > MAX_TOTAL_IMAGE_SIZE_MB) {
        this.isError = true;
        this.errorMessage =
          this.langue === "fr"
            ? `La taille totale des images ne doit pas dépasser ${MAX_TOTAL_IMAGE_SIZE_MB} Mo.`
            : `Total image size should not exceed ${MAX_TOTAL_IMAGE_SIZE_MB} MB.`;
        return;
      }

      if (!this.isImageFile(file)) {
        this.isError = true;
        this.errorMessage =
          this.langue === "fr"
            ? "Veuillez sélectionner uniquement des images."
            : "Please select only images.";
        return;
      }

      // Prepare to add file
      const reader = new FileReader();
      reader.onload = (e: any) => {
        newFilesToAdd.push(e.target.result);

        // Only add files when all have been processed
        if (newFilesToAdd.length === files.length) {
          newFilesToAdd.forEach((fileData) => {
            filesArray.push(this.formBuilder.control(fileData));
          });
        }
      };
      reader.readAsDataURL(file);

      // Update current total size for subsequent checks
      currentTotalSize += fileSizeMB;
    }
  }

  // Helper method to calculate current total image size
  calculateCurrentTotalImageSize(filesArray: FormArray): number {
    return filesArray.controls
      .map((control) => {
        // If it's a base64 string, calculate size
        if (
          typeof control.value === "string" &&
          control.value.startsWith("data:image")
        ) {
          // Remove the data URL prefix to get the base64 string
          const base64String = control.value.split(",")[1];
          const sizeInBytes = (base64String.length * 3) / 4;
          return sizeInBytes / 1024 / 1024; // Convert to MB
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);
  }

  validateWorkRequest(): void {
    this.workRequestService.changeState(this.data.id, 2).subscribe(
      (response) => {
        const successMessage =
          this.langue === "fr"
            ? "Demande de travail validée"
            : "Work request validated";
        this.notyService.displayNotification(successMessage, "success");
        this.formGroup.disable(); // Disable all form inputs
        this.formGroup.get("state").setValue(2); // Update the state to 'Validated'
        this.data.state = 2;
        this.showOperationsTable = true;
        this.requestValidated.emit();
      },
      (error) => {
        const errorMessage =
          this.langue === "fr"
            ? "Échec de la validation de la demande de travail"
            : "Failed to validate work request";
        this.notyService.displayNotification(errorMessage, "error");
      }
    );
  }

  rejectWorkRequest(msg: string): void {
    // Prepare the object with only the fields to be updated
    const updatePayload = {
      ...this.data,
      state: 3,
      rejectionReason: msg,
    };

    this.crudService
      .update(
        M_BASE_API + "/workRequest/update",
        this.data.id.toString(),
        updatePayload
      )
      .subscribe(
        (response) => {
          this.activeModal.close();
          this.itemRejected.emit();
          const successMessage =
            this.langue === "fr"
              ? "Demande de travail rejetée"
              : "Work request rejected";
          this.notyService.displayNotification(successMessage, "success");
        },
        (error) => {
          const errorMessage =
            this.langue === "fr"
              ? "Échec du rejet de la demande de travail"
              : "Failed to reject work request";
          this.notyService.displayNotification(errorMessage, "error");
        }
      );
  }

  ConfirmText() {
    const translations = {
      en: {
        title: "Are you sure you want to reject this work request?",
        text: "You won't be able to revert this!",
        confirmButtonText: "Reject ",
        successTitle: "Rejected!",
        successText: "Your work request has been rejected successfully",
        placeholder: "Enter rejection reason",
      },
      fr: {
        title: "Êtes-vous sûr de vouloir rejter cette demande de travail ?",
        text: "Vous ne pourrez pas annuler cette action !",
        confirmButtonText: "Rejeter ",
        successTitle: "Annulé !",
        successText: "Votre demande de travail a été rejetée avec succès",
        placeholder: "Saisissez la raison de rejet",
      },
    };

    // Assuming you have a variable to track the language (e.g., 'fr' or 'en')
    const language = "fr"; // Change this dynamically based on the user's choice

    swal
      .fire({
        title: translations[language].title,
        text: translations[language].text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2F8BE6",
        cancelButtonColor: "#F55252",
        confirmButtonText: translations[language].confirmButtonText,
        cancelButtonText: language === "fr" ? "Annuler" : "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
        html: `
          <input type="text" id="input-field" class="swal2-input" placeholder="${translations[language].placeholder}">
        `,
        didOpen: () => {
          const inputField = document.getElementById(
            "input-field"
          ) as HTMLInputElement;
          const confirmButton = swal.getConfirmButton();
          confirmButton.disabled = true;

          inputField.addEventListener("input", () => {
            confirmButton.disabled = !inputField.value.trim();
          });
        },
        preConfirm: () => {
          const inputValue = (
            document.getElementById("input-field") as HTMLInputElement
          ).value;
          return { inputValue };
        },
      })
      .then((result) => {
        if (result.value) {
          const inputValue = result.value.inputValue;
          this.rejectWorkRequest(inputValue);
        }
      });
  }

  removeFile(index: number) {
    const filesArray = this.formGroup.get("files") as FormArray;
    if (index > -1 && index < filesArray.length) {
      filesArray.removeAt(index);
    }
  }

  openImageModal(imageSrc: string) {
    const modalRef = this.modalService.open(ImageModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.imageSrc = imageSrc;
  }

  openMap() {
    const modalRef = this.modalService.open(VehiclePositionComponent, {
      size: "lg",
      centered: true,
    });
    // Only pass the vehicle when one is selected
    if (this.formGroup.get("vehiclesId").value) {
      const selectedVehicleId = this.formGroup.get("vehiclesId").value;
      const selectedVehicle = this.vehicles.find(
        (v) => v.id === selectedVehicleId
      );

      if (selectedVehicle) {
        modalRef.componentInstance.vehicle = selectedVehicle;
      }
      const requestDate = this.formGroup.get("requestDate").value;
      modalRef.componentInstance.dateDepart =
        this.calculateDateRange(requestDate).startDate;
      modalRef.componentInstance.dateArrivee =
        this.calculateDateRange(requestDate).endDate;
      modalRef.componentInstance.requestDate = requestDate;
    }
  }
  calculateDateRange(requestDate: any) {
    if (requestDate) {
      // Convert request date to Date object
      const requestDateTime = new Date(requestDate);

      // Calculate start date (request date - 1 hour)
      const startDateTime = new Date(
        requestDateTime.getTime() - 60 * 60 * 1000
      );
      return {
        startDate: startDateTime.toISOString(),
        endDate: requestDateTime.toISOString(),
      };
    }
  }

  private fetchInterventionCategories(): void {
    // Fetch all intervention categories for the select dropdown
    this.crudService
      .getAll<any>(M_BASE_API + "/interventionCategory")
      .subscribe(
        (categories) => {
          this.interventionCategories = categories.data;
        },
        (error) => {
          console.error("Error fetching intervention categories:", error);
        }
      );
  }

  private getSettings(): void {
    this.crudService
      .getAll<any>(OPN_BASE_URL + "/company-settings/all")
      .subscribe(
        (res: any) => {
          const settingsMap = res.map((item: any) => ({
            configKey: item.configKey,
            configValue: this.parseConfigValue(item.configValue),
          }));

          const constraintConfigSetting = settingsMap.find(
            (item) => item.configKey === "maintenance-config"
          );

          this.constraintConfig = constraintConfigSetting?.configValue || null;

          // If we're updating from navbar (scheduled task), set the category
          if (
            this.type !== "update" &&
            this.data?.requestType === "Maintenance"
          ) {
            this.setPreventiveMaintenanceCategory();
          }
          const initialRequestType = this.formGroup.get("requestType").value;
          this.updateInterventionCategory(initialRequestType);
        },
        (error) => {
          console.error("Error fetching settings:", error);
          this.showCategorySelect = true;
        }
      );
  }

  private parseConfigValue(value: string): any {
    try {
      return JSON.parse(value);
    } catch (e) {
      console.error("Error parsing config value:", e);
      return null;
    }
  }

  private updateInterventionCategory(requestType: string): void {
    if (!this.constraintConfig) {
      this.showCategorySelect = true;
      return;
    }

    const categoryId = this.getCategoryIdFromConfig(requestType);
    if (categoryId) {
      this.formGroup.patchValue({ interventionCategoryId: categoryId });
      this.showCategorySelect = false;
    } else {
      this.showCategorySelect = true;
    }
  }

  private getCategoryIdFromConfig(requestType: string): number | null {
    if (!this.constraintConfig) return null;

    switch (requestType) {
      case "Repair":
        return this.constraintConfig.maintenance_curative?.id || null;
      case "Maintenance":
        return this.constraintConfig.maintenance_preventive?.id || null;
      default:
        return null;
    }
  }

  private setPreventiveMaintenanceCategory(): void {
    if (this.constraintConfig?.maintenance_preventive?.id) {
      this.formGroup.patchValue({
        interventionCategoryId: this.constraintConfig.maintenance_preventive.id,
      });
      this.showCategorySelect = false;
    } else {
      this.showCategorySelect = true;
    }
  }

  submitForm() {
    if (this.formGroup.valid) {
      let url = M_BASE_API + "/workRequest";
      const formData = this.formGroup.value;
      // Ensure intervention category is set
      if (!formData.interventionCategoryId && this.showCategorySelect) {
        this.notValid = true;
        const errorMessage =
          this.langue === "fr"
            ? "Veuillez sélectionner une catégorie d'intervention"
            : "Please select an intervention category";
        this.notyService.displayNotification(errorMessage, "error");
        return;
      }

      if (this.type === "update" || this.type === "details") {
        if (this.data.requestType === "Renouvellement") {
          const username = this.authService.getUsernameFromToken();
          const scheduledTaskId = this.data.scheduledTaskId;

          if (scheduledTaskId) {
            const scheduledTaskUrl = M_BASE_API + "/scheduled-tasks";
            // Fetch the scheduled task by ID
            this.crudService
              .getOne<any>(scheduledTaskUrl, scheduledTaskId)
              .subscribe(
                (scheduledTask) => {
                  const renewalData = {
                    renewalDate: formData.renewalDate,
                    vehicleId: this.data.vehiclesId,
                    renewalType: scheduledTask.renewalType,
                    userId: username,
                  };
                  // Process renewal work request
                  this.workRequestService
                    .processRenewalWorkRequest(this.data.id, renewalData)
                    .subscribe(
                      (response) => {
                        this.activeModal.close("ok");
                        this.notValid = false;
                        const successMessage =
                          this.langue === "fr"
                            ? "Demande de renouvellement traitée avec succès"
                            : "Renewal request processed successfully";
                        this.notyService.displayNotification(
                          successMessage,
                          "success"
                        );
                      },
                      (error) => {
                        console.error("Error processing renewal:", error);
                        const errorMessage =
                          this.langue === "fr"
                            ? "Échec du traitement du renouvellement"
                            : "Failed to process renewal";
                        this.notyService.displayNotification(
                          errorMessage,
                          "error"
                        );
                      }
                    );
                },
                (error) => {
                  console.error("Error fetching scheduled task:", error);
                  const errorMessage =
                    this.langue === "fr"
                      ? "Échec de récupération de la tâche planifiée"
                      : "Failed to fetch scheduled task";
                  this.notyService.displayNotification(errorMessage, "error");
                }
              );
          } else {
            console.error("Scheduled task ID is missing.");
            const errorMessage =
              this.langue === "fr"
                ? "ID de la tâche planifiée manquant"
                : "Scheduled task ID is missing";
            this.notyService.displayNotification(errorMessage, "error");
          }
        } else {
          url += "/update";
          this.crudService
            .update(url, this.data.id.toString(), formData)
            .subscribe(
              (response) => {
                this.activeModal.close("ok");
                this.notValid = false;
                const successMessage =
                  this.langue === "fr"
                    ? "Demande de travail mise à jour avec succès"
                    : "Work request updated successfully";
                this.notyService.displayNotification(successMessage, "success");
              },
              (error) => {
                console.error("Error updating data:", error);
                const errorMessage =
                  this.langue === "fr"
                    ? "Échec de la mise à jour"
                    : "Failed Update";
                this.notyService.displayNotification(errorMessage, "error");
              }
            );
        }
      } else {
        url += "/add";
        this.crudService.post(url, formData).subscribe(
          (response) => {
            this.activeModal.close("ok");
            this.notValid = false;
            const successMessage =
              this.langue === "fr"
                ? "Demande de travail créée avec succès"
                : "Work request created successfully";
            this.notyService.displayNotification(successMessage, "success");
          },
          (error) => {
            console.error("Error posting data:", error);
            const errorMessage =
              this.langue === "fr" ? "Échec de l'ajout" : "Failed add";
            this.notyService.displayNotification(errorMessage, "error");
          }
        );
      }
    } else {
      this.notValid = true;
    }
  }

  fetchVehicleTypes() {
    const url = BASE_API_FLEET + "/vehicle-models";
    this.crudService.getAll(url).subscribe(
      (data: any) => {
        this.vehicleType = data.data.map((item) => ({
          id: item.id,
          name: `${item.vehicleBrand.brandName}-${item.name}`,
        }));
      },
      (error) => {
        console.error("Error fetching vehicle types:", error);
      }
    );
  }

  onVehicleTypeChange(selectedVehicleType: any): void {
    if (selectedVehicleType) {
      this.filtredVehicles = this.vehicles.filter(
        (v) => selectedVehicleType.id == v.type
      );
    } else {
      // Reset to all vehicles when type is cleared
      this.filtredVehicles = [...this.vehicles];
      this.formGroup.get("vehiclesId").setValue(null);
    }
  }

  onVehicleChange(selectedVehicle: any): void {
    if (selectedVehicle) {
      this.formGroup.get("vehicleTypeId").setValue(selectedVehicle.type, {
        emitEvent: false,
      });
    } else {
      // Reset vehicle type when vehicle is cleared
      this.formGroup.get("vehicleTypeId").setValue(null, {
        emitEvent: false,
      });
      this.filtredVehicles = [...this.vehicles];
    }
  }
  onRenewalChange() {
    // When the "Renouvellement" radio button is selected, we show the renewal date and hide operation/images sections.
    if (this.formGroup.get("requestType").value === "Renouvellement") {
      this.formGroup.get("renewalDate").setValidators([Validators.required]); // Make sure the renewal date is required
    } else {
      this.formGroup.get("renewalDate").clearValidators();
    }
  }

  fetchVehicles(username: string): void {
    const url = `${BASE_API_FLEET}/vehicles?username=${encodeURIComponent(
      username
    )}`;

    // Call the service to fetch vehicles based on the selected vehicle type
    this.crudService.getAll(url).subscribe(
      (data: any) => {
        this.vehicles = data.data.map((item) => ({
          id: item.id,
          name:
            item.vehicleNumber +
            " - " +
            item.model.vehicleBrand.brandName +
            " | " +
            item.model.name +
            " | " +
            item.registration,
          type: item.modelId,
          idBoitier: item.idBoitier,
        }));
        this.filtredVehicles = [...this.vehicles];
      },
      (error) => {
        console.error("Error fetching vehicles:", error);
      }
    );
  }

  navigateToWorkOrder(workOrderNumber: string): void {
    // Close the modal
    this.activeModal.close();

    // Navigate to the work order details page
    this.router.navigate([
      "/maintenance-management/work-order/form",
      workOrderNumber,
    ]);
  }
}
