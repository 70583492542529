// pagination.component.ts
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() collectionSize: number = 0;
  @Input() pageSize: number = 10;
  @Input() currentPage: number = 1;
  @Input() showDynamicOptions: boolean = true;
  @Input() class = 'mr-4'

  @Output() currentPageChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();

  onPageChange(newPage: number) {
    this.currentPageChange.emit(newPage);
  }

  onPageSizeChange(newSize: number) {
    this.pageSizeChange.emit(newSize);
  }
}
