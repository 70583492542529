<div class="modal-header">
  <h4 class="modal-title">{{"Grouped_Assignment"|translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
    <i class="fa-solid fa-xmark"></i>
  </button>
</div>
<div class="modal-content ">

  <form [formGroup]="groupForm" appRefresh>

    <div class="p-2 m-2">
      <div class="card-content">

        <!-- page instances to be assigned -->
        <ng-container *ngIf="viewState === 'form'">

          <div *ngFor="let group of groupedInstances; let i = index">

            <div (click)="toggleCollapse(i)">
              <span class="d-flex align-items-center text-dark font-weight-bold py-1 cursor-pointer">
                <i class="fa"
                  [ngClass]="{'fa-chevron-down': !group.isCollapsed, 'fa-chevron-right': group.isCollapsed}"></i>
                <span class="ml-2">{{ getDayOfWeek(group.date) }} {{ group.date }}</span>
                <span class="font-weight-lighter ml-1 ">({{group.instances.length }} {{ 'TRIP' | translate }})</span>
              </span>
            </div>

            <div [ngbCollapse]="group.isCollapsed">
              <div class="card-body">

                <ng-template #notAssigned>{{"NOT" | translate}} <b>{{"ASSIGNED" | translate}}</b>!</ng-template>

                <div class="table-responsive border">
                  <table class="table mb-0">
                    <thead>
                      <tr class="bg-grey bg-lighten-4 rounded p-2 mb-2">
                        <th># {{ 'LINE' | translate }}</th>
                        <th>{{ 'TRIP' | translate }}</th>
                        <th>{{ 'DEPARTURE' | translate }}</th>
                        <th>{{ 'DRIVER-NUM' | translate }}</th>
                        <th>{{ 'BUS-NUM' | translate }}</th>
                        <th>{{ 'RECEIVER-NUM' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="" *ngFor="let trip of group.instances">
                        <!-- route number -->
                        <td>
                          <span class="badge badge-dark rounded-pill font-weight-bold px-3">
                            {{ trip.lineNumber}}
                          </span>
                        </td>
                        <!-- trip number -->
                        <td>
                          <span class="badge badge-secondary rounded-pill">
                            <i class="fa-solid fa-bus"></i><span class="font-weight-bold hovering px-1">{{
                              trip.lineDirection }}
                            </span>
                          </span>
                        </td>
                        <!-- trip departure -->
                        <td>
                          {{ trip.plannedDeparture | date: 'dd MMM yyyy' }} <span class="font-weight-bold">{{
                            trip.plannedDeparture | date: 'HH:MM' }} </span>
                        </td>
                        <!-- Driver -->
                        <td>
                          <!-- already assigned -->
                          <div class="align-content-center" *ngIf="trip.driver; else noDriver">
                            <div class="d-flex flex-column align-items-center">
                              <span class="text-nowrap font-weight-bold">#{{ trip.driver?.matricule }}</span>
                              <span class="text-center">{{trip.driver.firstName}} {{trip.driver.lastName}} </span>
                              <button *ngIf="!trip.removedDriver" class="btn btn-sm bg-light-danger round text-nowrap"
                                (click)="removeDriver(trip)">
                                <i class="fa fa-trash mr-2"></i>{{"unassign" | translate}}
                              </button>
                            </div>
                          </div>
                          <!-- not assigned or assignation removed -->
                          <ng-template #noDriver>
                            <div class="text-center align-content-center">
                              <span class="text-danger" [ngbTooltip]="notAssigned" *ngIf="!trip.removedDriver">
                                <i class="fa-solid fa-slash"></i>
                              </span>
                              <!-- assignation removed -->
                              <button *ngIf="trip.removedDriver" class="btn btn-sm bg-light-success round text-nowrap"
                                (click)="undoRemoveDriver(trip)">
                                <i class="fa fa-undo mr-2"></i>{{"reassign" | translate}}
                              </button>
                            </div>
                          </ng-template>
                        </td>
                        <!-- Bus -->
                        <td>
                          <!-- already assigned -->
                          <div class="align-content-center" *ngIf="trip.bus; else noBus">
                            <div class="d-flex flex-column align-items-center">
                              <span class="text-nowrap font-weight-bold">#{{ trip.bus.vehicleNumber }}</span>
                              <!-- <span>{{trip.driver.firstName}} {{trip.driver.lastName}} </span>                             -->
                              <button *ngIf="!trip.removedBus" class="btn btn-sm bg-light-danger round text-nowrap mt-1"
                                (click)="removeBus(trip)">
                                <i class="fa fa-trash mr-2"></i>{{"unassign" | translate}}
                              </button>
                            </div>
                          </div>
                          <!-- not assigned or assignation removed -->
                          <ng-template #noBus>
                            <div class="text-center align-content-center">
                              <span class="text-danger" [ngbTooltip]="notAssigned" *ngIf="!trip.removedBus">
                                <i class="fa-solid fa-slash"></i>
                              </span>
                              <!-- assignation removed -->
                              <button *ngIf="trip.removedBus" class="btn btn-sm bg-light-success round text-nowrap"
                                (click)="undoRemoveBus(trip)">
                                <i class="fa fa-undo mr-2"></i>{{"reassign" | translate}}
                              </button>
                            </div>
                          </ng-template>
                        </td>
                        <!-- Receiver -->
                        <td>
                          <!-- already assigned -->
                          <div class="align-content-center" *ngIf="trip.receiver; else noReceiver">
                            <div class="d-flex flex-column align-items-center">
                              <span class="text-nowrap font-weight-bold">#{{ trip.receiver.matricule }}</span>
                              <span class="text-center">{{trip.receiver.firstName}} {{trip.receiver.lastName}} </span>
                              <button *ngIf="!trip.removedReceiver"
                                class="btn btn-sm bg-light-danger flex-grow-1 round text-nowrap"
                                (click)="removeReceiver(trip)">
                                <i class="fa fa-trash mr-2"></i>{{"unassign" | translate}}
                              </button>
                            </div>
                          </div>
                          <!-- not assigned or assignation removed -->
                          <ng-template #noReceiver>
                            <div class="text-center align-content-center">
                              <span class="text-danger" [ngbTooltip]="notAssigned" *ngIf="!trip.removedReceiver">
                                <i class="fa-solid fa-slash"></i>
                              </span>
                              <!-- assignation removed -->
                              <button *ngIf="trip.removedReceiver"
                                class="btn btn-sm bg-light-success flex-grow-1 round text-nowrap"
                                (click)="undoRemoveReceiver(trip)">
                                <i class="fa fa-undo mr-2"></i>{{"reassign" | translate}}
                              </button>
                            </div>
                          </ng-template>
                        </td>
                      </tr>
                  </table>
                </div>

              </div>
            </div>

          </div>

        </ng-container>



        <!-- page assignment -->
        <ng-container *ngIf="viewState === 'assignment'">
          <div *ngIf="isLoading" class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">{{"LOADING" | translate}}...</span>
            </div>
          </div>

          <div class="bg-light-secondary mb-2 p-2 rounded" *ngIf="!isLoading">
            <div *ngFor="let group of groupedInstances; let i = index">

              <div (click)="toggleCollapse(i)">
                <span
                  class="d-flex align-items-center text-dark font-weight-bold p-2 bg-light-secondary round cursor-pointer">
                  <i class="fa"
                    [ngClass]="{'fa-chevron-down': !group.isCollapsed, 'fa-chevron-right': group.isCollapsed}"></i>
                  <span class="ml-2">{{ getDayOfWeek(group.date) }} {{ group.date }}</span>
                  <span class="font-weight-lighter ml-1 ">({{group.instances.length }} {{ 'TRIP' | translate }})</span>
                </span>
              </div>

              <div [ngbCollapse]="group.isCollapsed">
                <div class="card-body">
                  <div class="d-flex m-0 flex-wrap">
                    <!-- day trips to be assigned -->
                    <div *ngFor="let trip of group.instances" class="col-md-3 p-1">
                      <div class="d-flex flex-column h-100 justify-content-around mission-item-w rounded p-1 bg-white">
                        <div class="d-flex flex-column justify-content-around m-0 p-1">
                          <div class="d-flex gap-05 mb-1">
                            <span class="badge badge-pill bg-success">
                              {{ trip.lineDirection}}
                            </span>
                            <div class="badge badge-pill bg-light-secondary flex-grow-1">
                              <span class="font-weight-bold">{{ trip.plannedDeparture | date:"HH:mm" }}</span>
                              <i class="fa-solid fa-van-shuttle mx-1"></i>
                              <span class="font-weight-lighter">{{ trip.estimatedArrival | date:"HH:mm"
                                }}</span>
                            </div>
                          </div>
                          <div class="px-1 text-secondary">{{ trip.tripRoute }}</div>
                        </div>
                        <div class="d-flex justify-content-center m-0 rounded-bottom p-1">
                          <div class="d-flex p-1">

                            <!-- BEGIN - Overlapping Trips - tooltips -->
                            <ng-template #popTitle1 let-overlappingMsg="overlappingMsg">
                              {{ overlappingMsg | translate}}
                            </ng-template>

                            <ng-template #popContent1 let-overlappings="overlappings"
                              let-assignationEmpty="assignationEmpty" let-overlappingTrigger="overlappingTrigger">
                              <!-- overlappings trips -->
                              <div class="d-flex flex-column"
                                *ngIf="(overlappings && overlappings.length > 0); else noOverlappingContent">

                                <div *ngIf="overlappingTrigger == 'Driver'" class="d-flex gap-05 flex-column">
                                  <span>
                                    {{"#"+trip.driver?.matricule+" - "+trip.driver?.firstName+ " " +
                                    trip.driver?.lastName}}
                                  </span>
                                  <span class="d-flex font-small-2 gap-05" [class.line-through]="trip.overrideDriver"
                                    *ngFor="let t of overlappings">
                                    <span class="badge badge-pill bg-light-info">
                                      {{ t.lineDirection}}
                                    </span>
                                    <div class="badge badge-pill bg-light-secondary flex-grow-1">
                                      <span class="font-weight-bold">{{ t.plannedDeparture | date:"HH:mm" }}</span>
                                      <i class="fa-solid fa-van-shuttle mx-1"></i>
                                      <span class="font-weight-lighter">{{ t.estimatedArrival | date:"HH:mm"
                                        }}</span>
                                    </div>
                                  </span>
                                </div>

                                <div *ngIf="overlappingTrigger == 'Bus'" class="d-flex gap-05 flex-column">
                                  <span>
                                    {{"#"+trip.bus?.vehicleNumber+" - "+trip.bus?.brand?.name+ " - " +
                                    trip.bus?.model?.name}}
                                  </span>
                                  <span class="d-flex font-small-2 gap-05" [class.line-through]="trip.overrideBus"
                                    *ngFor="let t of overlappings">
                                    <span class="badge badge-pill bg-light-info">
                                      {{ t.lineDirection}}
                                    </span>
                                    <div class="badge badge-pill bg-light-secondary flex-grow-1">
                                      <span class="font-weight-bold">{{ t.plannedDeparture | date:"HH:mm" }}</span>
                                      <i class="fa-solid fa-van-shuttle mx-1"></i>
                                      <span class="font-weight-lighter">{{ t.estimatedArrival | date:"HH:mm"
                                        }}</span>
                                    </div>
                                  </span>
                                </div>

                                <div *ngIf="overlappingTrigger == 'Receiver'" class="d-flex gap-05 flex-column">
                                  <span>
                                    {{"#"+trip.receiver?.matricule+" - "+trip.receiver?.firstName+ " " +
                                    trip.receiver?.lastName}}
                                  </span>
                                  <span class="d-flex font-small-2 gap-05" [class.line-through]="trip.overrideReceiver"
                                    *ngFor="let t of overlappings">
                                    <span class="badge badge-pill bg-light-info">
                                      {{ t.lineDirection}}
                                    </span>
                                    <div class="badge badge-pill bg-light-secondary flex-grow-1">
                                      <span class="font-weight-bold">{{ t.plannedDeparture | date:"HH:mm" }}</span>
                                      <i class="fa-solid fa-van-shuttle mx-1"></i>
                                      <span class="font-weight-lighter">{{ t.estimatedArrival | date:"HH:mm"
                                        }}</span>
                                    </div>
                                  </span>
                                </div>
                              </div>

                              <ng-template #noOverlappingContent>
                                <div class="text-center">
                                  <div *ngIf="!assignationEmpty">
                                    <span *ngIf="overlappingTrigger == 'Driver'">
                                      {{"#"+trip.driver?.matricule+" - "+trip.driver?.firstName+ " " +
                                      trip.driver?.lastName}}
                                    </span>
                                    <span *ngIf="overlappingTrigger == 'Bus'">
                                      {{"#"+trip.bus?.vehicleNumber+" - "+trip.bus?.brand?.name+ " - " +
                                      trip.bus?.model?.name}}
                                    </span>
                                    <span *ngIf="overlappingTrigger == 'Receiver'">
                                      {{"#"+trip.receiver?.matricule+" - "+trip.receiver?.firstName+ " " +
                                      trip.receiver?.lastName}}
                                    </span>
                                  </div>
                                  <span *ngIf="assignationEmpty">
                                    <i class="fa-solid fa-ban font-medium-4 text-grey"></i>
                                  </span>
                                </div>
                              </ng-template>

                            </ng-template>
                            <!-- END - Overlapping Trips - tooltips -->

                            <!-- Driver Badge -->
                            <div class="badge badge-secondary p-1 mr-1 d-flex align-items-center "
                              [ngClass]="getBadgeClass(trip, 'driver')">
                              <!-- pas de conflit -->
                              <span [ngbPopover]="popContent1" class="cursor-pointer" [popoverTitle]="popTitle1"
                                triggers="manual" #pDriver="ngbPopover"
                                (click)="toggleOverlappingTrips(pDriver,'Driver', trip)">
                                <i *ngIf="!trip.driverConflict  && trip.driver" class="fa-solid fa-check"></i>
                                <i *ngIf="trip.driverConflict" class="fa-solid fa-x"></i>
                                <img src="./assets/img/icons/driver.png" class="mx-1 icon-small">
                              </span>
                              <input *ngIf="trip.driverConflict" class="col-auto" type="checkbox" id="export-driver"
                                [checked]="trip.overrideDriver"
                                (change)="toggleOverrideDriver(trip, $event.target.checked)">
                              <input *ngIf="!trip.driverConflict && trip.driverDayOff" class="col-auto" type="checkbox"
                                id="dayOff-driver" [checked]="trip.overrideDriverDayOff"
                                (change)="toggleOverrideDriverDayOff(trip, $event.target.checked)">
                            </div>

                            <!-- Receiver Badge -->
                            <div class="badge badge-secondary p-1 mr-1 d-flex align-items-center "
                              [ngClass]="getBadgeClass(trip, 'receiver')">
                              <span [ngbPopover]="popContent1" class="cursor-pointer" [popoverTitle]="popTitle1"
                                triggers="manual" #pReceiver="ngbPopover"
                                (click)="toggleOverlappingTrips(pReceiver,'Receiver', trip)">
                                <i *ngIf="(!trip.receiverConflict && trip.receiver) || (!trip.receiverConflict && trip.receiver)"
                                  class="fa-solid fa-check"></i>
                                <i *ngIf="trip.receiverConflict" class="fa-solid fa-x"></i>
                                <img src="./assets/img/icons/cashier.png" class="mx-1 icon-small">
                              </span>
                              <input *ngIf="trip.receiverConflict" class="col-auto" type="checkbox" id="export-receiver"
                                [checked]="trip.overrideReceiver"
                                (change)="toggleOverrideReceiver(trip, $event.target.checked)">
                              <input *ngIf="!trip.receiverConflict && trip.receiverDayOff" class="col-auto"
                                type="checkbox" id="dayOff-receiver" [checked]="trip.overrideReceiverDayOff"
                                (change)="toggleOverrideReceiverDayOff(trip, $event.target.checked)">
                            </div>

                            <!-- Bus Badge -->
                            <div class="badge badge-secondary p-1 mr-1 d-flex align-items-center "
                              [ngClass]="getBadgeClass(trip, 'bus')">

                              <span [ngbPopover]="popContent1" class="cursor-pointer" [popoverTitle]="popTitle1"
                                triggers="manual" #pBus="ngbPopover" (click)="toggleOverlappingTrips(pBus,'Bus', trip)">
                                <i *ngIf="!trip.busConflict && trip.bus" class="fa-solid fa-check"></i>
                                <i *ngIf="trip.busConflict" class="fa-solid fa-x"></i>
                                <img src="./assets/img/icons/buss.png" class="mx-1 icon-small">
                              </span>
                              <input *ngIf="trip.busConflict" class="col-auto" type="checkbox" id="export-bus"
                                [checked]="trip.overrideBus" (change)="toggleOverrideBus(trip, $event.target.checked)">

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- BEGIN - assignment choice bloc -->
          <div class="border p-3">
            <div class="row">

              <!-- driver assignment -->
              <div class="col-lg-6 col-sm-12  bg-lighten-4 mb-3">
                <div class="d-flex align-items-center bg-grey bg-lighten-4 rounded p-3">
                  <img src="./assets/img/icons/driver.png" class="mr-4 w-auto">
                  <div class=" w-100 point-list-none" formGroupName="driver">
                    <ng-select id="drivers" formControlName="id" [items]="groupedDrivers" [groupBy]="groupBy"
                      bindLabel="name" bindValue="id" [searchable]="true" placeholder="{{'SELECT_DRIVER'|translate}}"
                      (change)="onDriverChange($event)" [appendTo]="'body'" class="custom-select-placement">
                    </ng-select>
                  </div>
                </div>
              </div>


              <!-- receiver assignment -->
              <div class="col-lg-6 col-sm-12  bg-lighten-4 mb-3">
                <div class="d-flex align-items-center bg-grey bg-lighten-4 rounded p-3">
                  <img src="./assets/img/icons/cashier.png" class="mr-4 w-auto">

                  <div class="w-100 point-list-none" formGroupName="receiver">

                    <ng-select id="receivers" formControlName="id" [items]="groupedReceivers" [groupBy]="groupBy"
                      bindLabel="name" bindValue="id" [searchable]="true" placeholder="{{'SELECT_RECEIVER'|translate}}"
                      (change)="onReceiverChange($event)" [appendTo]="'body'" class="custom-select-placement">

                    </ng-select>

                  </div>
                </div>

              </div>

              <!-- bus assignment -->
              <div class="col-lg-6 col-sm-12  bg-lighten-4">
                <div class="d-flex align-items-center bg-grey bg-lighten-4 rounded p-3 ">
                  <img src="./assets/img/icons/buss.png" class="mr-4" alt="">
                  <div class="point-list-none w-100" formGroupName="bus">

                    <ng-select [items]="busList" bindLabel="vehicleNumber" bindValue="id" formControlName="id"
                      [placeholder]="'SELECT_BUS' | translate" [searchable]="true" (change)="onBusChange($event)"
                      [appendTo]="'body'" class="custom-select-placement">
                    </ng-select>
                  </div>

                </div>
              </div>
            </div>

          </div>
          <!-- END - assignment choice bloc -->

        </ng-container>
      </div>
    </div>




    <div class="modal-footer row col-md-12 d-flex justify-content-end">
      <button *ngIf="viewState === 'assignment'" type="button" class="btn btn-secondary" (click)="returnToPrevious()">
        {{ 'RETURN' | translate }}
      </button>
      <button *ngIf="viewState === 'assignment'" type="button" class="btn btn-secondary"
        (click)="checkAvailabilityForInstances()">
        {{ 'CHECK-AVAILABILTIES' | translate }}
      </button>

      <button *ngIf="viewState === 'form' " type="button" class="btn btn-primary" (click)="onNextClicked()">
        {{ 'NEXT' | translate }}
      </button>

      <button (click)="saveForm()" *ngIf="viewState === 'assignment'" class="btn btn-primary"
        [disabled]="!isAvailabilityChecked">{{"Save" | translate}}</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">{{"Close" |
        translate}}</button>
    </div>
  </form>
</div>