import { ScheduledTasks } from "./../../scheduled-tasks-management/models/scheduled-tasks";
import {
  Agency,
  Family,
  Fuel,
  Genre,
  Oil,
  Staff,
} from "../../../operating-networks/trips-management/models/trips-instances.model";
import { Model } from "@swimlane/ngx-datatable";

export interface Vehicle {
  bus: boolean;
  id: number;
  idBoitier: number;
  parkingNumber: string;
  vehicleNumber: string;
  registration: string;
  capacity: number;
  circulationDate: string;
  status: number;
  genreId: number;
  genre: Genre;
  modelId: number;
  model: Model;
  familyId: number;
  family: Family;
  agencyId: number;
  agency: Agency;
  tankCapacity: number;
  defaultDriverId: number | null;
  oilStandardId: number;
  oil: Oil;
  fuelStandardId: number;
  fuel: Fuel;
  location: string;
}

export interface WorkOrder {
  id: number;
  description: string;
  status: number;
  interventionObject: string;
  operationId: number;
  number: number;
  requestor: string;
  observation: string | null;
  defectEliminated: number;
  date: string;
  idVehicle: number;
}

export interface Operation {
  id: number;
  workRequestId: number;
  workOrderId: number;
  workOrderNumber: string;
  natureInterventionId: number;
  nameNatureIntervention: string;  
  typeInterventionId: number;
  nameTypeIntervention: string;  
  typeMaintenanceId: number;
  nameTypeMaintenance: string;
  description: string;
  isImmobilized: any;
  immobilizationReason: any;
  status: number;
  datePrevuWorkOrder: string;
  userId: number;
}

export interface WorkRequest {
  id: number;
  applicantId: number;
  applicant?: string;
  vehicleTypeId: number;
  vehiclesId: number;
  vehicles?: Vehicle;
  requestDate: string;
  requestType: string;
  datePrevu: string;
  dateExecution: string;
  description: string;
  files: string[];
  state: number;
  interventionCategory: any;
  interventionCategoryId: number;
  urgency: number;
  enPanne?: boolean;
  operations?: Operation[];
  workOrders?: WorkOrder[];
  appointment?: any;
  scheduledTaskId?: any;
  rejectionReason?: any;
  createdAt: string;
  updatedAt: string;
  scheduledTask: any;
}
