<div class="card">
  <div class="card-body">
    <h5 class="card-title" *ngIf="!id" [appHasPermission]="['add station']">{{ 'CREATE_STATION' | translate }}</h5>
    <h5 class="card-title" *ngIf="id" [appHasPermission]="['update station']">{{ 'UPDATE_STATION' | translate }}</h5>
    <form [formGroup]="stationForm" (ngSubmit)="onSubmit()">
      <div class="mb-3">
        <label for="name" class="form-label">{{ 'NAME' | translate }}</label>
        <input type="text" id="name" formControlName="name" class="form-control">
      </div>
 
      <div class="mb-3">
        <label for="code" class="form-label">{{ 'Code' | translate }}</label>
        <input type="text" id="code" formControlName="code" class="form-control">
        <div *ngIf="stationForm.get('code').errors?.['codeExists']" class="error">
          {{ displayCodeError() }}
        </div>
      </div>

      <div class="mb-3">
        <label for="type" class="form-label">{{ 'TYPE' | translate }}</label>
        <select id="stationType" formControlName="stationTypeId" class="form-control">

          <option *ngFor="let type of stationTypes" [value]="type.id">
            {{type.name}}
          </option>
        </select>
      </div>

      <div class="mb-3 d-flex align-items-start">
        <label class="form-label mr-3">{{ 'STATUS' | translate }}</label>
        <div class="form-check form-check-inline">
          <input type="radio" id="active" formControlName="status" [value]="1" class="form-check-input">
          <label for="active" class="form-check-label">{{ 'ACTIVE' | translate }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input type="radio" id="inactive" formControlName="status" [value]="0" class="form-check-input">
          <label for="inactive" class="form-check-label">{{ 'INACTIVE' | translate }}</label>
        </div>
      </div>

      <div class="mb-3" style="height: 400px;">
        <app-map (locationSelected)="onLocationSelected($event)"></app-map>
      </div>

      <div class="row">
        <div class="mb-3 col-6 col-lg-6 col-md-6 col-sm-6">
          <label for="lat" class="form-label">{{ 'LATITUDE' | translate }}</label>
          <input type="number" id="lat" formControlName="lat" class="form-control">
          <div *ngIf="stationForm.controls.lat.errors && stationForm.controls.lat.errors.min" class="error">
            {{ 'LAT_MIN_ERROR' | translate }}
          </div>
          <div *ngIf="stationForm.controls.lat.errors && stationForm.controls.lat.errors.max" class="error">
            {{ 'LAT_MAX_ERROR' | translate }}
          </div>
        </div>

        <div class="mb-3 col-6 col-lg-6 col-md-6 col-sm-6">
          <label for="lon" class="form-label">{{ 'LONGITUDE' | translate }}</label>
          <input type="number" id="lon" formControlName="lon" class="form-control">
          <div *ngIf="stationForm.controls.lon.errors && stationForm.controls.lon.errors.min" class="error">
            {{ 'LON_MIN_ERROR' | translate }}
          </div>
          <div *ngIf="stationForm.controls.lon.errors && stationForm.controls.lon.errors.max" class="error">
            {{ 'LON_MAX_ERROR' | translate }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end mb-2">
        <button type="submit" class="btn btn-primary mr-2" [disabled]="stationForm.invalid"><i
            class="ft-check-square mr-1"></i>{{ 'SAVE' | translate
          }}</button>
        <button type="button" class="btn btn-secondary" (click)="cancel()"><i class="ft-x mr-1"></i>{{ 'CANCEL' |
          translate }}</button>
      </div>
    </form>
  </div>
</div>