import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TripsInstancesService } from "app/modules/operating-networks/trips-management/services/trips-instances.service";
import { NotyService } from "app/shared/services/noty.service";

@Component({
  selector: "app-regenerate-trips-modal",
  templateUrl: "./regenerate-trips-modal.component.html",
  styleUrls: ["./regenerate-trips-modal.component.scss"],
})
export class RegenerateTripsModalComponent implements OnInit {
  regenerationForm: FormGroup;
  langue = localStorage.getItem("langue");

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private tripService: TripsInstancesService,
    private notyService: NotyService
  ) {
    // Initialize the form
    this.regenerationForm = this.fb.group({
      startDate: [],
      endDate: [],
    });
  }
  ngOnInit(): void {}

  regenerateTrips() {
    const { startDate, endDate } = this.regenerationForm.value;
    this.tripService.regenerateTrips(startDate, endDate).subscribe(
      (res) => {
        const successMessage =
          this.langue === "fr"
            ? "Les voyages ont été régénérés avec succès."
            : "Trips successfully regenerated.";
        this.notyService.displayNotification(successMessage, "success");
        this.modal.close("Trips regenerated");
      },
      (err) => {
        console.error("API Error: ", err);
        const errorMessage =
          this.langue === "fr"
            ? "Échec de la régénération des voyages."
            : "Failed to regenerate trips.";
        this.notyService.displayNotification(errorMessage, "error");
        this.modal.close("Error occurred");
      }
    );
  }
}
