<div class="row">
  <div class="col-12">
    <div id="kick-start" class="card">
      <div class="card-header">
        <div class="card-title">
          {{ getCardTitle() | translate }}
        </div>
      </div>
      <div class="card-content">
        <div class="card-body">
          <section id="extended">
            <div class="row">
              <div class="col-12">
                <form [formGroup]="rentForm" (ngSubmit)="saveForm()">
                  <div class="mt2">
                    <div class="bg-light-secondary px-2 w-100 text-center p-2 font-medium-3">
                      {{"General_Information"| translate}}
                    </div>
                  </div>
                  <div class="rounded p-3 mb-3 mt-3">
                    <div class="form-row">

                      <div class="col-12 col-md-6">
                        <div class="form-group mb-2">
                          <label for="client-select">{{ "Client" | translate }}</label>
                          <ng-select [items]="clientsList" [addTag]="addNewClient"
                            [addTagText]="'AJOUTER_CLIENT' | translate" [multiple]="false" formControlName="client"
                            [ngClass]="{ 'is-invalid': submitted && r.client.invalid, 'is-valid': submitted && !r.client.invalid }">
                          </ng-select>
                          <div *ngIf="submitted && r.client.errors?.required" class="invalid-feedback">
                            {{ "Client" | translate }} {{ "REQUIRED" | translate }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group mb-2">
                          <label for="basic-form-2">{{ "Date_Time" | translate }}</label>
                          <div class="d-flex gap-1">
                            <div class="flex-grow-1">
                              <div class="input-group">
                                <input class="form-control" id="rentDate" formControlName="rentDate"
                                  placeholder="{{'DATE_FORMAT' | translate}}" ngbDatepicker #d="ngbDatepicker"
                                  (dateSelect)="onDateSelect($event)"
                                  [ngClass]="{ 'is-invalid': submitted && rentForm.get('rentDate').invalid, 'is-valid': submitted && !rentForm.get('rentDate').invalid }">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                    <i class="fa fa-calendar"></i>
                                  </button>
                                </div>
                              </div>
                              <div *ngIf="submitted && rentForm.get('rentDate').errors?.required"
                                class="invalid-feedback">
                                {{ "DATE_TIME" | translate }} {{ "REQUIRED" | translate }}
                              </div>
                            </div>
                            <ngb-timepicker [spinners]="false" formControlName="rentTime" class="mt-0"
                              [ngClass]="{ 'is-invalid': submitted && rentForm.get('rentTime').invalid, 'is-valid': submitted && !rentForm.get('rentTime').invalid }">
                            </ngb-timepicker>
                            <div *ngIf="submitted && rentForm.get('rentTime').errors?.required"
                              class="invalid-feedback">
                              {{ "TIME_REQUIRED" | translate }}
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 col-12">
                        <div class="form-group mb-2">
                          <label for="basic-form-1">{{ "Bus_Nbr" | translate }}</label>
                          <input type="number" min="0" id="nbrOfBus" class="form-control" formControlName="nbrOfBus"
                            [ngClass]="{ 'is-invalid': submitted && r.nbrOfBus.invalid, 'is-valid': submitted && !r.nbrOfBus.invalid }" />
                          <div *ngIf="submitted && r.nbrOfBus.errors?.required" class="invalid-feedback">
                            {{ "Bus_Nbr" | translate }} {{ "REQUIRED" | translate }}
                          </div>
                          <div *ngIf="submitted && r.nbrOfBus.errors?.['min']" class="invalid-feedback d-block">
                            {{ 'Bus_Nbr' | translate }} {{ 'MUST_BE_POSITIVE' | translate }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group mb-2">
                          <label for="basic-form-2">{{ "Passenger_Nbr" | translate }}</label>
                          <input type="number" min="0" id="nbrOfPassenger" class="form-control"
                            formControlName="nbrOfPassenger"
                            [ngClass]="{ 'is-invalid': submitted && r.nbrOfPassenger.invalid, 'is-valid': submitted && !r.nbrOfPassenger.invalid }" />
                          <div *ngIf="submitted && r.nbrOfPassenger.errors?.required" class="invalid-feedback">
                            {{ "Passenger_Nbr" | translate }} {{ "REQUIRED" | translate }}
                          </div>
                          <div *ngIf="submitted && r.nbrOfPassenger.errors?.['min']" class="invalid-feedback d-block">
                            {{ 'Passenger_Nbr' | translate }} {{ 'MUST_BE_POSITIVE' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row mt-2">
                      <div class="col-md-6 col-12">
                        <div class="form-group mb-2">
                          <span class="badge bg-light-warning text-bold-600 p-2 col-12">
                            {{getTotalDistanceAllBlocks()}} km
                          </span>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group mb-2">
                          <span class="badge  bg-light-success text-bold-600 p-2 col-12">
                            {{getTotalDurationAllBlocksInHoursAndMinutes()}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt2">
                    <div class="bg-light-secondary px-2 w-100 text-center p-2 font-medium-3">
                      {{"Trips"| translate}}
                    </div>
                  </div>
                  <ng-container formArrayName="blocks">
                    <ng-container *ngFor="let block of blocksFormArray.controls; let blockIndex = index"
                      [formGroupName]="blockIndex">
                      <div class="border p-2 mt-2 rounded">
                        <div class="card-title mb-2 mt-1">
                          {{"Trip"| translate}} {{blockIndex + 1}}
                        </div>


                        <div class="form-row">
                          <div class="col-md-5 col-12">
                            <div class="form-group mb-2">
                              <div class="d-flex gap-1">
                                <div class="flex-grow-1">
                                  <div class="input-group ">
                                    <input class="form-control bg-white" [formControlName]="'tripDate'"
                                      placeholder="{{'DATE_FORMAT' | translate}}" ngbDatepicker #tripD="ngbDatepicker"
                                      (dateSelect)="updateTripDateTime(blockIndex)"
                                      [ngClass]="{ 'is-invalid': submitted && getBlockFormGroup(blockIndex).get('tripDate').invalid, 
                                                 'is-valid': submitted && !getBlockFormGroup(blockIndex).get('tripDate').invalid }"
                                      [minDate]="rentForm.get('rentDate').value"
                                      [dateFilter]="blockIndex === 0 ? isFirstBlockDateValid : null">
                                    <div class="input-group-append">
                                      <button class="btn btn-outline-secondary" (click)="tripD.toggle()" type="button">
                                        <i class="fa fa-calendar"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <ngb-timepicker [spinners]="false" [formControlName]="'tripTime'"
                                  (ngModelChange)="updateTripDateTime(blockIndex)"
                                  [timeValidator]="blockIndex === 0 ? isFirstBlockTimeValid : null"></ngb-timepicker>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-12">
                            <div class="form-group mb-2">
                              <input type="number" min="0" id="nbrOfNights" class="form-control bg-white"
                                [formControlName]="'nbrOfNights'" [ngClass]="{ 'is-invalid': submitted && getBlockFormGroup(blockIndex).get('nbrOfNights').invalid, 
                        'is-valid': submitted && !getBlockFormGroup(blockIndex).get('nbrOfNights').invalid }"
                                [attr.placeholder]="'Nbr_Of_Nights' | translate" />
                              <div
                                *ngIf="submitted && getBlockFormGroup(blockIndex).get('nbrOfNights').errors?.required"
                                class="invalid-feedback">
                                {{ "Nbr_Of_Nights" | translate }} {{ "REQUIRED" | translate }}
                              </div>
                              <div *ngIf="submitted && getBlockFormGroup(blockIndex).get('nbrOfNights').errors?.['min']"
                                class="invalid-feedback d-block">
                                {{ 'Nbr_Of_Nights' | translate }} {{ 'MUST_BE_POSITIVE' | translate }}
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col-12 col-md-3 mt-md-1">
                            <div class="form-group mb-2">
                              <div class="custom-switch custom-switch-success custom-control-inline mb-1 mb-xl-0">
                                <input type="checkbox" class="custom-control-input" id="color-switch-3"
                                  formControlName="empty">
                                <label class="custom-control-label mr-1" for="color-switch-3">
                                  <span>{{"Empty"| translate}}</span>
                                </label>
                              </div>
                            </div>
                          </div> -->
                          <div class="col-12 col-md-3 mt-md-1">
                            <div class="form-group mb-2">
                              <div class="custom-switch custom-switch-success custom-control-inline mb-1 mb-xl-0">
                                <input type="checkbox" class="custom-control-input"
                                  [id]="'color-switch-3-' + blockIndex" [formControlName]="'empty'"
                                  [ngClass]="{ 'is-invalid': submitted && getBlockFormGroup(blockIndex).get('empty').invalid, 'is-valid': submitted && !getBlockFormGroup(blockIndex).get('empty').invalid }" />
                                <label class="custom-control-label mr-1" [for]="'color-switch-3-' + blockIndex">
                                  <span>{{ 'Empty' | translate }}</span>
                                </label>
                                <div *ngIf="submitted && getBlockFormGroup(blockIndex).get('empty').errors?.required"
                                  class="invalid-feedback">
                                  {{ 'Empty' | translate }} {{ 'REQUIRED' | translate }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-row mt-2">
                          <div class="col-md-6 col-12">
                            <div class="form-group mb-2">
                              <span class="badge bg-light-warning text-bold-600 p-2 col-12">
                                {{ getTotalDistance(blockIndex) }} km
                              </span>
                            </div>
                          </div>
                          <div class="col-md-6 col-12">
                            <div class="form-group mb-2">
                              <span class="badge  bg-light-success text-bold-600 p-2 col-12">
                                {{ getTotalDurationInHoursAndMinutes(blockIndex) }}
                              </span>
                            </div>
                          </div>

                        </div>

                        <div class="table-responsive p-3 mb-3 mt-4">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th class="text-bold-600"># {{ 'Order' | translate }}</th>
                                <th class="text-bold-600">{{ 'Station' | translate }}</th>
                                <th class="text-bold-600">{{ 'Distance(prec)' | translate }}</th>
                                <th class="text-bold-600">{{ 'Duration' | translate }}</th>
                                <th class="text-bold-600">{{ 'Stopping_Duration' | translate }}</th>
                                <th class="text-bold-600">{{ 'Actions' | translate }}</th>
                              </tr>
                            </thead>
                            <tbody [dragula]="'DRAGULA_FACTS_' + blockIndex" [dragulaModel]="blocks[blockIndex].rents">
                              <tr *ngFor="let item of blocks[blockIndex].rents; let i = index" class="draggable">
                                <td>{{ item.order }}</td>
                                <td>
                                  <div
                                    *ngIf="blocks[blockIndex].showSelectList && !item.name && i === blocks[blockIndex].rents.length - 1">
                                    <select class="form-control mb-1"
                                      (change)="addStation($event.target.value, blockIndex)">
                                      <option value="">{{"SELECT_STATION" | translate}}</option>
                                      <option *ngFor="let station of stations" [value]="station.name">
                                        {{ station.name }}
                                      </option>
                                    </select>
                                  </div>
                                  <div *ngIf="item.name">{{ item.name }}</div>
                                  <span *ngIf="!item.name" class="text-center cursor-pointer" (click)="AddNewStation()">
                                    <i class="fa-solid fa-plus mr-1"></i>{{ "Add_Station" | translate }}
                                  </span>
                                </td>
                                <td>{{ getItineraryStations(blockIndex).at(i)?.get('distance')?.value }} km</td>
                                <td>{{ getItineraryStations(blockIndex).at(i)?.get('duration')?.value }} min</td>
                                <td>
                                  <input type="number" min="0" id="stoppingTime" class="form-control"
                                    [formControl]="getItineraryStations(blockIndex).at(i).get('stoppingTime')"
                                    placeholder="{{'PARKING_DURATION'|translate}}" [ngClass]="{
                                      'is-invalid': submitted && getItineraryStations(blockIndex).at(i).get('stoppingTime').invalid,
                                      'is-valid': submitted && !getItineraryStations(blockIndex).at(i).get('stoppingTime').invalid
                                      }" />
                                  <div
                                    *ngIf="submitted && getItineraryStations(blockIndex).at(i).get('stoppingTime').errors?.required"
                                    class="invalid-feedback">
                                    {{ "Stopping_Duration" | translate }} {{ "REQUIRED" | translate }}
                                    <span>min</span>
                                  </div>
                                  <div
                                    *ngIf="submitted && getItineraryStations(blockIndex).at(i).get('stoppingTime').errors?.['min']"
                                    class="invalid-feedback d-block">
                                    {{ 'Stopping_Duration' | translate }} {{ 'MUST_BE_POSITIVE' | translate }}
                                  </div>
                                </td>
                                <td>
                                  <button class="btn btn-danger p-1" (click)="removeStation(i, blockIndex)">
                                    <i class="ft-trash-2 font-medium-3"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>



                        <div class="pt-1 pb-1 d-flex align-content-center flex-wrap">
                          <button type="button" class="btn bg-light-secondary px-2 w-100"
                            (click)="toggleSelectList(blockIndex)">
                            <em class="ft-plus-circle mx-1"></em>{{ 'Add_Station' | translate }}
                          </button>
                        </div>

                        <div class="mr-2 ml-2" *ngIf="submitted && getItineraryStations(blockIndex).length < 2">
                          <div class="text-danger">
                            <p>{{"TWO_STATIONS_REQUIRED"| translate}}</p>
                          </div>
                        </div>
                        <div class="mb-3 p-1" style="height: 400px;">
                          <app-itineray-map
                            (routeCalculated)="onRouteCalculated($event, blockIndex)"></app-itineray-map>
                        </div>


                        <div *ngIf="blockIndex > 0" class="pt-1 pb-1 d-flex align-content-center flex-wrap">
                          <button type="button" class="btn btn-danger px-2 w-100" (click)="removeBlock(blockIndex)">
                            <em class="ft-minus-circle mx-1"></em>{{ 'Remove_Itinerary' | translate }}
                          </button>
                        </div>


                      </div>
                    </ng-container>
                  </ng-container>

                  <div class="pt-2 d-flex align-content-center flex-wrap">
                    <button type="button" class="btn bg-info text-white px-2 w-100" (click)="addBlock()">
                      <em class="ft-plus-circle mx-1"></em>{{ 'Add_Trip' | translate }}
                    </button>
                  </div>

                  <div class="pt-3 d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary"><i class="ft-check-square mr-1"></i>{{"SAVE" |
                      translate}}</button>
                    <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">
                      <i class="ft-x-square mr-1"></i>{{ "CANCEL" | translate }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>