import {Component, Input, OnInit} from '@angular/core';
import * as echarts from 'echarts';
import {SaeService} from '../services/sae.service';
import { Station } from 'app/modules/operating-networks/stations-management/models/stations.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-trip-progress',
    templateUrl: './trip-progress.component.html',
    styleUrls: ['./trip-progress.component.scss']
})
export class TripProgressComponent implements OnInit {
    @Input() itinerary: any;
    @Input() trip: any;
    @Input() tripsStations: any[] = [];
    stations: Array<Station> = [];
    busPosition = 0;
    chartInstance: any;
    routeDistance = 0;
    tripProgress = 0;
    stationsProperties: string | any[]

    constructor(
        private saeService: SaeService,
        private translate: TranslateService
    ) {
    }

    wordsToTranslate = {
        'VISITED': 'VISITED',
        'EST_ARRIVAL': 'EST_ARRIVAL',
        'IGNORED': 'IGNORED',
    };

    public getTranslation(word: string): Promise<string> {
        if (!word) {
            return Promise.resolve("");
        }
        return new Promise((r, reject) => {
            this.translate.get(word).subscribe({
                next: (translation: string) => r(translation),
                error: (err) => reject(err),
            });
        });
    }

    ngOnInit(): void {
        if (this.itinerary) {
            Promise.all(Object.keys(this.wordsToTranslate).map(async (key) => {
                this.wordsToTranslate[key] = await this.getTranslation(key);
            })).then(() => {
                this.routeDistance = this.itinerary.distance === 0 ?
                    this.itinerary.itineraryStations.reduce((acc: number, station: any) => acc + station.distance, 0)
                    : this.itinerary.distance;
               /* this.stations = this.itinerary.itineraryStations.map((station: any) => {
                    return {
                        id: station.station.id,
                        name: station.station.name,
                        distance: station.distance,
                        lat: +station.station.lat,
                        lon: +station.station.lon,
                        duration: station.duration,
                        stoppingTime: station.stoppingTime,
                        stationOrder: station.stationOrder,
                        visited: false
                    };
                });

                this.stations.sort((a, b) => a.stationOrder - b.stationOrder);
                this.stations.forEach((station, index) => {
                    station.distance = this.stations.slice(0, index + 1).reduce((acc, s) => acc + s.distance, 0);
                    if(station.stationOrder !==1) {
                        station.distance += 5;
                    }
                });*/
                this.getStopTime();
                if (this.trip) {
                    if (this.trip.status === 3) {
                        this.getUpdatedData(this.trip.id);
                    } else if (this.trip.status === 5) {
                        this.busPosition = this.routeDistance;
                        this.updateChart([[0, 0], [this.routeDistance, 0]]);
                    }
                }
            });

        }
    }

    initializeChart() {
        if (this.chartInstance) {
            this.chartInstance.dispose();
        }
        const chartDom = document.getElementById('trip-progress');
        this.chartInstance = echarts.init(chartDom);

        this.busPosition = (this.trip.progression * this.stations[this.stations.length - 1].distance) / 100 || 0;
        // travelled distance is the sum of all visited stations
        const traveledDistance = (this.trip.progression * this.stations[this.stations.length - 1].distance) / 100;
        const option = {
            xAxis: {
                type: 'value',
                splitLine: {show: false},
                axisLabel: {
                    formatter: '{value} km',
                    color: '#000000'
                },
            },
            yAxis: {
                type: 'value',
                show: false,
                splitLine: {show: false}
            },
            tooltip: {
                trigger: 'item',
                formatter: function (params: { dataIndex: string | number; seriesName: string; }) {
                    // Retrieve the station based on the index
                    const station = this.stations[params.dataIndex];

                    // Check if the hovered item is a station or a checkmark (visited station)
                    if (params && (params.seriesName === 'Stations' || params.seriesName === 'Visited Stations')) {
                        if (station) {
                            return `${station.name}<br>Distance: ${station.distance} km`;
                        }
                    }
                    return '';
                }.bind(this)
            },
            series: [
                {
                    name: 'Traveled Distance',
                    type: 'line',
                    data: [[0, 0], [traveledDistance, 0]],
                    lineStyle: {color: '#40c057', width: 4}
                },
                {
                    name: 'Remaining Route',
                    type: 'line',
                    data: this.stations.filter(station => !station.visited).map(station => [station.distance, 0]),
                    lineStyle: {color: '#a0a0a0', width: 2}
                },
                {
                    name: 'Stations',
                    type: 'scatter',
                    data: this.stations.map(station => [station.distance, 0]),
                    symbol: 'image://assets/img/leaflet/bus-stop.png',
                    symbolSize: 30,
                    symbolKeepAspect: true,
                    symbolOffset: [0, -20],
                    markPoint: {
                        data:
                            [
                            // Flattened array for visited stations
                            ...this.stations.filter(station => station.visited).map(station => {
                                const timeDifference = Math.round(
                                    (new Date(station.rtArrivalTime).getTime() -
                                        new Date(station.estimatedArrivalTime).getTime()) / 60000
                                );
                                const differenceText = `${timeDifference > 0 ? '+' : ''}${timeDifference} min`;
                                const visitedText = `${station.rtArrivalTime.replace('T', ' ')}`;
                                const labelColor = timeDifference <= 0 ? 'green' : 'red';

                                return {
                                    coord: [station.distance, 0],
                                    symbol: 'image://assets/img/markers/checkmark.png',
                                    symbolSize: [20, 20],
                                    value: timeDifference,
                                    label: {
                                        show: true,
                                        formatter: [
                                            '{difference|' + differenceText + '}',
                                            '{marker|}',
                                            '{visited|' + visitedText + '}'
                                        ].join('\n'),
                                        rich: {
                                            difference: {
                                                color: labelColor,
                                                position: 'center',
                                                padding: [5, 5, 5, 50],
                                                borderRadius: 3,
                                                backgroundColor: '#ffffff',
                                                offset: [0, -20]
                                            },
                                            marker: {
                                                height: 70
                                            },
                                            visited: {
                                                color: '#2f8be6',
                                                position: 'bottom',
                                                padding: [0, 0],
                                                borderRadius: 3,
                                                backgroundColor: '#ffffff',
                                                offset: [0, 0]
                                            }
                                        },
                                        position: 'insideBottom',
                                        distance: -30
                                    }
                                };
                            }),
                            // Flattened array for unvisited stations
                                ...this.stations.filter(station => !station.visited).map(station => ({
                                coord: [station.distance, 0],
                                symbol: 'circle', // No checkmark for unvisited stations
                                symbolSize: [1, 1],
                                value: 0,
                                label: {
                                    show: true,
                                    formatter: station.status === 3 ? '{estimated|' + this.wordsToTranslate['IGNORED']+ '}' :
                                        '{estimated|' + this.wordsToTranslate['EST_ARRIVAL'] + ': ' +
                                        station.estimatedArrivalTime.replace('T', ' ').split(' ')[1].slice(0, 5) + '}',
                                    rich: {
                                        estimated: {
                                            color: station.status === 3 ? 'red' : 'orange',
                                            padding: [5, 5],
                                            borderRadius: 3,
                                            backgroundColor: '#ffffff',
                                            offset: [-10, -20]
                                        }
                                    },
                                    position: 'insideTop',
                                    distance: -60
                                }
                            }))
                        ]
                    }

                },
                {
                    name: 'Bus',
                    type: 'scatter',
                    data: [[this.busPosition, 0]],
                    symbol: 'image://assets/img/markers/bus-icon.png',
                    symbolSize: 40,
                    symbolOffset: [0, -17]
                },
            ]
        };

        this.chartInstance.setOption(option);
    }


    updateBusPosition(message: { distance: number; progress: number; }): void {
        const busDistance = message.distance;
        this.busPosition = message.distance;
        this.tripProgress = message.progress;
        for (let i = 1; i < this.stationsProperties.length; i++) {
            if (this.stationsProperties[i].rtArrivalTime && !this.stationsProperties[i].rtArrivalTime.match(/\d{4}-\d{2}-\d{2} null/)) {
                this.stations[i].visited = true;
                this.stations[i].rtArrivalTime = this.stationsProperties[i].rtArrivalTime;
                this.stations[i].rtDepartureTime = this.stationsProperties[i].rtDepartureTime;
            }else {
                // this.stations[i].estimatedArrivalRt = this.stationsProperties[i].estimatedArrivalTime;
                 this.stations[i].estimatedArrivalTime = this.stationsProperties[i].estimatedArrivalTime;
                 this.stations[i].estimatedDepartureTime = this.stationsProperties[i].plannedDepartureTime;
            }
        }
        this.updateChart([[0, 0], [busDistance, 0]], [[busDistance, 0], [this.routeDistance, 0]]);
    }

    private updateChart(traveledData: number[][], routeData?: number[][]): void {
        this.chartInstance.setOption({
            series: [
                {
                    name: 'Bus',
                    data: [[this.busPosition, 0]],
                },
                {
                    name: 'Traveled Distance',
                    data: traveledData,
                    symbol: 'none',
                    lineStyle: {color: '#40c057', width: 4}
                },
                {
                    name: 'Remaining Route',
                    data: routeData,
                    lineStyle: {color: '#a0a0a0', width: 2}
                },
                {
                    name: 'Stations',
                    type: 'scatter',
                    data: this.stations.map(station => [station.distance, 0]),
                    symbol: 'image://assets/img/leaflet/bus-stop.png',
                    symbolSize: 30,
                    symbolKeepAspect: true,
                    symbolOffset: [0, -20],
                    markPoint: {
                        data:
                            [
                                // Flattened array for visited stations
                                ...this.stations.filter(station => station.visited).map(station => {
                                    const timeDifference = Math.round(
                                        (new Date(station.rtArrivalTime).getTime() -
                                            new Date(station.estimatedArrivalTime).getTime()) / 60000
                                    );
                                    const differenceText = `${timeDifference > 0 ? '+' : ''}${timeDifference} min`;
                                    const visitedText = `${station.rtArrivalTime.replace('T', ' ')}`;
                                    const labelColor = timeDifference <= 0 ? 'green' : 'red';

                                    return {
                                        coord: [station.distance, 0],
                                        symbol: 'image://assets/img/markers/checkmark.png',
                                        symbolSize: [20, 20],
                                        value: timeDifference,
                                        label: {
                                            show: true,
                                            formatter: [
                                                '{difference|' + differenceText + '}',
                                                '{marker|}',
                                                '{visited|' + visitedText + '}'
                                            ].join('\n'),
                                            rich: {
                                                difference: {
                                                    color: labelColor,
                                                    position: 'center',
                                                    padding: [5, 5, 5, 50],
                                                    borderRadius: 3,
                                                    backgroundColor: '#ffffff',
                                                    offset: [0, -20]
                                                },
                                                marker: {
                                                    height: 70
                                                },
                                                visited: {
                                                    color: '#2f8be6',
                                                    position: 'bottom',
                                                    padding: [0, 0],
                                                    borderRadius: 3,
                                                    backgroundColor: '#ffffff',
                                                    offset: [0, 0]
                                                }
                                            },
                                            position: 'insideBottom',
                                            distance: -30
                                        }
                                    };
                                }),
                                // Flattened array for unvisited stations
                                ...this.stations.filter(station => !station.visited).map(station => ({
                                    coord: [station.distance, 0],
                                    symbol: 'circle', // No checkmark for unvisited stations
                                    symbolSize: [1, 1],
                                    value: 0,
                                    label: {
                                        show: true,
                                        formatter: station.status === 3 ? '{estimated|' + this.wordsToTranslate['IGNORED']+ '}' :
                                            '{estimated|' + this.wordsToTranslate['EST_ARRIVAL'] + ': ' +
                                            station.estimatedArrivalTime.replace('T', ' ').split(' ')[1].slice(0, 5) + '}',
                                        rich: {
                                            estimated: {
                                                color: station.status === 3 ? 'red' : 'orange',
                                                padding: [5, 5],
                                                borderRadius: 3,
                                                backgroundColor: '#ffffff',
                                                offset: [-10, -20]
                                            }
                                        },
                                        position: 'insideTop',
                                        distance: -60
                                    }
                                }))
                            ]
                    }

                },
            ]
        });
    }

    getUpdatedData(idTrip: number): void {
        this.saeService.connect();
        this.saeService.message$.subscribe((message) => {
            if(parseInt(message.TripId, 10) === idTrip) {
                this.handleMessage(message);
            }
        });
    }

    handleMessage(message: any): void {
            this.stationsProperties = message.stations.map((station: any) => {
                return {
                    name: station.properties.name,
                    rtArrivalTime: station.properties.arrival_time_rt,
                    estimatedArrivalTime: station.properties.estimated_arrival_time,
                    plannedDepartureTime: station.properties.departude_time,
                    rtDepartureTime: station.properties.departude_time_rt,
                    lat: station.geometry.coordinates[1],
                    lon: station.geometry.coordinates[0]
                };
            });
            this.updateBusPosition(message);
    }

    // get stop time
    getStopTime(): any {
        this.stations = this.tripsStations
        this.initializeChart()
    }
}
