<div class="modal-header">
  <h4 class="modal-title">
    {{
    viewMode && item.status === 2 ? ('View_Intervention' | translate) :
    (isEditMode ? ('Edit_Intervention' | translate) :
    ('New_Intervention' | translate))
    }}
    <span class="font-weight-bolder mr-5" *ngIf="item"> #{{ item?.number }}</span>
  </h4>
  <span class="badge bg-light-info mt-1" *ngIf="isEditMode || viewMode">
    {{
    item?.status === 2 ?
    ('status_completed' | translate) :
    ('status_in_progress' | translate)
    }}
  </span>

  <button type="button" class="close" aria-label="Close " (click)="activeModal.close('Cross click')">
    <i class="fa-solid fa-xmark"></i>
  </button>
</div>

<div class="modal-body ">
  <form [formGroup]="operatorForm" (ngSubmit)="save()">

    <div class="card shadow-none border">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h4 class="card-title">{{"Intervention_Details" | translate}}</h4>
        <div>
          <div class="form-check-inline mr-5">
            <input class="form-check-input" type="radio" id="typeInternal" formControlName="type" [value]="1" checked>
            <label class="form-check-label" for="typeInterne">
              {{ 'Internal' | translate }}
            </label>
          </div>
          <div class="form-check-inline ">
            <input class="form-check-input" type="radio" id="typeExterne" formControlName="type" [value]="0">
            <label class="form-check-label" for="typeExterne">
              {{ 'External' | translate }}
            </label>
          </div>
        </div>
      </div>

      <div class="card-content">
        <div class="card-body">

          <div class="form-row">
            <div class="col-md-6 col-12">
              <div class="form-group mb-2">
                <label for="basic-form-1">{{"Intervention_Date"| translate}}</label>
                <div class="row">

                  <br>
                  <div class="input-group col-7">
                    <input class="form-control" id="date" formControlName="date"
                      placeholder="{{'DATE_FORMAT' | translate}}" ngbDatepicker #d="ngbDatepicker"
                      (dateSelect)="onDateSelect($event)"
                      [ngClass]="{ 'is-invalid': submitted && o.date.invalid, 'is-valid': submitted && o.date.invalid }">

                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                    <div *ngIf="submitted && o.date.errors?.required" class="invalid-feedback">
                      {{ "Intervention_Date" | translate }} {{ "REQUIRED" | translate }}
                    </div>
                  </div>
                  <div class="col-5">
                    <ngb-timepicker [spinners]="false" formControlName="time" class="mb-2"
                      [ngClass]="{ 'is-invalid': submitted && o.time.invalid, 'is-valid': submitted && o.time.invalid }">
                    </ngb-timepicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12" *ngIf="operatorForm.get('type')?.value === 1">
              <div class="form-group mb-2">
                <label for="basic-form-2">{{"Intervention_Supervisor" | translate}}</label>
                <input type="text" id="supervisor" formControlName="supervisor"
                  [ngClass]="{ 'is-invalid': submitted && o.supervisor.invalid, 'is-valid': submitted && o.supervisor.invalid }"
                  class="form-control">
              </div>
            </div>
            <div class="col-md-3 col-12" *ngIf="operatorForm.get('type')?.value === 0">
              <div class="form-group mb-2">
                <label for="basic-form-2">{{"Intervention_Provider" | translate}}</label>
                <input type="text" id="provider" formControlName="provider"
                  [ngClass]="{ 'is-invalid': submitted && o.provider.invalid, 'is-valid': submitted && o.provider.invalid }"
                  class="form-control">

              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="form-group mb-2">
                <label for="basic-form-2">{{"Intervention_Duration" | translate}}</label>
                <input type="text" id="duration" formControlName="duration" class="form-control"
                  [readonly]="operatorForm.get('type')?.value === 1"
                  [placeholder]="operatorForm.get('type')?.value === 0 ? '0h 0m' : ''"
                  (blur)="formatDuration($event.target.value)">
              </div>
            </div>
          </div>
          <div class="form-row">

            <div class="col-6">
              <div class="form-group mb-2">
                <label for="interventionType">{{ 'INTERVENTION_TYPE_LABEL' | translate }}</label>
                <ng-select [items]="interventionTypes" bindLabel="name" bindValue="id"
                  formControlName="interventionTypeId" [clearable]="false"
                  [placeholder]="'SELECT_INTERVENTION_TYPE' | translate" (change)="onInterventionTypeChange($event)"
                  [ngClass]="{ 'is-invalid': submitted && o.interventionTypeId.invalid, 'is-valid': submitted && o.interventionTypeId.valid}">
                </ng-select>
                <div *ngIf="submitted && o.interventionTypeId.errors?.required" class="invalid-feedback">
                  {{ 'INTERVENTION_TYPE_REQUIRED' | translate }}
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group mb-2">
                <label for="interventionType">{{ 'INTERVENTION_NATURE_LABEL' | translate }}</label>
                <ng-select [items]="interventionNaturesFiltred" bindLabel="description" bindValue="id"
                  formControlName="interventionNatureId" [clearable]="false"
                  [placeholder]="'SELECT_INTERVENTION_NATURE' | translate"
                  (change)="onInterventionNatureChange($event)">
                </ng-select>
                <div *ngIf="submitted && o.interventionTypeId.errors?.required" class="invalid-feedback">
                  {{ 'INTERVENTION_NATURE_REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div *ngIf="operatorForm.get('type')?.value === 1" class="col-12">
              <div class="form-group mb-2">
                <label>{{"Diagnostic" | translate}}</label>
                <textarea rows="3" class="form-control" formControlName="diagnostic"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="card shadow-none border" *ngIf="operatorForm.get('type')?.value === 1">
      <div class="d-flex align-items-center card-header justify-content-between pt-2">
        <h4 class="card-title">{{"OPERATORS_LIST"| translate}}</h4>
        <div class="align-self-end px-2 py-1 d-flex flex-wrap text-white" *ngIf="!showOperatorForm && !viewMode">
          <button type="button" class="btn bg-info bg-lighten-1 px-2 w-100 btn-sm" (click)="addOperator()"
            [appHasPermission]="['update_work_order']">
            <em class="ft-plus-circle mx-1 white"></em> <span class="text-white">{{ 'Add_Operator' | translate
              }}</span>
          </button>
        </div>
      </div>
      <div class="card-content">

        <div *ngIf="showOperatorForm" class="p-2 border">
          <div class="d-flex flex-grow-1 flex-column flex-md-row">
            <ng-select [items]="availableOperators" bindLabel="fullName" bindValue="id"
              formControlName="selectedOperator" [clearable]="false" [placeholder]="'Select_Operator' | translate"
              [searchable]="true" class="flex-grow-1 mb-2 mb-md-0">
            </ng-select>
            <div class="ml-md-2 d-flex">
              <button class="btn btn-success mr-2" (click)="saveOperator()"
                [disabled]="!operatorForm.get('selectedOperator')?.value">
                {{ 'Add' | translate }}
              </button>
              <button class="btn btn-danger" (click)="cancelAddOperator()">
                {{ 'Cancel' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div class="card-body table-responsive p-2">

          <table class="table border-top-0 mb-0"
            *ngIf="selectedOperators && selectedOperators.length > 0; else noOperators">
            <thead>
              <tr>
                <th>{{ 'Mat_Operator'|translate }}</th>
                <th>{{ 'Name_Operator'|translate }}</th>
                <th>{{ 'Total_Work_Duration'|translate }}</th>
                <th colSpan="2">{{ 'Work_Log'|translate }}
                </th>

              </tr>
            </thead>
            <tbody *ngFor="let operator of selectedOperators">
              <tr class="cursor-pointer ">
                <td class="col-3">
                  <span>{{ operator.matricule }}</span>
                </td>
                <td class="col-3">
                  <span>
                    {{ operator.firstName }} {{ operator.lastName }}
                  </span>
                </td>

                <td class="col-3">
                  {{ operator.totalWorkDuration }}
                </td>

                <!-- <td class="col-2">
                  <span (click)="isEditMode && addWorkDiary(operator)"
                    [ngClass]="{'text-muted': !isEditMode, 'text-info': isEditMode}">
                    <i class="fa-regular fa-clock" [ngClass]="{'text-info': isEditMode, 'text-muted': !isEditMode}"></i>
                  </span>
                </td> -->
                <td class="col-2">
                  <span (click)="!viewMode && isEditMode && addWorkDiary(operator)"
                    [ngClass]="{'text-muted': viewMode || !isEditMode, 'text-info': !viewMode && isEditMode}">
                    <i class="fa-regular fa-clock"
                      [ngClass]="{'text-info': !viewMode && isEditMode, 'text-muted': viewMode || !isEditMode}">
                    </i>
                  </span>
                </td>

                <td *ngIf="!viewMode"> <span class="col-1" (click)="removeOperator(operator)"><i
                      class="fa fa-trash text-danger" aria-hidden="true"></i>
                  </span></td>
              </tr>
            </tbody>
          </table>

          <ng-template #noOperators>
            <p class="mb-0 text-center font-small-3">({{"OPERATORS_LIST_EMPTY"| translate}})</p>
          </ng-template>

          <div *ngIf="operatorError" class=" px-2 py-1 d-flex align-content-center flex-wrap text-danger">
            {{ 'At_least_one_operator_required' | translate }}
          </div>

        </div>
      </div>
    </div>
    <div class="card shadow-none border" *ngIf="operatorForm.get('type')?.value === 1">
      <div class="d-flex align-items-center card-header justify-content-between pt-2">
        <h4 class="card-title">{{"SPARE_PART_LIST"| translate}}</h4>
        <div class="align-self-end px-2 py-1 d-flex flex-wrap text-white" *ngIf="!showSparePartForm && !viewMode">
          <button type="button" class="btn bg-info bg-lighten-1 px-2 w-100 btn-sm" (click)="addSparePart()">
            <em class="ft-plus-circle mx-1 white"></em>
            <span class="text-white">{{'ADD_SPARE_PART' | translate}}</span>
          </button>
        </div>
      </div>
      <div class="card-content">


        <div *ngIf="showSparePartForm" class="p-2 border">
          <div class="d-flex flex-column flex-md-row">

            <ng-select class="col-3" [items]="sparePartList" bindLabel="name" bindValue="id" [addTag]="addNewSparePart"
              [addTagText]="'ADD_SPARE_PART' | translate" [multiple]="false" formControlName="sparePart"
              [ngClass]="{ 'is-invalid': submitted && r.sparePart.invalid, 'is-valid': submitted && !r.sparePart.invalid }">
            </ng-select>

            <input type="number" id="quantity" formControlName="quantity" class="form-control mr-2 col-2"
              placeholder="{{ 'QUANTITY' | translate }}">

            <div class="form-check-inline mr-2">
              <input class="form-check-input" type="radio" id="stateNew" formControlName="state" [value]="1" checked>
              <label class="form-check-label" for="stateNew">
                {{ 'NEW' | translate }}
              </label>
            </div>

            <div class="form-check-inline mr-2">
              <input class="form-check-input" type="radio" id="stateUsed" formControlName="state" [value]="0">
              <label class="form-check-label" for="stateUsed">
                {{ 'USED' | translate }}
              </label>
            </div>


            <ng-select [items]="vehicles" bindLabel="vehicleNumber" bindValue="id" formControlName="selectedVehicleId"
              [clearable]="false" [placeholder]="'SELECT_VEHICLE' | translate" [searchable]="true"
              *ngIf="operatorForm.get('state')?.value == 0" class="flex-grow-1 mr-2">
            </ng-select>
            <div class="d-flex">
              <button class="btn btn-success mr-2" (click)="saveSparePart()">
                {{ 'Add' | translate }}
              </button>
              <button class="btn btn-danger" (click)="cancelAddSparePart()">
                {{ 'Cancel' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div class="card-body table-responsive p-2">

          <table class="table border-top-0 mb-0"
            *ngIf="selectedSparePart && selectedSparePart.length > 0; else noSpareParts">
            <thead>
              <tr>
                <th class="col-3">{{ 'SPARE_PART'|translate }}</th>
                <th class="col-3">{{ 'QUANTITY'|translate }}</th>
                <th class="col-3">{{ 'STATE'|translate }}</th>
                <th class="col-2">{{ 'VEHICLE'|translate }}</th>
                <th scope="col-1"></th>
              </tr>
            </thead>
            <tbody *ngFor="let sparePart of selectedSparePart">

              <tr class="cursor-pointer ">
                <td class="col-3">
                  <span>{{ sparePart.sparePartName }}</span>
                </td>
                <td class="col-3">
                  <span>{{ sparePart.quantity }}</span>
                </td>
                <td class="col-3">
                  {{ sparePart.state === 1 ? ('NEW' | translate) : ('USED' | translate) }}
                </td>
                <td class="col-2">
                  {{ sparePart.vehicleNumber }}
                </td>
                <td *ngIf="!viewMode">
                  <span class="col-1" (click)="removeSparePart(sparePart)">
                    <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <ng-template #noSpareParts>
            <p class="mb-0 text-center font-small-3">({{"SPARE_PART_LIST_EMPTY"| translate}})</p>
          </ng-template>

        </div>

      </div>
    </div>


    <div class="form-row border border-light p-2 mt-2 rounded"
      *ngIf="selectedTypeInterventionType == 1 || selectedTypeInterventionType == 2  ">
      <div class="col-md-6 col-12">
        <div class="form-group mb-2">
          <label for="basic-form-1">{{"Oil_Quantity" | translate}} </label>
          <div class="input-group">
            <input class="form-control" type="number" min="0" id="oilQuantity" formControlName="oilQuantity">
            <div class="input-group-append">
              <span class="input-group-text">L</span>
            </div>
          </div>
          <div *ngIf="submitted && o.oilQuantity.errors?.['min']" class="invalid-feedback d-block">
            {{ 'Oil_Quantity' | translate }} {{ 'MUST_BE_POSITIVE' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-row border border-light p-2 mt-2 rounded">
      <div class="col-md-6 col-12">
        <div class="form-group mb-2">
          <label for="basic-form-2">{{"Index_Kilometers" | translate}}</label>
          <div class="input-group">
            <input class="form-control" type="number" min="0" id="indexKilometers" formControlName="indexKilometers">
            <div class="input-group-append">
              <span class="input-group-text">km</span>
            </div>
          </div>
          <div *ngIf="submitted && o.indexKilometers.errors?.['min']" class="invalid-feedback d-block">
            {{ 'Index_Kilometers' | translate }} {{ 'MUST_BE_POSITIVE' | translate }}
          </div>
        </div>
      </div>
    </div>


    <div class="form-row border border-light p-2 mt-2 rounded">
      <div class="col-md-12 col-12">
        <div class="form-group mb-2">
          <label>{{"Description" | translate}}</label>
          <textarea rows="3" class="form-control" formControlName="observation"></textarea>
        </div>
      </div>
    </div>


    <div class="card shadow-none border" [appHasPermission]="['conformity_intervention']" *ngIf="operatorForm.get('status')?.value === 2">
      <div class="d-flex align-items-center card-header justify-content-between pt-2">
        <h4 class="card-title">{{ "CONFORMITY" | translate }}</h4>
      </div>

      <div class="card-content p-3">
        <div class="form-group">
          <div class="form-check-inline mr-5">
            <input class="form-check-input" type="radio" id="conform" formControlName="conformity" [value]="2" />
            <label class="form-check-label" for="conform">
              {{ 'CONFORM' | translate }}
            </label>
          </div>

          <div class="form-check-inline">
            <input class="form-check-input" type="radio" id="notconform" formControlName="conformity" [value]="3" />
            <label class="form-check-label" for="notconform">
              {{ 'NOT_CONFORM' | translate }}
            </label>
          </div>
        </div>

        <div *ngIf="operatorForm.get('conformity')?.value === 3" class="mt-3">
          <label for="noConformityReason">{{ 'NO_CONFORMITY_REASON' | translate }}</label>
          <input type="text" id="noConformityReason" formControlName="noConformityReason" class="form-control"
            [placeholder]="'Enter reason for non-conformity' | translate"
            [ngClass]="{ 'is-invalid': submitted && operatorForm.get('noConformityReason')?.errors?.['required'] }" />
          <div class="invalid-feedback"
            *ngIf="submitted && operatorForm.get('noConformityReason')?.errors?.['required']">
            {{ 'NO_CONFORMITY_REASON_REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>

  </form>


</div>



<div class="modal-footer">
  <button *ngIf="completeMode" type="button" class="btn btn-success" [appHasPermission]="['finish_intervention']" (click)="completeIntervention(item.id)">
    <i class="ft-check-square mr-1"></i>{{"COMPLETE" | translate}}
  </button>

  <button *ngIf="!viewMode" type="button" class="btn btn-primary" (click)="save()">
    <i class="ft-check-square mr-1"></i>{{"SAVE" | translate}}
  </button>

  <button *ngIf="viewMode && item.status === 2" type="button" class="btn btn-primary"
    (click)="conformIntervention(item.id)" [appHasPermission]="['conformity_intervention']">
    <i class="ft-check-square mr-1"></i>{{"SAVE" | translate}}
  </button>

  <button type="button" class="btn btn-secondary" (click)="closeModal()">
    <i class="ft-x mr-1"></i>{{"CANCEL"|translate}}
  </button>
</div>
