<div class="modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header flex flex-col">
      <div class="flex items-center"> 
        <h4 class="modal-title">
          {{ (type === 'add' ? 'CREATE_WORK_REQUEST' : type === 'details' ? 'DETAILS_WORK_REQUEST' :
          'EDIT_WORK_REQUEST') | translate }}
          <span *ngIf="type !== 'add'"> #{{ data.id }}</span>
        </h4>


      </div>
      <div class="row">
        <div> <!-- Second row with status -->
          <img *ngIf="this.type =='details'" src="/assets/img/map.png" alt="Vehicle Map" class="vehicle-map ml-auto"
            ngbTooltip="{{'View_Vehicle_Position'|translate}}" placement="left" (click)="openMap()">
        </div>
        <button type="button" class="close mr-1" aria-label="Close" (click)="activeModal.close('Cross click')">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>
    <div class="modal-body" [formGroup]="formGroup">

      <span class="badge" style="float: right;" *ngIf="type != 'add'"
        [class]="'bg-light-' + state[data.state - 1]?.bgName ">
        {{ getStateNameById(data.state) | translate }}
      </span>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">{{"VEHICLE_DETAILS" | translate}}</h4>
        </div>
        <div class="card-content">
          <div class="card-body">

            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-group mb-2">
                  <label for="vehicleType">{{ 'VEHICLE_TYPE_LABEL' | translate }}</label>
                  <ng-select [items]="vehicleType" bindLabel="name" bindValue="id" formControlName="vehicleTypeId"
                    [placeholder]="'VEHICLE_TYPE_LABEL' | translate" [searchable]="true"
                    [ngClass]="{'is-invalid': formGroup.get('vehicleTypeId').invalid && notValid, 
                                'is-valid': formGroup.get('vehicleTypeId').valid && formGroup.get('vehicleTypeId').touched}" (change)="onVehicleTypeChange($event)">
                  </ng-select>
                  <div *ngIf="formGroup.get('vehicleTypeId').invalid && notValid" class="text-danger">
                    {{ 'VEHICLE_TYPE_REQUIRED' | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group mb-2">
                  <label for="vehicle">{{ 'VEHICLE_LABEL' | translate }}</label>
                  <ng-select [items]="filtredVehicles" bindLabel="name" bindValue="id" formControlName="vehiclesId"
                    [readonly]="type === 'details'" [disabled]="type === 'details'" (change)="onVehicleChange($event)"
                    [placeholder]="'VEHICLE_LABEL' | translate" [searchable]="true" [ngClass]="{'is-invalid': formGroup.get('vehiclesId').invalid && notValid, 
                                'is-valid': formGroup.get('vehiclesId').valid && formGroup.get('vehiclesId').touched}">
                  </ng-select>
                  <div *ngIf="formGroup.get('vehiclesId').invalid && notValid" class="text-danger">
                    {{ 'VEHICLE_REQUIRED' | translate }}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h4 class="card-title">{{"REQUEST_DETAILS" | translate}}</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-group mb-2">
                  <label for="date">{{ 'DATE_LABEL' | translate }}</label>
                  <input type="datetime-local" class="form-control" id="date" formControlName="requestDate"
                    [ngClass]="{'is-invalid': formGroup.get('requestDate').invalid && notValid,
                                'is-valid': formGroup.get('requestDate').valid && formGroup.get('requestDate').touched}" />
                  <div *ngIf="formGroup.get('requestDate').invalid && notValid" class="text-danger">
                    {{ 'DATE_REQUIRED' | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group mb-2">
                  <label for="applicant">{{ 'APPLICANT_LABEL' | translate }}</label>
                  <input type="text" class="form-control" formControlName="applicant" readonly
                    [ngClass]="{'is-valid': formGroup.get('applicant').valid && formGroup.get('applicant').touched }">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-group mb-2">
                  <label for="applicant">{{ 'REQUEST_TYPE' | translate }}</label>
                  <div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="intervention" formControlName="requestType"
                        value="Repair">
                      <label class="form-check-label" for="intervention">{{ 'REPAIR_REQUEST' | translate }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="maintenance" formControlName="requestType"
                        value="Maintenance">
                      <label class="form-check-label" for="maintenance">{{ 'MAINTENANCE_REQUEST' | translate }}</label>
                    </div>
                    <div *ngIf="type === 'details' && formGroup.get('requestType').value === 'Renouvellement'"
                      class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="renewal" formControlName="requestType"
                        value="Renouvellement" (change)="onRenewalChange()">
                      <label class="form-check-label" for="renewal">{{ 'RENEWAL_REQUEST' | translate }}</label>
                    </div>
                    <div *ngIf="formGroup.get('requestType').invalid && notValid" class="text-danger">
                      {{ 'ACTION_TYPE_REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group mb-2">
                  <label for="applicant">{{ 'REQUEST_LEVEL' | translate }}</label>
                  <select class="form-control" id="urgency" formControlName="urgency" [ngClass]="{'is-invalid': formGroup.get('urgency').invalid && notValid,
                                'is-valid': formGroup.get('urgency').valid && formGroup.get('urgency').touched}">
                    <option value=null disabled selected hidden>{{'SELECT_URGENCY' | translate }} </option>
                    <option [value]="1">{{"HIGH"|translate}}</option>
                    <option [value]="2">{{"MEDIUM"|translate}}</option>
                    <option [value]="3">{{"LOW"|translate}}</option>
                  </select>
                  <div *ngIf="formGroup.get('urgency').invalid && notValid" class="text-danger">
                    {{ 'URGENCY_REQUIRED' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-12">
                <div class="form-group mb-2" *ngIf="showCategorySelect">
                  <label class="col-form-label">
                    {{ 'INTERVENTION_CATEGORY' | translate }}
                    <span class="text-danger">*</span>
                  </label>
                  <ng-select [items]="interventionCategories" [clearable]="true" bindLabel="name" bindValue="id"
                    placeholder="{{ 'SELECT_CATEGORY' | translate }}" formControlName="interventionCategoryId"
                    [ngClass]="{'is-invalid': formGroup.get('interventionCategoryId').invalid && notValid, 
                                'is-valid': formGroup.get('vehicleTypeId').valid && formGroup.get('vehicleTypeId').touched}">
                  </ng-select>
                  <div *ngIf="notValid && formGroup.get('interventionCategoryId').invalid" class="text-danger">
                    {{ 'CATEGORY_REQUIRED' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-12">
                <div class="form-group mb-2">
                  <label for="description" class="col-form-label">{{ 'DESCRIPTION_LABEL' | translate }}</label>
                  <textarea class="form-control" id="description" formControlName="description" rows="3"
                    [ngClass]="{'is-invalid': formGroup.get('description').invalid && notValid,
                                'is-valid': formGroup.get('description').valid && formGroup.get('description').touched }"></textarea>
                  <div *ngIf="formGroup.get('description').invalid && notValid" class="text-danger">
                    {{ 'DESCRIPTION_REQUIRED' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <!-- <div *ngIf="formGroup.get('requestType').value === 'Renouvellement'">
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group mb-2">
                    <label for="renewalDate">{{ 'DATE_DE_RENOUVELLEMENT' | translate }}</label>
                    <input type="date" class="form-control" id="renewalDate" formControlName="renewalDate" />
                  </div>
                </div>
              </div>
            </div> -->
            <div class="row" *ngIf="formGroup.get('requestType').value != 'Renouvellement'">
              <div class="col-md-12">
                <div class="form-group mb-2"
                  [ngClass]="{'disabled':formGroup.get('state').value === 1 && type === 'details'}">
                  <label for="attachment" class="col-form-label">{{ 'ATTACHMENT_LABEL' | translate }} <span
                      style="font-size: 1em; font-weight: 400;">(png/jpeg/jpg)</span>
                    <div class="text-muted small mt-1">
                      {{ 'MAX_FILE_UPLOAD_INFO' | translate: {
                      maxFiles: maxImageUploadLimit,
                      maxTotalSize: maxTotalImageSizeMB,
                      maxSingleSize: maxSingleImageSizeMB
                      } }}
                    </div>
                  </label>
                  <span *ngIf="selectedFiles.length !==0"> ({{ selectedFiles.length }}/{{ maxImageUploadLimit }})</span>
                  <div class="file">
                    <label class="file-input uploadfilecontainer" for="attachment"
                      *ngIf="formGroup.get('state').value < 2">
                      <input type="file" (change)="onFileSelected($event)" multiple id="attachment" name="attachment"
                        style="display: none;"
                        [ngClass]="{'is-valid': formGroup.get('files').valid && formGroup.get('files').touched}" />
                      <div *ngIf="isError" class="text-danger ml-2">{{ errorMessage }}</div>
                    </label>
                    <div id="selected-files">
                      <ul class="file-list">
                        <li *ngFor="let fileControl of formGroup.get('files').value; let i = index" class="file">
                          <img [src]="fileControl" alt="" class="file-image" (click)="openImageModal(fileControl)">
                          <em class="fa-solid fa-circle-xmark cross" (click)="removeFile(i)"></em>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="formGroup.get('requestType').value === 'Renouvellement'">
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group mb-2">
                    <label for="renewalDate">{{ 'DATE_DE_RENOUVELLEMENT' | translate }}</label>
                    <input type="date" class="form-control" id="renewalDate" formControlName="renewalDate" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="formGroup.get('requestType').value != 'Renouvellement'">
        <div class="card" *ngIf="showOperationsTable">
          <div class="card-header" [appHasPermission]="['operation_list']">
            <h4 class="card-title">{{ 'OPERATIONS_LIST' | translate }}</h4>
          </div>
          <div class="card-content" [appHasPermission]="['operation_list']">
            <div class="card-body">
              <!-- Operations -->
              <div class="form-row">
                <div class="col-md-12">
                  <div class="add-operation-zone" *ngIf="showAddOperation">
                    <div [formGroup]="newOperationForm">
                      <div class="card">
                        <div class="card-header">
                          <h4 class="card-title">{{ 'NEW_OPERATION' | translate }}</h4>
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <!-- Category Display -->
                            <div class="row">
                              <div class="col-md-12 col-12">
                                <div class="form-group mb-2">
                                  <label>{{ 'OPERATIONS_TABLE.CATEGORY' | translate }}</label>
                                  <input type="text" class="form-control" [value]="categoryName" readonly />
                                </div>
                              </div>
                            </div>

                            <!-- Maintenance Type Selection -->
                            <div class="row">
                              <div class="col-md-12 col-12">
                                <div class="form-group mb-2">
                                  <label>{{ 'OPERATIONS_TABLE.MAINTENANCE_TYPE' | translate }}</label>
                                  <ng-select [items]="filteredMaintenanceTypes" bindLabel="name" bindValue="id"
                                    formControlName="maintenanceTypeId"
                                    [placeholder]="'SELECT_MAINTENANCE_TYPE' | translate" [searchable]="true"
                                    [ngClass]="{'is-invalid': newOperationForm.get('maintenanceTypeId').invalid && notValidOperation,
                            'is-valid': newOperationForm.get('maintenanceTypeId').valid && newOperationForm.get('maintenanceTypeId').touched}">
                                  </ng-select>
                                  <div *ngIf="newOperationForm.get('maintenanceTypeId').invalid && notValidOperation"
                                    class="invalid-feedback">
                                    {{ 'MAINTENANCE_TYPE_REQUIRED' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- Intervention Type -->
                            <div class="row">
                              <div class="col-md-12 col-12">
                                <div class="form-group mb-2">
                                  <label>{{ 'INTERVENTION_TYPE' | translate }}</label>
                                  <ng-select [items]="interventionTypes" bindLabel="name" bindValue="id"
                                    formControlName="interventionTypeId" (change)="onInterventionTypeChange($event)"
                                    [placeholder]="'OPERATIONS_TABLE.SELECT_TYPE' | translate" [searchable]="true"
                                    [ngClass]="{'is-invalid': newOperationForm.get('interventionTypeId').invalid && notValidOperation,
                            'is-valid': newOperationForm.get('interventionTypeId').valid && newOperationForm.get('interventionTypeId').touched}">
                                  </ng-select>
                                  <div *ngIf="newOperationForm.get('interventionTypeId').invalid && notValidOperation"
                                    class="invalid-feedback">
                                    {{ 'INTERVENTION_TYPE_REQUIRED' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- Intervention Nature -->
                            <div class="row">
                              <div class="col-md-12 col-12">
                                <div class="form-group mb-2">
                                  <label>{{ 'OPERATIONS_TABLE.NATURE' | translate }}</label>
                                  <ng-select [items]="filteredInterventionNatures" bindLabel="description"
                                    bindValue="id" formControlName="interventionNatureId"
                                    (change)="onInterventionNatureChange($event)"
                                    [placeholder]="'OPERATIONS_TABLE.SELECT_NATURE' | translate" [searchable]="true"
                                    [ngClass]="{'is-invalid': newOperationForm.get('interventionNatureId').invalid && notValidOperation,
                            'is-valid': newOperationForm.get('interventionNatureId').valid && newOperationForm.get('interventionNatureId').touched}">
                                  </ng-select>
                                  <div *ngIf="newOperationForm.get('interventionNatureId').invalid && notValidOperation"
                                    class="invalid-feedback">
                                    {{ 'INTERVENTION_NATURE_REQUIRED' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-12 col-12">
                                <div class="form-group mb-2">
                                  <label class="col-form-label">{{ 'DESCRIPTION' | translate }}</label>
                                  <textarea class="form-control" formControlName="operationDescription" rows="3"
                                    [ngClass]="{'is-invalid': newOperationForm.get('operationDescription').invalid && notValidOperation,
                                              'is-valid': newOperationForm.get('operationDescription').valid && newOperationForm.get('operationDescription').touched}"></textarea>
                                  <div *ngIf="newOperationForm.get('operationDescription').invalid && notValidOperation"
                                    class="invalid-feedback">
                                    {{ 'OPERATION_DESCRIPTION_REQUIRED' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <ng-container *ngIf="isDetailsView">
                              <div class="row">
                                <div class="col-md-6 col-12">
                                  <div class="form-group mb-2">
                                    <label>{{ 'OPERATIONS_TABLE.DATE' | translate }}</label>
                                    <input type="date" class="form-control" formControlName="workOrderDate"
                                      [ngClass]="{'is-valid': newOperationForm.get('workOrderDate').valid && newOperationForm.get('workOrderDate').touched}" />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6 col-12">
                                  <div class="form-group mb-2">
                                    <div class="custom-control custom-switch">
                                      <input type="checkbox" class="custom-control-input" id="immobilizeSwitch"
                                        formControlName="isImmobilized">
                                      <label class="custom-control-label" for="immobilizeSwitch">
                                        {{ 'OPERATIONS_TABLE.IMMOBILIZE_VEHICLE' | translate }}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" *ngIf="newOperationForm.get('isImmobilized').value">
                                <div class="col-md-12 col-12">
                                  <div class="form-group mb-2">
                                    <label>{{ 'OPERATIONS_TABLE.IMMOBILIZATION_REASON' | translate }}</label>
                                    <textarea class="form-control" formControlName="immobilizationReason" rows="2"
                                      placeholder="{{ 'OPERATIONS_TABLE.ENTER_IMMOBILIZATION_REASON' | translate }}">
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </ng-container>

                            <div class="actions">
                              <button type="button" class="btn btn-success" (click)="saveOperation()"
                                *ngIf="!isDetailsView || !operationHasDate">
                                {{ 'CONFIRM' | translate }}
                              </button>
                              <button type="button" class="btn btn-danger" (click)="cancelOperation()">
                                {{ 'CANCEL' | translate }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button type="button" class="btn btn-success" (click)="toggleAddOperation()"
                    *ngIf="!showAddOperation && formGroup.get('state').value !== 5"
                    [appHasPermission]="['manage_opertaions']">
                    <i class="fas fa-plus"></i> {{ 'ADD_OPERATION' | translate }}
                  </button>

                  <!-- Tableau des opérations -->
                  <div class="operations-table" *ngIf="operationsArray.length > 0">
                    <table>
                      <thead>
                        <tr>
                          <th>{{ 'OPERATIONS_TABLE.CATEGORY' | translate }}</th>
                          <th>{{ 'OPERATIONS_TABLE.MAINTENANCE_TYPE' | translate }}</th>
                          <th>{{ 'OPERATIONS_TABLE.NATURE' | translate }}</th>
                          <th>{{ 'OPERATIONS_TABLE.TYPE' | translate }}</th>
                          <th>{{ 'OPERATIONS_TABLE.DESCRIPTION' | translate }}</th>
                          <th>{{ 'OPERATIONS_TABLE.DATE' | translate }}</th>
                          <th>{{ 'OPERATIONS_TABLE.WORK_ORDER' | translate }}</th>
                          <th>{{ 'OPERATIONS_TABLE.STATUS' | translate }}</th>
                          <th>{{ 'OPERATIONS_TABLE.ACTIONS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let operation of operationsArray.controls; let i=index">
                          <td>{{ categoryName || '-' }}</td>
                          <td>{{ operation.value.nameTypeMaintenance || '-' }}</td>
                          <td>{{ operation.value.nameInterventionNature || '-' }}</td>
                          <td>{{ operation.value.nameTypeIntervention || '-' }}</td>
                          <td>{{ operation.value.operationDescription || '-' }}</td>
                          <td>{{ operation.value.workOrderDate ? (operation.value.workOrderDate | date:'dd/MM/yyyy') :
                            '-' }}</td>
                          <td>
                            <a *ngIf="operation.value.workOrderNumber"
                              (click)="navigateToWorkOrder(operation.value.workOrderId)"
                              class="text-primary cursor-pointer">
                              #{{ operation.value.workOrderNumber }}
                            </a>
                            <span *ngIf="!operation.value.workOrderNumber">-</span>
                          </td>
                          <td>
                            {{
                            operation.value.status == 1 ? ('OPERATION_STATUS.NOT_PLANNED' | translate) :
                            operation.value.status == 2 ? ('OPERATION_STATUS.PLANNED' | translate) :
                            operation.value.status == 3 ? ('OPERATION_STATUS.IN_PROGRESS' | translate) :
                            ('OPERATION_STATUS.CLOSED' | translate) || '-'
                            }}
                          </td>
                          <td>
                            <div class="position-relative d-flex align-items-center cursor-pointer">
                              <div ngbDropdown display="dynamic" class="d-inline-block" container="body">
                                <i class="fas fa-ellipsis-h mt-1 ml-4" id="chatOptions" ngbDropdownToggle></i>
                                <div ngbDropdownMenu aria-labelledby="chatOptions">
                                  <a (click)="viewOperationDetails(i)" class="dropdown-item"
                                    [appHasPermission]="['add_appointment']">
                                    {{ 'VIEW_DETAILS' | translate }}
                                  </a>
                                  <a (click)="editOperation(i)" class="dropdown-item"
                                    [appHasPermission]="['manage_opertaions']">
                                    {{ 'EDIT' | translate }}
                                  </a>
                                  <a class="dropdown-item danger" (click)="removeOperation(i)"
                                    [appHasPermission]="['manage_opertaions']">
                                    {{ 'DELETE' | translate }}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf=" formGroup.get('state').value === 3">
        <div class="card ">
          <div class="card-header">
            <h4 class="card-title">{{"REJECTION_REASON" | translate}}</h4>
          </div>
          <div class="card-body">

            <span class="text-danger">{{data.rejectionReason}} </span>

          </div>
        </div>
      </div>


    </div>

    <div class="modal-footer gap d-flex flex-wrap w-100">
      <button type="button" class="btn btn-primary" *ngIf="(formGroup.get('state').value === 1 && type !== 'details')"
        (click)="submitForm()">
        <i class="ft-check-square mr-1"></i>{{ 'CREATE_BTN'| translate }}
      </button>

      <!-- New buttons for Validate and Reject (only visible when state is 1) -->
      <ng-container>
        <button type="button" class="btn btn-success" *ngIf="formGroup.get('state').value === 1 && type === 'details'"
          (click)="validateWorkRequest()" [appHasPermission]="['validate_reject']"> <i class="ft-check-square mr-1"></i>
          {{ 'VALIDATE_BTN' | translate }}
        </button>
        <button type="button" class="btn btn-danger" *ngIf="formGroup.get('state').value === 1 && type === 'details'"
          (click)="ConfirmText()" [appHasPermission]="['validate_reject']">
          <i class="ft-x mr-1"></i> {{ 'REJECT_BTN' | translate }}
        </button>
      </ng-container>

      <button type="button" class="btn btn-secondary" *ngIf="formGroup.get('state').value === 1"
        (click)="activeModal.dismiss('cancel')">
        <i class="ft-x mr-1"></i> {{ 'CANCEL_BTN' | translate }}
      </button>
    </div>
  </div>
</div>