import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OPN_BASE_URL } from "../../../shared/global/var";
import { BehaviorSubject, Observable } from "rxjs";
import { CrudService } from "../../../shared/services/crud.service";
import { map, shareReplay, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CompanySettingsService {
  private settingsCache$: Observable<any[]> | null = null;
  private profileConfigSubject = new BehaviorSubject<any>(null);
  public profileConfig$ = this.profileConfigSubject.asObservable();

  private settingsSubject = new BehaviorSubject<any>({ logo: "" });
  settings$ = this.settingsSubject.asObservable();

  constructor(private http: HttpClient, private crudService: CrudService) {}

  update(newSettings: any): void {
    this.settingsSubject.next(newSettings);
  }

  updateSettings(settings: any): Observable<any> {
    return this.http.put(OPN_BASE_URL + "/company-settings/update", settings);
  }

  getCompanySettings() {
    const delayConstraints = {
      minorDelay: 0,
      majorDelay: 0,
      minorAdvancement: 0,
      majorAdvancement: 0,
    };
    this.crudService
      .getAll<any>(OPN_BASE_URL + "/company-settings/all")
      .subscribe(async (res: any) => {
        const settingsMap = res.map((item: any) => ({
          configKey: item.configKey,
          configValue: JSON.parse(item.configValue),
        }));

        const constraints = settingsMap.find(
          (item) => item.configKey === "constraint-config"
        );

        if (constraints) {
          switch (constraints.configValue.retard_mineur.unit) {
            case "min":
              delayConstraints["minorDelay"] =
                constraints.configValue.retard_mineur.value;
              break;
            case "hours":
              delayConstraints["minorDelay"] =
                constraints.configValue.retard_mineur.value * 60;
              break;
            case "days":
              delayConstraints["minorDelay"] =
                constraints.configValue.retard_mineur.value * 60 * 24;
              break;
            default:
              delayConstraints["minorDelay"] =
                constraints.configValue.retard_mineur.value;
          }
          switch (constraints.configValue.grand_retard.unit) {
            case "min":
              delayConstraints["majorDelay"] =
                constraints.configValue.grand_retard.value;
              break;
            case "hours":
              delayConstraints["majorDelay"] =
                constraints.configValue.grand_retard.value * 60;
              break;
            case "days":
              delayConstraints["majorDelay"] =
                constraints.configValue.grand_retard.value * 60 * 24;
              break;
          }

          switch (constraints.configValue.avancement_mineur.unit) {
            case "min":
              delayConstraints["minorAdvancement"] =
                constraints.configValue.avancement_mineur.value;
              break;
            case "hours":
              delayConstraints["minorAdvancement"] =
                constraints.configValue.avancement_mineur.value * 60;
              break;
            case "days":
              delayConstraints["minorAdvancement"] =
                constraints.configValue.avancement_mineur.value * 60 * 24;
              break;
            default:
              delayConstraints["minorAdvancement"] =
                constraints.configValue.avancement_mineur.value;
          }

          switch (constraints.configValue.grand_avancement.unit) {
            case "min":
              delayConstraints["majorAdvancement"] =
                constraints.configValue.grand_avancement.value;
              break;
            case "hours":
              delayConstraints["majorAdvancement"] =
                constraints.configValue.grand_avancement.value * 60;
              break;
            case "days":
              delayConstraints["majorAdvancement"] =
                constraints.configValue.grand_avancement.value * 60 * 24;
              break;
          }
        }
      });

    return delayConstraints;
  }

  getAllCompanySettings(): Observable<any[]> {
    if (!this.settingsCache$) {
      this.settingsCache$ = this.http
        .get<any[]>(`${OPN_BASE_URL}/company-settings/all`)
        .pipe(
          map((res) =>
            res.map((item) => ({
              configKey: item.configKey,
              configValue: JSON.parse(item.configValue),
            }))
          ),
          tap((settings) => {
            const profileConfig = settings.find(
              (item) => item.configKey === "profile-config"
            );
            if (profileConfig) {
              this.profileConfigSubject.next(profileConfig.configValue);
            }
          }),
          shareReplay(1)
        );
    }
    return this.settingsCache$;
  }

  clearCache() {
    this.settingsCache$ = null;
    this.profileConfigSubject.next(null);
  }
}
