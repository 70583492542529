<form [formGroup]="reportForm" (ngSubmit)="saveForm()" (keydown.enter)="$event.preventDefault()">
    <div class="modal-header">
        <h4 class="modal-title">{{"Report_Trip"|translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="d-flex font-medium-1 header-flex-group justify-content-start mb-3 gap-05">
            <!-- route -->
            <div class="badge badge-dark p-2 rounded-pill font-weight-bold">{{data.lineNumber}}</div>
            <!-- trip code -->
            <div class="badge badge-secondary rounded-pill p-2 font-weight-bold">
                <i class="fa-solid fa-bus mr-2"></i>{{data.lineDirection}}
            </div>
            <!-- trip route -->
            <div class="badge badge-secondary flex-grow-1 p-2 rounded-pill">{{ data.tripRoute }}</div>
            <!-- departure -->
            <div class="badge badge-light rounded-pill p-2 flex-grow-1">
                {{data.plannedDeparture | date :"dd/MM/yyyy"}} <b>{{data.plannedDeparture | date :"HH:mm"}}</b> <i
                    class="fa-solid mx-1 fa-arrow-right"></i>
                <b>{{data.estimatedArrival | date :"HH:mm"}}</b>
            </div>
            <!-- status -->
            <div
                class="align-items-center badge rounded-pill badge-light d-flex justify-content-center text-center p-2 ml-auto">
                <div class="status-pill mr-1"
                    [ngClass]="{'bg-secondary': data.status == '1', 'bg-info': data.status == '2', 'bg-warning': data.status == '3', 'bg-danger': data.status == '4', 'bg-success': data.status == '5' , 'bg-grey': data.status =='6'}">
                </div>
                {{ data.status == 1 ? ("NOT_ASSIGNED" | translate) : (data.status == 2 ? ("ASSIGNED" | translate) :
                (data.status == 3 ? ("IN_PROGRESS" | translate) : (data.status == 4 ?
                ("CANCELLED" | translate) : (data.status == 5 ? ("COMPLETED" | translate) : (data.status == 6 ?
                ("REPORTED" | translate) : ""))))) }}
            </div>
        </div>


        <div class="d-flex gap-05 flex-column bg-lighten-4 mt-2 mb-3 rounded">
            <div class="align-items-center bg-lighten-4 border d-flex gap-1 justify-content-center p-2 mb-2 rounded">

                <span class="p-2">
                    <i class="fa-solid fa-clock-rotate-left mr-1"></i> {{"REPORT_TO"|translate}}
                </span>

                <ngb-timepicker formControlName="plannedDeparture" spinners="true"></ngb-timepicker>


                <div class="align-items-center d-flex gap-05 justify-content-center p-2 rounded-pill"
                    [ngClass]="(data.driverAvailable == 1 || data.receiverAvailable == 1 || data.busAvailable == 1) ? 'bg-light-danger' : 'bg-light-grey'">

                    <span class="px-2 text-black-50">{{"ESTIMATED_ARRIVAL"|translate}}</span>

                    <span class="badge badge-secondary round px-2">{{ formattedEstimatedArrival | date
                        :"dd/MM/yyyy"}}</span>

                    <span class="badge badge-success round font-weight-bold px-2"
                        [class.badge-danger]="data.driverAvailable == 1 || data.receiverAvailable == 1 || data.busAvailable == 1">
                        {{ formattedEstimatedArrival | date :"HH:mm"}}
                    </span>

                    <div class="badge badge-secondary p-1 round d-flex align-items-center" *ngIf="data.driver"
                        [ngClass]="{'bg-danger': data.driverAvailable == 1,'bg-success': data.driverAvailable == 0}">
                        <i *ngIf="data.driverAvailable == 0" class="fa-solid fa-check"></i>
                        <i *ngIf="data.driverAvailable == 1" class="fa-solid fa-x"></i>
                        <img src="./assets/img/icons/driver.png" class="mx-1 icon-small">
                    </div>

                    <div class="badge badge-secondary p-1 round d-flex align-items-center" *ngIf="data.receiver "
                        [ngClass]="{'bg-danger': data.receiverAvailable == 1,'bg-success': data.receiverAvailable == 0}">
                        <i *ngIf="data.receiverAvailable == 0" class="fa-solid fa-check"></i>
                        <i *ngIf="data.receiverAvailable == 1" class="fa-solid fa-x"></i>
                        <img src="./assets/img/icons/cashier.png" class="mx-1 icon-small">
                    </div>

                    <div class="badge badge-secondary p-1 round d-flex align-items-center" *ngIf="data.bus "
                        [ngClass]="{'bg-danger': data.busAvailable == 1,'bg-success': data.busAvailable == 0}">
                        <i *ngIf="data.busAvailable == 0" class="fa-solid fa-check"></i>
                        <i *ngIf="data.busAvailable == 1" class="fa-solid fa-x"></i>
                        <img src="./assets/img/icons/buss.png" class="mx-1 icon-small">
                    </div>
                </div>

            </div>

            <!-- Overlapping Trips - tooltips -->
            <ng-template #popTitle1 let-tripNumber="tripNumber" let-tripRoute="tripRoute">
                <b>{{tripNumber}}</b> {{tripRoute}}
            </ng-template>
            <ng-template #popContent1 let-plannedDeparture="plannedDeparture" let-plannedArrival="plannedArrival">
                est. dep: <b> {{plannedDeparture | date :"HH:mm"}}</b>
                <br>
                est. arriv. <b> {{plannedArrival | date :"HH:mm"}}</b>
            </ng-template>

            <!-- driver unavailable -->
            <ngb-alert *ngIf="data.driverAvailable === 1" type="light-danger"
                class="d-flex align-items-center alert bg-light-danger alert-light-danger gap-05 mb-0"
                [dismissible]="false">
                <div class="badge bg-danger p-1 px-2 round d-flex align-items-center gap-05">
                    <span class="text-white"><i class="fa-solid fa-x"></i></span><img
                        src="./assets/img/icons/driver.png" class="icon-small">
                </div>
                <span>{{"REPORT_DRIVER_ERROR_MESG" | translate}}</span>
                <!-- overlappingDriverMissions -->
                <div class="d-flex justify-content-center gap-1">
                    <button *ngFor="let trip of overlappingDriverMissionData" type="button"
                        class="btn btn-danger px-2 py-1 rounded-pill" [ngbPopover]="popContent1"
                        [popoverTitle]="popTitle1" triggers="manual" #pDriver="ngbPopover"
                        (click)="toggleWithGreeting(pDriver,'Driver', trip.idTrip)">
                        {{trip.lineDirection}}<i class="fa-solid fa-triangle-exclamation ml-1"></i>
                    </button>
                </div>
            </ngb-alert>

            <!-- receiver unavailable -->
            <ngb-alert *ngIf="data.receiverAvailable === 1" type="light-danger"
                class="d-flex align-items-center alert bg-light-danger alert-light-danger gap-05 mb-0"
                [dismissible]="false">
                <div class="badge bg-danger p-1 px-2 round d-flex align-items-center gap-05">
                    <span class="text-white"><i class="fa-solid fa-x"></i></span><img
                        src="./assets/img/icons/cashier.png" class="icon-small">
                </div>
                <span *ngIf="data.receiverAvailable === 1"> {{"REPORT_RECEIVER_ERROR_MESG" | translate}}</span>
                <!-- overlappingDriverMission -->
                <div class="d-flex justify-content-center gap-1">
                    <button *ngFor="let trip of overlappingReceiverMissionData" type="button"
                        class="btn btn-danger px-2 py-1 rounded-pill" [ngbPopover]="popContent1"
                        [popoverTitle]="popTitle1" triggers="manual" #pReceiver="ngbPopover"
                        (click)="toggleWithGreeting(pReceiver,'Receiver', trip.idTrip)">
                        {{trip.lineDirection}}<i class="fa-solid fa-triangle-exclamation ml-1"></i>
                    </button>
                </div>
            </ngb-alert>

            <!-- bus unavailable -->
            <ngb-alert *ngIf="data.busAvailable === 1" type="light-danger"
                class="d-flex align-items-center alert bg-light-danger alert-light-danger gap-05 mb-0"
                [dismissible]="false">

                <div class="badge bg-danger p-1 px-2 round d-flex align-items-center gap-05 ">
                    <span class="text-white"><i class="fa-solid fa-x"></i></span><img src="./assets/img/icons/buss.png"
                        class="icon-small">
                </div>
                <span *ngIf="data.busAvailable === 1">{{"REPORT_BUS_ERROR_MESG" | translate}}</span>
                <!-- overlappingBusMission -->
                <div class="d-flex justify-content-center gap-1">
                    <button *ngFor="let trip of overlappingBusMissionData" type="button"
                        class="btn btn-danger px-2 py-1 rounded-pill" [ngbPopover]="popContent1"
                        [popoverTitle]="popTitle1" triggers="manual" #pBus="ngbPopover"
                        (click)="toggleWithGreeting(pBus,'Bus', trip.idTrip)">
                        {{trip.lineDirection}}<i class="fa-solid fa-triangle-exclamation ml-1"></i>
                    </button>
                </div>
            </ngb-alert>


        </div>
    </div>

    <div class="modal-footer">
        <button type="submit" class="btn btn-info" [disabled]="isSaveDisabled()">{{"SAVE"|translate}}</button>
        <button type="button" class="btn btn-danger" (click)="closeModal()">{{"Close"|translate}}</button>
    </div>
</form>