import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ExportService } from "../../services/export.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-refuels-modal",
  templateUrl: "./refuels-modal.component.html",
  styleUrls: ["./refuels-modal.component.scss"],
})
export class RefuelsModalComponent implements OnInit {
  refuelsList;
  @Input() refuels;
  @Input() refuellingNumber: string;
  @Input() refuellingType: number;

  headerRow: string[] = []; // Header row for CSV/Excel export

  filterRows: string[] = [];
  isExporting = false;

  constructor(
    public activeModal: NgbActiveModal,
    private exportService: ExportService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.headerRow = [
      "Vehicle",
      "Driver",
      "Quantity",
      "Kilometer_Index",
      "Time",
      "Fuel_Type",
      "avg_consumption",
    ];
    this.filterRows = ["Document"];
  }
  convertStringToDate(time: string): Date {
    if (!time) return new Date();
    const [hours, minutes] = time.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);
    return date;
  }

  async exportToExcel() {
    this.isExporting = true;
    if (this.isExporting) {
      const data = await this.prepareDataForExport();
      const filterData = await this.prepareFilterData();
      const headers = await this.exportService.getHeaders(this.headerRow);

      this.exportService.exportToExcel(
        "REFUELLING_LIST",
        filterData.selectedHeaderRow,
        filterData.filterData,
        headers,
        data
      );
      this.isExporting = false;
    }
  }

  async prepareDataForExport(): Promise<any[]> {
    return await Promise.all(
      this.refuels.refuels.map(async (refuel) => {
        return [
          refuel.vehicle?.vehicleNumber,
          refuel.driver?.matricule +
            " - " +
            refuel.driver?.firstName +
            " " +
            refuel.driver?.lastName,
          refuel.quantity,
          refuel.kilometerIndex,
          this.convertTimeFormat(refuel.time),
          refuel.fuelType,
          refuel.avgConsumption,
        ];
      })
    );
  }

  async prepareFilterData() {
    const filterData = [];
    const selectedHeaderRow = [];

    filterData.push(this.refuellingNumber);
    selectedHeaderRow.push("Number");

    if (this.refuellingType == 1) {
      const type = await this.exportService.getTranslation("EXTERNAL_FUELLING");
      filterData.push(type);
      selectedHeaderRow.push("REFUELLING_TYPE");
      filterData.push(this.refuels.externalStation);
      selectedHeaderRow.push("agency");
    } else {
      const type = await this.exportService.getTranslation("INTERNAL_FUELLING");
      filterData.push(type);
      selectedHeaderRow.push("REFUELLING_TYPE");
      filterData.push(this.refuels.agency?.name);
      selectedHeaderRow.push("agency");
    }
    filterData.push(this.refuels.date);
    selectedHeaderRow.push("Date");

    return { filterData, selectedHeaderRow };
  }

  convertTimeFormat(timeString: string): string {
    const date = this.convertStringToDate(timeString);
    return this.formatDate(date);
  }

  private formatDate(date: Date): string {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}h ${minutes}min`;
  }
}
