import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { catchError } from "rxjs/operators";
import { NotyService } from "../services/noty.service";
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { DeleteModalComponent } from "../modals/delete-modal/delete-modal.component";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  HTTP_ERRORS = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    TIMEOUT: 408,
    UNPROCESSABLE_ENTITY: 422,
  };

  constructor(
    private authenticationService: AuthService,
    private notyService: NotyService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        let message = "";
        const redirectUrl = "/";

        const lang = localStorage.getItem("langue") || "en";

        const getMessage = (enMessage: string, frMessage: string): string => {
          return lang === "fr" ? frMessage : enMessage;
        };

        switch (err.status) {
          case this.HTTP_ERRORS.UNAUTHORIZED:
            message = getMessage(
              "You are not authorized. Please login.",
              "Vous n'êtes pas autorisé. Veuillez vous connecter."
            );
            this.authenticationService.logout();
            break;
          case this.HTTP_ERRORS.BAD_REQUEST:
            this.handle400Error(err);
            break;
          case this.HTTP_ERRORS.FORBIDDEN:
            this.handle403Error(request.url);
            break;
          case this.HTTP_ERRORS.UNPROCESSABLE_ENTITY:
            for (const field in err.error.errors) {
              if (err.error.errors[field]) {
                message = getMessage(
                  err.error.errors[field],
                  err.error.errors[field]
                );
                this.notyService.displayNotification(message, "error");
              }
            }
            break;
          default:
          // if (err.status.toString()[0] === "5" && !request.url.startsWith('/reverse')) {
          //   message = getMessage(
          //     err.error.message
          //       ? err.error.message
          //       : "Internal Server Error.",
          //     err.error.message
          //       ? err.error.message
          //       : "Erreur interne du serveur."
          //   );
          //   this.notyService.displayNotification(message, "error");
          // }
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

  private async handle403Error(url: string): Promise<void> {
    try {
      const resourceInfo = this.extractResourceInfo(url);
      const lang = localStorage.getItem("langue") || "en";

      const message =
        lang === "fr"
          ? `Vous n'avez pas les droits d'accès à la liste des ${resourceInfo.resource}`
          : `You do not have access rights to the list of ${resourceInfo.resource}`;

      this.notyService.displayNotification(message, "error");
    } catch (e) {
      const lang = localStorage.getItem("langue") || "en";
      const fallbackMessage =
        lang === "fr"
          ? "Vous n'avez pas les droits d'accès à cette ressource"
          : "You do not have access rights to this resource";

      this.notyService.displayNotification(fallbackMessage, "error");
    }
  }

  private handle400Error(error: HttpErrorResponse): void {
    const lang = localStorage.getItem("langue") || "en";
    let message = "";

    if (typeof error.error === "string") {
      const errorKey = this.extractErrorKeyFromMessage(error.error);
      message =
        DeleteModalComponent.getErrorMessageForKey(errorKey, lang) ||
        error.error;
    } else if (error.error?.message) {
      const errorKey =
        error.error.code ||
        this.extractErrorKeyFromMessage(error.error.message);
      message =
        DeleteModalComponent.getErrorMessageForKey(errorKey, lang) ||
        error.error.message;
    } else {
      const errorKey = "defaultError";
      message = DeleteModalComponent.getErrorMessageForKey(errorKey, lang);
    }

    this.notyService.displayNotification(message, "error");
  }

  private extractErrorKeyFromMessage(message: string): string {
    const errorMapping = [
      { key: "deleteRouteError", keywords: ["Route", "associated Trips"] },
      {
        key: "deleteServiceGroupError",
        keywords: ["Service Group", "associated Trips"],
      },
      { key: "deleteAgencyError", keywords: ["Agency", "associated routes"] },
      { key: "deleteCenterError", keywords: ["Center", "associated Agency"] },
      {
        key: "deleteRouteTypeError",
        keywords: ["Route Type", "associated routes"],
      },
      {
        key: "deleteVehicleBrandError",
        keywords: ["vehicle brand", "associated vehicle models"],
      },
      {
        key: "deleteBusTypeError",
        keywords: ["bus type", "associated vehicle"],
      },
      {
        key: "deleteVehicleError",
        keywords: ["vehicle", "associated trip instances"],
      },
      {
        key: "deleteVehicleFamilyError",
        keywords: ["vehicle family", "associated vehicles"],
      },
      {
        key: "deleteVehicleModelError",
        keywords: ["vehicle model", "associated vehicles"],
      },
      { key: "deleteStaffError", keywords: ["staff", "associated trip"] },
      {
        key: "deleteStaffTypeError",
        keywords: ["staff type", "associated staff"],
      },
      {
        key: "deleteInterventionError",
        keywords: ["intervention", "associated work orders"],
      },
      {
        key: "deleteInterventionCategoryError",
        keywords: ["Intervention Category", "associated maintenance types"],
      },
      {
        key: "deleteInterventionTypeError",
        keywords: ["intervention type", "associated interventions"],
      },
      {
        key: "deleteMaintenanceTypeError",
        keywords: ["Maintenance Type", "associated scheduled tasks"],
      },
      {
        key: "deleteWorkDiaryError",
        keywords: ["work diary", "associated intervention(s)"],
      },
      {
        key: "deleteStandardError",
        keywords: ["standard", "associated vehicles"],
      },
      {
        key: "deleteStationError",
        keywords: ["Station", "associated itineraries"],
      },
      { key: "deleteUserError", keywords: ["user", "associated inscriptions"] },
    ];

    for (const mapping of errorMapping) {
      if (mapping.keywords.every((kw) => message.includes(kw))) {
        return mapping.key;
      }
    }
    return "defaultError";
  }

  private extractResourceInfo(url: string): { resource: string } {
    try {
      const apiPath = url.split("/api/")[1];
      if (!apiPath) throw new Error("Invalid API path");
      const segments = apiPath.split("/").filter(Boolean);
      let resourceSegment = "";
      for (let i = segments.length - 1; i >= 0; i--) {
        if (segments[i] && segments[i] !== "list") {
          resourceSegment = segments[i].split(/[^a-zA-Z]/)[0];
          break;
        }
      }
      const resource = this.formatResourceName(resourceSegment);
      return { resource };
    } catch (error) {
      return { resource: "ressources" };
    }
  }
  private formatResourceName(name: string): string {
    const frenchTerms: { [key: string]: string } = {
      vehicles: "véhicules",
      stations: "stations",
      route: "routes",
    };
    const cleanName = name.toLowerCase();
    return frenchTerms[cleanName] || name.replace(/-|_/g, " ").toLowerCase();
  }
}
