<div class="timeline-container">
  <button class="nav-button prev" [class.disabled]="isAtTop" [disabled]="isAtTop" (click)="scrollUp()">
    <i class="arrow up"></i>
  </button>

  <div class="timeline-container">
    <div class="timeline-content" #timelineContent>
      <div class="timeline" [@scrollAnimation]="scrollDirection" (@scrollAnimation.done)="onAnimationDone()">
        <div class="timeline-event" *ngFor="let event of displayedEvents; let i = index" [style.marginTop]="event.spacing + 'px'">
          <div class="start-point" *ngIf="event.isStart">
            <div class="timeline-bullet start"></div>
            <div class="timeline-details start">
              <div class="start-label">{{event.route}}</div>
            </div>
          </div>
  
          <div *ngIf="!event.isStart">
            <div class="timeline-bullet"></div>
            <div class="timeline-details">
              <div class="event-info">
                  <div class="route" *ngIf="event.startTime && event.endTime">{{event.startTime}} → {{ event.endTime }}</div>
                  <div class="line" *ngIf="event.line">{{'TRIP'| translate}}: {{ event.line }}</div>
                  <div class="line" *ngIf="event.interventionType">{{'INTERVENTION_TYPE'| translate}}: {{ event.interventionType }}</div>
                    <div class="route" *ngIf="event.route">{{'ROUTE'| translate}}: {{ event.route }}</div>
                     <div class="route" *ngIf="event.interventionNature">{{'INTERVENTION_NATURE'| translate}}: {{ event.interventionNature }}</div>
                    <div *ngIf="event.plannedDeparture"> {{'DEPART'| translate}}:
                          <span *ngIf="event.rtDeparture" class="font-weight-bold font-medium-1 mr-1 ">
                            {{ event.rtDeparture | date:' H\'h\' m\'min\'' }}
                          </span>
                            <span *ngIf="!event.rtDeparture" class="font-weight-bold font-medium-1 mr-1 ">
                            <abbr title="{{ 'NotDefined' | translate }}" class="initialism">--
                            </abbr>
                          </span>
                      <span class="font-small-2 text-nowrap">/ prév. {{ event.plannedDeparture | date:'H\'h\'m\'min\''}}</span>
                  </div>
                  <div *ngIf="event.plannedArrival">{{"ARRIVEE" | translate}}:
                          <span *ngIf="event.rtArrival" class="font-weight-bold font-medium-1 mr-1 ">
                            {{ event.rtArrival | date:' H\'h\' m\'min\'' }}
                          </span>
                            <span *ngIf="!event.rtArrival" class="font-weight-bold font-medium-1 mr-1 ">
                            <abbr title="{{ 'NotDefined' | translate }}" class="initialism">--
                            </abbr>
                          </span>
                      <span class="font-small-2 text-nowrap">/ prév. {{ event.plannedArrival | date:'H\'h\'m\'min\''}}</span>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button class="nav-button next" [class.disabled]="isAtBottom" [disabled]="isAtBottom" (click)="scrollDown()">
    <i class="arrow down"></i>
  </button>
</div>
