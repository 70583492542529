import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CrudService } from "./crud.service";
import { OPN_BASE_URL } from "../global/var";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import autoTable from "jspdf-autotable";
import jspdf from "jspdf";
@Injectable({
  providedIn: "root",
})
export class ExportService {
  profileConfig: any;

  constructor(
    private translate: TranslateService,
    private crudService: CrudService
  ) {}

  public getTranslation(word: string): Promise<string> {
    if (!word) {
      return Promise.resolve("");
    }
    return new Promise((resolve, reject) => {
      this.translate.get(word).subscribe({
        next: (translation: string) => resolve(translation),
        error: (err) => reject(err),
      });
    });
  }

  public async getHeaders(headers: string[]): Promise<string[]> {
    return await Promise.all(
      headers.map((field) => this.getTranslation(field))
    );
  }
  async exportToCSV(
    listName: string,
    headers: string[],
    data: any[],
    filterHeader: string[],
    filterData: any[] = []
  ) {
    const filterTranslation = await this.getTranslation("APPLIED_FILTERS");

    // Initialize CSV data with filter information
    let csvData = filterTranslation + "\n";
    filterHeader.length === 0
      ? (csvData += filterTranslation + ",N/A\n")
      : (csvData += filterTranslation + "\n");
    const filterHeaderTranslation = await this.getHeaders(filterHeader);
    csvData = this.convertToCSV([filterData], filterHeaderTranslation);
    csvData += "\n";
    const ExportationDateTranslation = await this.getTranslation(
      "EXPORTATION_DATE"
    );
    const listTranslation = await this.getTranslation(listName);
    csvData +=
      ExportationDateTranslation + "," + new Date().toLocaleString() + "\n";
    csvData += "\n";
    const headersTranslation = await this.getHeaders(headers);
    csvData += this.convertToCSV(data, headersTranslation);
    const blob = new Blob([csvData], { type: "text/csv" });
    saveAs(blob, listTranslation + ".csv");
  }

  convertToCSV(data: any, headers: any) {
    const array = [headers, ...data];
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index = 0; index < array[i].length; index++) {
        if (line !== "") {
          line += ",";
        }
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  async getBase64ImageFromUrl(imageUrl: string) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  exportToExcel(
    listName: string,
    filterHeader: string[],
    filterData: any[],
    headers: string[],
    data: any[],
    reportName = null,
    aggregationFields = [],
    aggregationValues = []
  ) {
    this.crudService
      .getAll<any>(OPN_BASE_URL + "/company-settings/all")
      .subscribe(async (res: any) => {
        const settingsMap = res.map((item: any) => ({
          configKey: item.configKey,
          configValue: JSON.parse(item.configValue),
        }));

        this.profileConfig = settingsMap.find(
          (item) => item.configKey === "profile-config"
        );
        let logoBase64 = this.profileConfig.configValue.logo;
        const acro = this.profileConfig.configValue.acronym;
        const address =
          this.profileConfig.configValue.name +
          "\n" +
          this.profileConfig.configValue.address;
        if (!logoBase64 || logoBase64 === "") {
          this.getBase64ImageFromUrl("/assets/img/bus-svgrepo-com.png")
            .then((result) => {
              logoBase64 = result;
              const backroundColor = "e2e6ea";
              this.saveExcel(
                logoBase64,
                acro,
                address,
                backroundColor,
                listName,
                filterHeader,
                filterData,
                headers,
                data,
                reportName,
                aggregationFields,
                aggregationValues
              );
            })
            .catch((err) => console.error(err));
        } else {
          await this.saveExcel(
            logoBase64,
            acro,
            address,
            "e2e6ea",
            listName,
            filterHeader,
            filterData,
            headers,
            data,
            reportName,
            aggregationFields,
            aggregationValues
          );
        }
      });
  }

  private async saveExcel(
    logoBase64,
    acro,
    address: string,
    logoBackgroundColor = "ffffff",
    listName = "list",
    filterHeader = [],
    filterData = [],
    headers = [],
    data = [],
    reportName,
    aggregationFields = [],
    aggregationValues = []
  ) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add the logo image
    const imageId = workbook.addImage({
      base64: logoBase64,
      extension: "png", // or 'jpeg' if your image is a JPEG
    });

    // Add the logo image to the worksheet at the center of A1:B7
    worksheet.addImage(imageId, {
      tl: { col: 1.2, row: 1 },
      ext: { width: 150, height: 100 }, // Adjust the size as needed
    });

    worksheet.getCell("A8").value = acro;
    // make acro bold and font cam
    worksheet.getCell("A8").font = { bold: true, size: 14 };
    worksheet.mergeCells("A8:B8"); // Adjust cell range as needed
    // center the acro A6:B6
    worksheet.getCell("A8").alignment = { horizontal: "center" };
    worksheet.getCell("A8").border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    // Merge cells for the logo and address
    worksheet.mergeCells("A1:B7"); // Adjust cell range as needed
    // add background color to the A1:B5
    worksheet.getCell("A1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: logoBackgroundColor },
    };
    // center the logo
    worksheet.getCell("A1").alignment = {
      horizontal: "center",
      vertical: "middle",
    };

    // add border to the A1:B6
    worksheet.getCell("A1").border = {
      top: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
      bottom: { style: "dashed" },
    };

    worksheet.getCell("C1").value = address;
    worksheet.mergeCells("C1:F8"); // Adjust cell range as needed
    // add border to the C1:F5
    worksheet.getCell("C1").border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    // put the address in the top left
    worksheet.getCell("C1").alignment = { vertical: "top", horizontal: "left" };

    // Add an empty row below the merged cells
    worksheet.addRow([]);
    worksheet.addRow([]);

    const title = reportName ? reportName : listName;
    const titleTranslation = await this.getTranslation(title);
    worksheet.addRow([titleTranslation]);
    // add border to the list title
    worksheet.addRow([]);
    worksheet.addRow([]);

    const filterTranslation = await this.getTranslation("APPLIED_FILTERS");
    filterHeader.length === 0
      ? worksheet.addRow([])
      : worksheet.addRow([filterTranslation]);
    const filterHeaders = await this.getHeaders(filterHeader);
    for (let i = 0; i < filterHeaders.length; i++) {
      if (
        !filterData[i] ||
        filterData[i].trim() === "N/A" ||
        filterData[i].trim() === ""
      ) {
        if (filterHeaders[i].toLowerCase().includes("date")) {
          const unspecifiedTranslation = await this.getTranslation(
            "UNSPECIFIED"
          );
          worksheet.addRow([filterHeaders[i], unspecifiedTranslation]);
        } else {
          const allTranslation = await this.getTranslation("ALL");
          worksheet.addRow([
            filterHeaders[i],
            `${allTranslation}`,
          ]);
        }
      } else {
        worksheet.addRow([filterHeaders[i], filterData[i]]);
      }
      // add border to the filter header
      worksheet.getCell("A" + (i + 11)).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell("B" + (i + 11)).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    }

    worksheet.addRow([]);
    worksheet.addRow([]);
    const ExportationDateTranslation = await this.getTranslation(
      "EXPORTATION_DATE"
    );
    const listTranslation = await this.getTranslation(listName);
    worksheet.addRow([ExportationDateTranslation, new Date().toLocaleString()]);
    worksheet.addRow([]);
    worksheet.addRow([]);

    // Add the rest of the data
    // const fields = data.map((field) => field.name);
    this.getHeaders(headers).then(async (h) => {
      // Add the headers and color the header green consider the header after the filter
      worksheet.addRow(h);
      const headerRowNumber = 17 + filterHeader.length;
      worksheet
        .getRow(headerRowNumber)
        .eachCell({ includeEmpty: true }, (cell, colNumber) => {
          if (colNumber <= headers.length) {
            // Apply fill only to header cells
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "90EE90" },
            };
          }
        });

      worksheet.addRows(
        data.map((row) => row.map((cell) => (cell == null ? "" : cell))) // Replace null/undefined with ""
      );

      worksheet.addRow([]);
      worksheet.addRow([]);

      if (aggregationFields.length > 0 && aggregationValues.length > 0) {
        const aggregationTranslation = await this.getTranslation("AGGREGATION");
        worksheet.addRow([]);
        worksheet.addRow([aggregationTranslation]);
        for (let i = 0; i < aggregationFields.length; i++) {
          if(aggregationValues[i]){
            const aggregationFieldsTranslation = await this.getTranslation(
                aggregationFields[i]
            );
            worksheet.addRow([
              aggregationFieldsTranslation,
              aggregationValues[i],
            ]);
          }
        }
      }

      worksheet.addRow([]);
      worksheet.addRow([]);

      // Auto-size columns
      worksheet.columns.forEach((column) => {
        column.width = 22; // Adjust as necessary
      });

      // Apply borders
      worksheet.eachRow((row) => {
        row.eachCell({ includeEmpty: true }, (cell) => {
          cell.border = {
            top: { style: "medium" },
            left: { style: "medium" },
            bottom: { style: "medium" },
            right: { style: "medium" },
          };
        });
      });

      const excelBuffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, listTranslation + ".xlsx");
    });
  }

  async exportToPDF(
    listName: string,
    filterHeaders: string[],
    filterValues: any[],
    headers: string[],
    data: any[],
    aggregationFields = [],
    aggregationValues = []
  ) {
    try {
      // Fetch translations for the headers
      const translatedHeaders = await Promise.all(
        headers.map((header) => this.getTranslation(header.toUpperCase()))
      );
      // translated headers to lower case
      const translatedHeadersLowerCase = translatedHeaders.map((header) =>
        header.toLowerCase()
      );
      const filterHeaderTranslation = await this.getHeaders(filterHeaders);
      const listNameTranslation = await this.getTranslation(listName);

      // Retrieve company settings and logo
      const res = await this.crudService
        .getAll<any>(`${OPN_BASE_URL}/company-settings/all`)
        .toPromise();
      const settingsMap = res.map((item: any) => ({
        configKey: item.configKey,
        configValue: JSON.parse(item.configValue),
      }));

      const profileConfig = settingsMap.find(
        (item) => item.configKey === "profile-config"
      );
      const logoBase64 =
        profileConfig?.configValue.logo ||
        (await this.getBase64ImageFromUrl("/assets/img/bus-svgrepo-com.png"));
      const acro = profileConfig?.configValue.acronym || "";
      const address = `${profileConfig?.configValue.name}\n${profileConfig?.configValue.address}`;

      // Initialize jsPDF
      const doc = new jspdf({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
      });
      const margin = 10;
      let yPosition = margin;

      // Add Logo
      if (logoBase64) {
        doc.addImage(logoBase64, "PNG", margin, yPosition, 30, 30);
        yPosition += 35;
      }

      // Add Company Acronym and Address
      doc.setFontSize(14);
      doc.text(acro, margin, yPosition);
      yPosition += 8;
      doc.setFontSize(10);
      doc.text(address, margin, yPosition);
      yPosition += 15;

      // Add List Title
      doc.setFontSize(16);
      doc.text(listNameTranslation, margin, yPosition);
      yPosition += 10;

      // Add Filters
      const filterTranslation = await this.getTranslation("APPLIED_FILTERS");
      doc.setFontSize(14);
      doc.text(filterTranslation, margin, yPosition);
      yPosition += 8;

      doc.setFontSize(12);
      for (let i = 0; i < filterHeaderTranslation.length; i++) {
        const header = filterHeaderTranslation[i];
        const value = filterValues[i]?.trim();
        let displayValue;

        if (!value || value === "N/A" || value === "") {
          if (header.toLowerCase().includes("date")) {
            displayValue = await this.getTranslation("UNSPECIFIED");
          } else {
            displayValue = await this.getTranslation("ALL");
          }
        } else {
          displayValue = value;
        }

        doc.text(`${header.toLowerCase()}: ${displayValue}`, margin, yPosition);
        yPosition += 8;
      }

      yPosition += 5;

      // Add Exportation Date
      const exportationDateTranslation = await this.getTranslation(
        "EXPORTATION_DATE"
      );
      doc.text(
        `${exportationDateTranslation}: ${new Date().toLocaleString()}`,
        margin,
        yPosition
      );
      yPosition += 10;

      // Add Aggregations
      if (aggregationFields.length > 0 && aggregationValues.length > 0) {
        const aggregationTranslation = await this.getTranslation(
          "AGGREGATION"
        );
        doc.setFontSize(14);
        doc.text(aggregationTranslation, margin, yPosition);
        yPosition += 8;
        for (let i = 0; i < aggregationFields.length; i++) {
          if(aggregationValues[i]){
            const fieldTranslation = await this.getTranslation(
                aggregationFields[i]
            );
            const value = aggregationValues[i];
            doc.text(`${fieldTranslation}: ${value}`, margin, yPosition);
            yPosition += 8;
          }
        }
      }
      yPosition += 10;

      // Add Table
      autoTable(doc, {
        head: [translatedHeadersLowerCase],
        body: data,
        startY: yPosition,
        margin: { top: 10 },
      });

      // Save PDF
      doc.save(`${listNameTranslation}.pdf`);
    } catch (error) {
      console.error("Error exporting to PDF:", error);
    }
  }
}
