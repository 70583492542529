import { CrudService } from "app/shared/services/crud.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  BASE_API_STAFF,
  BASE_URL_FLEET,
  M_BASE_URL,
} from "app/shared/global/var";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  NgbActiveModal,
  NgbDateStruct,
  NgbModal,
  NgbTimeStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { WorkDiaryComponent } from "../work-diary/work-diary.component";
import { AuthService } from "app/shared/auth/auth.service";

@Component({
  selector: "app-intervention-form",
  templateUrl: "./intervention-form.component.html",
  styleUrls: ["./intervention-form.component.scss"],
})
export class InterventionFormComponent implements OnInit {
  @Input() itemId;
  @Input() linkedOperation;
  @Input() item;
  @Input() vehicleDistance;
  @Input() interventionLength;
  @Output() listUpdated: EventEmitter<void> = new EventEmitter<void>();
  @Output() interventionAdded = new EventEmitter<void>();
  @Input() viewMode: boolean = false;
  @Input() completeMode: boolean = false;
  operator: any;
  sparePart: any;
  selectedOperators: any[] = [];
  selectedSparePart: any[] = [];
  submitted = false;
  operatorError = false;
  totalDuration: number;
  currentOperatorId: number;
  vehicles: any;

  operatorForm!: FormGroup;

  showOperatorForm = false;
  showProviderForm = false;
  showSparePartForm = false;
  isEditMode = false;
  username: string;
  interventionTypes: any;
  interventionNatures: any;
  interventionNaturesFiltred: any;

  availableOperators: any[] = [];
  filteredSpareParts: any[] = [];
  sparePartSearchTerm: string = "";
  sparePartList: any[] = [];

  selectedTypeInterventionType = null;

  constructor(
    private crudService: CrudService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private authservice: AuthService
  ) {
    this.username = this.authservice.getUsernameFromToken();
  }

  /**
   * Initializes the component and checks if it is in edit mode.
   * Calls methods to initialize the forms and fetch the list of operators.
   */

  ngOnInit(): void {
    this.getInterventionList();
    this.initForms();
    this.getOperatorList();
    this.getVehicles();
    this.fetchInterventionTypes();
    this.fetchInterventionNatures();
    if (this.item) {
      this.isEditMode = true;
      this.loadInterventionData();
    }

    if (this.viewMode) {
      // Disable all form controls except conformity fields
      Object.keys(this.operatorForm.controls).forEach((key) => {
        if (key !== "conformity" && key !== "noConformityReason") {
          this.operatorForm.get(key)?.disable();
        }
      });
    }
    const conformityValue = this.operatorForm.get("conformity")?.value;
    if (conformityValue === 2) {
      this.operatorForm.get("conformity")?.disable();
    } else if (conformityValue === 3) {
      this.operatorForm.get("conformity")?.disable();
      this.operatorForm.get("noConformityReason")?.disable();
    }
  }

  getInterventionList() {
    this.crudService
      .getAll<any>(M_BASE_URL + "/sparePart")
      .subscribe((data) => {
        // Transformez les données pour ng-select
        this.sparePartList = data.map((sparePart) => ({
          id: sparePart.id, // ID de la pièce existante
          name: sparePart.name, // Nom de la pièce
        }));

        if (this.item) {
          this.loadInterventionData();
        }
      });
  }

  /**
   * Initializes the operator form with required form controls and validation rules.
   */

  initForms() {
    const initialKilometers = this.vehicleDistance
      ? parseInt(this.vehicleDistance)
      : null;
    this.operatorForm = this.fb.group({
      date: [null, Validators.required],
      time: [null, Validators.required],
      supervisor: [""],
      duration: ["0h 0m"],
      diagnostic: [""],
      type: [1],
      status: [""],
      conformity: [1],
      noConformityReason: [""],
      provider: [""],
      oilQuantity: [null, [Validators.min(0)]],
      indexKilometers: [initialKilometers, [Validators.min(0)]],
      interventionTypeId: [
        this.linkedOperation.typeInterventionId,
        Validators.required,
      ],
      interventionNatureId: [
        this.linkedOperation.natureInterventionId,
        Validators.required,
      ],
      observation: [""],
      selectedOperator: [null],
      selectedSparePart: [null],
      sparePart: [""],
      sparePartId: [""],
      quantity: [""],
      state: [1],
      selectedVehicleId: [""],
      selectedVehicle: [""],
    });

    if (this.viewMode && this.operatorForm.get("type")?.value === 0) {
      this.operatorForm.get("duration")?.disable();
    }

    this.operatorForm.get("type")?.valueChanges.subscribe((value) => {
      const durationControl = this.operatorForm.get("duration");
      if (value === 1) {
        // Internal intervention
        durationControl?.disable();
        durationControl?.setValue(this.calculateTotalDuration());
      } else if (value === 0 && this.viewMode) {
        // External intervention
        durationControl?.disable();
      } else {
        durationControl?.enable();
      }
    });
  }

  formatDuration(value: string) {
    if (this.operatorForm.get("type")?.value === 0) {
      // Only process for external interventions
      let hours = 0;
      let minutes = 0;

      // Parse the input value
      const match =
        value.match(/(\d+)h\s*(\d+)m/) || value.match(/(\d+):(\d+)/);
      if (match) {
        hours = parseInt(match[1]) || 0;
        minutes = parseInt(match[2]) || 0;
      } else {
        // Try to parse as number of minutes
        const totalMinutes = parseInt(value);
        if (!isNaN(totalMinutes)) {
          hours = Math.floor(totalMinutes / 60);
          minutes = totalMinutes % 60;
        }
      }

      // Format and set the value
      const formattedDuration = `${hours}h ${minutes}m`;
      this.operatorForm.patchValue({ duration: formattedDuration });
    }
  }

  /**
   * Getter method for easy access to the form controls in the template.
   */

  get o() {
    return this.operatorForm.controls;
  }

  /**
   * Loads existing intervention data into the form if the component is in edit mode.
   * Maps the technician data to populate the selected operators.
   */

  loadInterventionData() {
    if (this.item) {
      const date = new Date(this.item.date);
      const kilometers =
        this.item.indexKilometers ||
        (this.vehicleDistance ? parseInt(this.vehicleDistance) : null);
      this.operatorForm.patchValue({
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        },
        time: { hour: date.getHours(), minute: date.getMinutes() },
        supervisor: this.item.supervisor,
        duration: this.item.duration,
        diagnostic: this.item.diagnostic,
        oilQuantity: this.item.oilQuantity,
        indexKilometers: kilometers,
        observation: this.item.observation,
        type: this.item.type,
        noConformityReason: this.item.noConformityReason,
        conformity: this.item.conformity,
        status: this.item.status,
        provider: this.item?.provider,
        interventionTypeId: this.item.interventionTypeId,
        interventionNatureId: this.item.interventionNatureId,
      });

      if (this.item.techniciens && Array.isArray(this.item.techniciens)) {
        this.selectedOperators = this.item.techniciens.map((tech: any) => ({
          id: tech.id,
          matricule: tech.matricule,
          firstName: tech.firstName,
          lastName: tech.lastName,
          totalWorkDuration: 0,
        }));
      }

      if (this.item.spareParts && Array.isArray(this.item.spareParts)) {
        this.selectedSparePart = this.item.spareParts.map((part: any) => {
          const sparePart = this.sparePartList.find(
            (sp) => sp.id === part.sparePartId
          );

          const vehicle = this.vehicles?.find((v) => v.id === part.vehicleId);

          return {
            id: part.id,
            sparePartId: part.sparePartId,
            sparePartName: part?.name || "_",
            quantity: part.quantity,
            state: part.state,
            vehicleId: part.vehicleId,
            vehicleNumber: vehicle?.vehicleNumber || "_",
          };
        });
      }
      if (this.item.type === 0) {
        // Pour les interventions externes, utiliser directement la durée
        this.operatorForm.patchValue({
          duration: this.item.duration,
        });
      } else {
        // Fetch work diaries for the current intervention
        const fetchPromises = this.selectedOperators.map((operator) =>
          this.fetchWorkDiaries(operator.id, this.item.id).then(
            (workDiaries) => {
              // Sum durations for each operator
              workDiaries.forEach((diary) => {
                operator.totalWorkDuration += Number(diary.duration);
              });
              // Convert total duration into hours and minutes
              operator.totalWorkDuration = `${Math.floor(
                operator.totalWorkDuration / 60
              )}h ${operator.totalWorkDuration % 60}m`;
            }
          )
        );

        // Wait for all fetch operations to complete
        Promise.all(fetchPromises).then(() => {
          const totalDuration = this.calculateTotalDuration();
          this.operatorForm.patchValue({ duration: totalDuration });
        });
      }
    }
  }

  // Function to fetch work diaries
  fetchWorkDiaries(operatorId: number, interventionId: number): Promise<any[]> {
    const url = `${M_BASE_URL}/workDiary/${operatorId}/${interventionId}`;
    return this.crudService.getAll(url).toPromise() as Promise<any[]>;
  }

  /**
   * Closes the current modal window.
   */
  closeModal() {
    this.activeModal.close();
  }

  /**
   * Updates the form's date field when a date is selected.
   * @param date The selected date from the datepicker.
   */

  onDateSelect(date: NgbDateStruct) {
    this.operatorForm.patchValue({ date });
  }

  /**
   * Updates the form's time field when the time is changed.
   * @param time The selected time from the timepicker.
   */

  onTimeChange(time: NgbTimeStruct) {
    this.operatorForm.patchValue({ time });
  }

  /**
   * Retrieves the list of operators from the API and filters them based on the job.
   */

  getOperatorList() {
    const url = `${BASE_API_STAFF}/staff?username=${encodeURIComponent(
      this.username
    )}`;
    return this.crudService.getAll(url).subscribe((data: any) => {
      this.operator = data.data;
      this.operator = this.operator.filter((applicant) =>
        applicant.job.includes(3)
      );
      this.operator.forEach((op) => {
        op.fullName = `${op.firstName} ${op.lastName}`;
      });

      // Initialisez les opérateurs disponibles
      this.updateAvailableOperators();
    });
  }

  updateAvailableOperators() {
    this.availableOperators = this.operator.filter(
      (op) => !this.selectedOperators.some((selected) => selected.id === op.id)
    );
  }

  /**
   * Displays the operator form to add a new operator.
   */

  addOperator() {
    if (this.viewMode) return;
    this.showOperatorForm = true;
  }

  /**
   * Cancels adding a new operator, hides the form, and resets the form.
   */

  cancelAddOperator() {
    this.showOperatorForm = false;

  }

  /**
   * Saves the selected operator into the list of selected operators.
   * Hides the operator form after saving.
   */

  saveOperator() {
    const selectedOperatorId = this.operatorForm.get("selectedOperator")?.value;
    const selectedOperator = this.operator.find(
      (op) => op.id === selectedOperatorId
    );

    if (selectedOperator) {
      this.selectedOperators.push({
        id: selectedOperator.id,
        matricule: selectedOperator.matricule,
        firstName: selectedOperator.firstName,
        lastName: selectedOperator.lastName,
        totalWorkDuration: this.totalDuration,
      });

      this.showOperatorForm = false;
      this.operatorError = false;
      this.operatorForm.patchValue({
        selectedOperator: null,
        duration: this.calculateTotalDuration(),
      });
    }
    this.updateAvailableOperators();
  }

  /**
   * Removes an operator from the list of selected operators.
   * @param operator The operator to be removed.
   */

  removeOperator(operator: any) {
    if (this.viewMode) return;
    this.selectedOperators = this.selectedOperators.filter(
      (op) => op.id !== operator.id
    );

    // Mettez à jour la liste des opérateurs disponibles après la suppression
    this.updateAvailableOperators();
  }

  /**
   * Validates the form by checking both the form controls and if there are any selected operators.
   * @returns {boolean} True if the form is valid, false otherwise.
   */

  isFormValid(): boolean {
    return this.operatorForm.valid && this.selectedOperators.length > 0;
  }

  /**
   * Opens a modal to add a work diary entry for a selected operator.
   * @param operator The operator for which the work diary is being added.
   */

  addWorkDiary(operator: any) {
    this.currentOperatorId = operator.id; // Store the current operator's ID

    const modalRef = this.modalService.open(WorkDiaryComponent, {
      size: "xl",
    });

    modalRef.componentInstance.operator = operator;
    modalRef.componentInstance.intervention = this.item;

    modalRef.result.then(
      (totalDuration: number | null) => {
        if (totalDuration !== null) {
          // Ensure it's not null
          this.updateTotalDuration(totalDuration);
        } else {
          // Optionally handle the case where the modal was closed without changes
          this.resetTotalDuration(); // Reset or keep previous duration
        }
      },
      (reason) => {
        // Optionally handle modal dismissal if needed
      }
    );
  }

  resetTotalDuration() {
    const operator = this.selectedOperators.find(
      (op) => op.id === this.currentOperatorId
    );
    if (operator) {
      operator.totalWorkDuration = "0h 0m";
    }
  }

  updateTotalDuration(newDurationInMinutes: number) {
    if (newDurationInMinutes < 0) return; // Validate input

    const operator = this.selectedOperators.find(
      (op) => op.id === this.currentOperatorId
    );

    if (operator) {
      // Parse existing duration to minutes if it was set previously
      const existingDurationParts = operator.totalWorkDuration.split("h");
      const existingHours = parseInt(existingDurationParts[0]) || 0;
      const existingMinutes = parseInt(existingDurationParts[1]) || 0;

      // Calculate total minutes
      const totalMinutes =
        existingHours * 60 + existingMinutes + newDurationInMinutes;

      // Convert back to hours and minutes
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      operator.totalWorkDuration = `${hours}h ${minutes}m`; // Format as "Xh Ym"
    }

    // this.totalDuration += newDurationInMinutes;
    this.operatorForm.patchValue({
      duration: this.calculateTotalDuration(),
    });
  }

  /**
   * Fetch interventions types
   */
  private fetchInterventionTypes(): void {
    const url = `${M_BASE_URL}/interventionType`;
    this.crudService.getAll(url).subscribe(
      (data: any) => {
        this.interventionTypes = data.data.map((item) => ({
          id: item.id,
          name: item.name,
          type: item.type,
        }));
      },
      (error) => console.error("Error fetching intervention types:", error)
    );
  }

  /**
   * Fetch interventions natures
   */
  private fetchInterventionNatures(): void {
    const url = `${M_BASE_URL}/interventionNature/nature`;
    this.crudService.getAll(url).subscribe(
      (data: any) => {
        this.interventionNatures = data;
        this.interventionNaturesFiltred = [...this.interventionNatures];
      },
      (error) => console.error("Error fetching intervention types:", error)
    );
  }

  save() {
    this.submitted = true;
    // this.operatorError = this.selectedOperators.length === 0;

    if (this.operatorForm.invalid) {
      return;
    } else {
      const formValue = this.operatorForm.value;
      const date = formValue.date;
      const time = formValue.time;
      // const totalDuration = this.calculateTotalDuration();
      let duration = formValue.duration;
      if (formValue.type === 1) {
        duration = this.calculateTotalDuration();
      }

      // Prepare spare parts with correct structure
      const preparedSpareParts = this.selectedSparePart.map((sp) => ({
        quantity: sp.quantity,
        state: sp.state || 0,
        sparePartId: sp.sparePartId,
        vehicleId: sp.vehicleId,
        interventionId: null, // Will be set by backend
      }));

      const localDate = new Date(
        date.year,
        date.month - 1,
        date.day,
        time.hour,
        time.minute
      );

      // Adjust the time by the local timezone offset
      const intervention = {
        ...formValue,
        date: new Date(
          localDate.getTime() - localDate.getTimezoneOffset() * 60000
        ).toISOString(),

        duration: duration,
        workOrderId: this.isEditMode ? this.item.workOrderId : this.itemId,
        techniciens: this.selectedOperators.map((op) => ({ id: op.id })),
        interventionSpartParts: preparedSpareParts,
      };

      if (formValue.type === 0) {
        delete intervention.supervisor; // Remove supervisor if it exists
        intervention.provider = formValue.provider;
      } else if (formValue.type === 1) {
        delete intervention.provider; // Remove provider if it exists
        intervention.supervisor = formValue.supervisor;
      }

      const url = this.isEditMode
        ? `${M_BASE_URL}/intervention/update`
        : `${M_BASE_URL}/intervention/add`;

      if (this.isEditMode) {
        this.crudService.update(url, this.item.id, intervention).subscribe(
          (response) => {
            this.listUpdated.emit();
            this.activeModal.close(response);
          },
          (error) => {
            console.error("Error updating intervention:", error);
          }
        );
      } else {
        this.crudService.post(url, intervention).subscribe(
          (response) => {
            this.activeModal.close(response);

            const workOrderData = { status: 2 };
            this.crudService
              .update(
                `${M_BASE_URL}/workOrder/updateStatus`,
                this.itemId,
                workOrderData
              )
              .subscribe(
                () => {
                  this.listUpdated.emit();
                },
                (error) => {
                  console.error("Error updating work order status:", error);
                }
              );
          },
          (error) => {
            console.error("Error adding intervention:", error);
          }
        );
      }
    }
  }

  calculateTotalDuration(): string {
    let totalMinutes = 0;

    this.selectedOperators.forEach((operator) => {
      if (
        operator.totalWorkDuration &&
        typeof operator.totalWorkDuration === "string"
      ) {
        const durationParts = operator.totalWorkDuration.split("h");
        if (durationParts.length === 2) {
          const hours = parseInt(durationParts[0]) || 0;
          const minutes = parseInt(durationParts[1]) || 0;
          totalMinutes += hours * 60 + minutes;
        }
      }
    });

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  }

  onInterventionTypeChange(event: any): void {
    this.interventionNaturesFiltred = this.interventionNatures.filter(
      (it) => it.interventionTypeId == event.id
    );
  }

  onInterventionNatureChange(event: any): void {
    const selectedNature = this.interventionNatures.find(
      (nature) => nature.id === event.id
    );
    if (selectedNature) {
      this.operatorForm
        .get("interventionTypeId")
        .setValue(selectedNature.interventionTypeId, {
          emitEvent: false,
        });
    }
  }
  getVehicles() {
    const url = `${BASE_URL_FLEET}/vehicles?username=${encodeURIComponent(
      this.username
    )}`;
    this.crudService.getAll<any>(url).subscribe((data) => {
      this.vehicles = data.data;
    });
    if (this.item) {
      this.loadInterventionData();
    }
  }

  cancelAddSparePart() {
    this.showSparePartForm = false;
    this.operatorForm.patchValue({
      sparePart: "",
      quantity: null,
      state: 1,
      selectedVehicleId: null,
      selectedVehicle: null,
    });
  }

  saveSparePart() {
    const sparePartId = this.operatorForm.get("sparePart")?.value;

    const selectedSparePart = this.sparePartList.find(
      (sp) => sp.id === sparePartId
    );
    const quantity = this.operatorForm.get("quantity")?.value;
    const state = this.operatorForm.get("state")?.value;
    const selectedVehicleId = this.operatorForm.get("selectedVehicleId")?.value;
    const selectedVehicle = this.vehicles.find(
      (v) => v.id == selectedVehicleId
    );

    this.selectedSparePart.push({
      quantity: quantity || 0,
      state: state,
      sparePartId: sparePartId || null,
      sparePartName: selectedSparePart?.name || "",
      vehicleId: state == 0 ? selectedVehicleId : null,
      vehicleNumber:
        state == 0 && selectedVehicle ? selectedVehicle.vehicleNumber : null,
    });

    this.showSparePartForm = false;

    this.operatorForm.patchValue({
      sparePart: null,
      quantity: null,
      state: 1,
      selectedVehicleId: null,
      selectedVehicle: null,
    });
  }
  addSparePart() {
    if (this.viewMode) return;
    this.showSparePartForm = true;
  }

  removeSparePart(sparePart: any) {
    if (this.viewMode) return;
    const indexToBeRemoved = this.selectedSparePart.indexOf(sparePart);

    if (indexToBeRemoved !== -1) {
      this.selectedSparePart.splice(indexToBeRemoved, 1);
    }
  }

  addNewSparePart = (term: string) => {
    return new Promise<any>((resolve) => {
      const existingSparePart = this.sparePartList.find(
        (sp) => sp.name.toLowerCase() === term.toLowerCase()
      );

      if (existingSparePart) {
        resolve({
          id: existingSparePart.id,
          name: existingSparePart.name,
        });
        return;
      }

      const newSparePart = {
        name: term,
      };

      this.crudService
        .post(`${M_BASE_URL}/sparePart/add`, newSparePart)
        .subscribe(
          (response) => {
            const newSparePartItem = {
              id: response.id,
              name: response.name,
            };
            this.sparePartList.push(newSparePartItem);
            // Mettre à jour le formulaire avec l'objet complet
            this.operatorForm.patchValue({
              sparePart: newSparePartItem.id,
            });

            // Retourner l'objet complet
            resolve(newSparePartItem);
          },
          (error) => {
            console.error(
              "Erreur lors de la création de la pièce de rechange",
              error
            );
            resolve(null);
          }
        );
    });
  };

  completeIntervention(id: number) {
    const status = 2; // Statut "Terminé"
    this.crudService.changeState(id, status).subscribe(
      (response) => {
        this.activeModal.close(response);
        this.listUpdated.emit();
      },
      (error) => {
        console.error("Error updating the status", error);
        this.listUpdated.emit();
      }
    );
    this.activeModal.close();
  }

  conformIntervention(id: number) {
    const conformity = this.operatorForm.get("conformity")?.value;
    const noConformityReason =
      this.operatorForm.get("noConformityReason")?.value;

    this.crudService
      .updateConformity(id, conformity, noConformityReason)
      .subscribe(
        (response) => {
          this.listUpdated.emit();
        },
        (error) => {
          console.error("Error updating the conformity", error);
          this.listUpdated.emit();
        }
      );
    this.activeModal.close();
  }
}
