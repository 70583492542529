<div class="modal-header mb-2 d-flex align-items-center justify-content-start gap-1">

  <h4 class="modal-title d-flex justify-content-start">{{"Work_Order"|translate}} - <span class="font-weight-bolder"
      *ngIf="item"> #{{ item?.number
      }}</span></h4>
  <span *ngIf="item?.status === 1" class="badge bg-light-secondary">{{ 'Open' | translate }}</span>
  <span *ngIf="item?.status === 3" class="badge bg-light-succes text-sm">{{ 'status_completed' | translate }}</span>
  <span *ngIf="item?.status === 2" class="badge bg-light-primary">{{ 'status_in_progress' | translate }}</span>

  <div class="d-flex justify-content-end ml-auto">
    <button *ngIf="item?.status === 3 && item?.conformity === 3" type="button" class=" btn btn-info "
      (click)="reOpenOT()" [appHasPermission]="['reopen_work_order']">
      <span class="mr-1"><i class="fa fa-undo "></i></span>{{ 'Reopen' | translate }}
    </button>
  </div>
</div>

<div class="modal-body " *ngIf="item">
  <form [formGroup]="workOrderForm">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-info-circle mr-2 align-middle"></i>
        <span class="text-bold-600">{{"General_information" | translate}}</span>
      </div>
      <div class="card-body">
        <div class="row mb-1">
          <div class="col-md-8 col-12">
            <div class="row mb-2">
              <div class="col-md-6 col-12 mb-2">
                <span class="text-bold-600">{{"Date"| translate}}</span>
                <br>
                <span>{{item?.date | date: 'yyyy-MM-dd'}}</span>
              </div>
              <div class="col-md-6 col-12 mb-2">
                <span class="text-bold-600">{{"Requestor" | translate}}</span>
                <br>
                <span>{{item.requestor}}</span>
              </div>
            </div>
            <div class="row mb-2">
              <div *ngIf="item.vehicles" class="col-md-6 col-12 mb-2">
                <span class="text-bold-600">{{"Vehicle"| translate}}</span>
                <br>
                <span>{{item.vehicles.vehicleNumber}} - {{item.vehicles.model.vehicleBrand.brandName}}
                  | {{item.vehicles.model.name}} | {{item.vehicles.registration}}</span>
              </div>
              <div class="col-md-6 col-12 mb-2">
                <span class="text-bold-600">{{"Description"| translate}}</span>
                <br>
                <span> {{item.description}}</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6 col-12 mb-2">
                <span class="text-bold-600">{{"OPERATIONS_TABLE.MAINTENANCE_TYPE"| translate}}</span>
                <br>
                <span> {{item.nameTypeMaintenance}}</span>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <!-- work request -->
            <div class="card my-0 border" *ngIf="linkedWorkRequest">
              <!-- i need WR number !! TODO -->
              <div class="card-body">
                <div class="d-flex justify-content-between mb-2">
                  <span (click)="viewWorkOrderDetail()" class="badge bg-light-info cursor-pointer">{{"Demande de travail
                    -" | translate}} #{{linkedWorkRequest.id}}</span>
                </div>
                <!-- the operation of the WO -->
                <p *ngIf="linkedWROperation">
                  Operation: {{ linkedWROperation.nameNatureIntervention }}
                </p>

                <!-- <div *ngIf="task.messageType === 'image'"
                  class="d-flex justify-content-center overflow-hidden rounded my-3">
                  <img [src]="task.taskMessage" alt="ToDo Pic" height="350">
                </div> -->

                <div *ngIf="linkedWROperation" class="d-flex justify-content-between align-items-center">
                  <div class="d-flex font-small-2">
                    <div class="mr-2"><i class="ft-clock mr-1"></i>{{linkedWROperation.createdAt | date }}</div>
                    <!-- <div class="mr-2" *ngIf="task.messageCount != ''"><i
                        class="ft-message-square mr-1"></i>{{task.messageCount}}</div>
                    <div *ngIf="task.linkCount != ''"><i class="ft-link mr-1"></i>{{task.linkCount}}</div> -->
                  </div>
                  <!-- <ul class="list-unstyled users-list m-0 d-flex align-items-center" *ngIf="task.isUserImg">
                    <li *ngFor="let img of task.assignedTo">
                      <img class="rounded-circle" [src]="img" alt="Avatar" height="24" width="24">
                    </li>
                  </ul> -->
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <!-- <i class="ft-settings mr-2 align-middle"></i> -->
        <i class="fa fa-tools mr-2 align-middle"></i>
        <span class="text-bold-600">{{"Interventions" | translate}}</span>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ 'Intervention_Number' | translate }}</th>
                <th scope="col">{{ 'Start_Date' | translate }}</th>
                <th scope="col">{{ 'Responsible' | translate }} / {{"Intervention_Provider" | translate}}</th>
                <th scope="col">{{ 'Operator' | translate }}</th>
                <th scope="col">{{ 'Status' | translate }}</th>
                <th scope="col">{{ 'Conformity' | translate }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of paginatedInterventions" class="cursor-pointer">
                <td>
                  <span class="badge badge-secondary">{{ item.number }}</span>
                </td>
                <td>
                  <span>{{ item.date | date: 'dd/MM/yyyy HH:mm' }} </span>
                </td>
                <td>
                  {{ item.supervisor }} {{item.provider}}
                </td>
                <td>
                  <span *ngIf="item.techniciens.length === 0">_</span>
                  <!-- Display all technicians' matricule, firstName, lastName -->
                  <span *ngFor="let tech of item.techniciens; let isLast = last">
                    {{ tech.matricule }} - {{ tech.firstName }} {{ tech.lastName }}
                    <span *ngIf="!isLast">, </span> <!-- Add a comma between technicians, but not after the last one -->
                  </span>
                </td>
                <td>
                  {{
                  item.status === 2 ?
                  ('status_completed' | translate) :
                  ('status_in_progress' | translate)
                  }}
                </td>

                <td>
                  {{
                  item.conformity === 2 ?
                  ('CONFORM' | translate) :
                  item.conformity === 3 ?
                  ('NOT_CONFORM'| translate) :
                  ''
                  }}
                </td>

                <td>
                  <div class="position-relative d-flex align-items-center cursor-pointer">
                    <div ngbDropdown display="dynamic" placement="left" class="d-inline-block">
                      <em class="fas fa-ellipsis-h mt-1" id="chatOptions" ngbDropdownToggle></em>
                      <div ngbDropdownMenu aria-labelledby="chatOptions">
                        <a *ngIf="item.status !== 2 && item.status !== 3" (click)="editIntervention(item)"
                          class="dropdown-item" [appHasPermission]="['update_work_order']">{{"Edit"|translate}}</a>
                        <a *ngIf="item.status === 2 || item.status === 3" (click)="viewInterventionDetails(item)"
                          class="dropdown-item" [appHasPermission]="['view_details_intervention']">
                          {{"View_DETAILS"|translate}}
                        </a>
                        <a *ngIf="item.status === 1" (click)="completeInterventionView(item)"
                          [appHasPermission]="['finish_intervention']" class="dropdown-item">
                          {{"COMPLETE"|translate}}</a>
                        <a *ngIf="item?.status !== 2" class="dropdown-item danger"
                          (click)="openInterventionDeleteModal(item.id,'intervention',item.number)"
                          [appHasPermission]="['update_work_order']">{{"DELETE"|translate}}</a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div *ngIf="item?.status !== 3" class="px-2 py-1 d-flex align-content-center flex-wrap text-white">
          <button type="button" class="btn bg-info px-2 w-100" (click)="addIntervention(item.id)"
            [appHasPermission]="['update_work_order']">
            <em class="ft-plus-circle mx-1 white"></em> <span class="text-white">{{ 'Add_Intervention' |
              translate}}</span>
          </button>
        </div>
        <!--<div class="d-flex justify-content-end flex-wrap pt-2">
          <ngb-pagination [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="onPageChange($event)"
            [collectionSize]="interventionsLength"></ngb-pagination>
          <ng-select [(ngModel)]="pageSize" (ngModelChange)="onPageChange($event)" [clearable]="false"
            [ngModelOptions]="{standalone: true}">
            <ng-option [value]="5">5 / page</ng-option>
            <ng-option [value]="10">10 / page</ng-option>
            <ng-option [value]="15">15 / page</ng-option>
          </ng-select>
        </div>-->
        <app-pagination [collectionSize]="interventionsLength" [(pageSize)]="pageSize" [(currentPage)]="page"
          (currentPageChange)="onPageChange($event)" (pageSizeChange)="onPageChange($event)">
        </app-pagination>
      </div>
    </div>


    <div *ngIf="item?.status === 3" class="card" [appHasPermission]="['conformity_work_order']">
      <div class="card-header">
        <i class="fa fa-check-circle mr-2 align-middle"></i>
        <span class="text-bold-600">{{"CONFORMITY" | translate}}</span>
      </div>


      <div class="card-body">
        <div class="form-group">
          <div class="form-check-inline mr-5">
            <input class="form-check-input" type="radio" id="otconform" formControlName="conformity" [value]="2" />
            <label class="form-check-label" for="otconform">
              {{ 'CONFORM' | translate }}
            </label>
          </div>

          <div class="form-check-inline">
            <input class="form-check-input" type="radio" id="otnotconform" formControlName="conformity" [value]="3" />
            <label class="form-check-label" for="otnotconform">
              {{ 'NOT_CONFORM' | translate }}
            </label>
          </div>
        </div>

        <div *ngIf="workOrderForm.get('conformity')?.value === 3" class="mt-3">
          <label for="noConformityReason">{{ 'NO_CONFORMITY_REASON' | translate }}</label>
          <input type="text" id="noConformityReason" formControlName="noConformityReason" class="form-control"
            [placeholder]="'Enter reason for non-conformity' | translate"
            [ngClass]="{ 'is-invalid': submitted && workOrderForm.get('noConformityReason')?.errors?.['required'] }" />
          <div class="invalid-feedback"
            *ngIf="submitted && workOrderForm.get('noConformityReason')?.errors?.['required']">
            {{ 'NO_CONFORMITY_REASON_REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- <div class="modal-footer  ">
  <button   type="button" class="btn btn-primary" (click)="save()"><i class="ft-check-square mr-1"></i>{{"Save" |
    translate}}</button>
  <button type="button" class="btn btn-secondary" (click)="cancel()"><i class="ft-x mr-1"></i>{{ 'CANCEL' |
    translate }}</button>
  <button *ngIf="item?.status !== 3" type="button" class="btn btn-secondary" [disabled]="!allInterventionsCompleted" (click)="terminerOT()"><i class="ft-x mr-1"></i>{{ 'Finish_OT' |
    translate }}</button>
</div> -->


<div class="modal-footer">
 
  <button *ngIf="item?.status === 3 && item?.conformity !== 2 && item?.conformity !== 3" type="button" class="btn btn-primary" (click)="save()">
    <i class="ft-check-square mr-1"></i>{{"Save" | translate}}
  </button>

  <button *ngIf="item?.status !== 3 && allInterventionsCompleted" type="button" class="btn btn-success"
    (click)="terminerOT()" [appHasPermission]="['finish_work_order']">
    <i class="ft-x mr-1"></i>{{ 'Finish_OT' | translate }}
  </button>

  <button type="button" class="btn btn-secondary" (click)="cancel()">
    <i class="ft-x mr-1"></i>
    {{ item?.status === 3 && item?.conformity !== 2 && item?.conformity !== 3 ? ('CANCEL' | translate) : ('RETURN' | translate) }}
  </button>
</div>