import { DecimalPipe, formatDate } from "@angular/common";
import { Injectable, PipeTransform } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap, map } from "rxjs/operators";
import { SortDirection } from "app/shared/directives/sortable.directive";
import { TripsInstances } from "../models/trips-instances.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { OPN_BASE_URL } from "app/shared/global/var";
import { AuthService } from "app/shared/auth/auth.service";

/**
 * Interface representing the structure of a search result.
 */
interface SearchResult {
  trips: TripsInstances[];
  total: number;
}

/**
 * Interface representing the state of the TripsInstancesService.
 */
interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: SortDirection;
}

/**
 * Interface representing grouped trips by departure date.
 */
export interface GroupedTrips {
  departure: string;
  trips: TripsInstances[];
}

/**
 * Compares two values for sorting purposes.
 * @param v1 The first value to compare.
 * @param v2 The second value to compare.
 * @returns -1 if v1 < v2, 1 if v1 > v2, 0 if equal.
 */
function compare(v1, v2) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

/**
 * Sorts an array of TripsInstances based on a specified column and direction.
 * @param trips Array of TripsInstances to sort.
 * @param column The column to sort by.
 * @param direction The sort direction ('asc' or 'desc').
 * @returns Sorted array of TripsInstances.
 */
function sort(
  trips: TripsInstances[],
  column: string,
  direction: string
): TripsInstances[] {
  if (direction === "" || column === "departure") {
    return trips;
  } else {
    return [...trips].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === "asc" ? res : -res;
    });
  }
}

/**
 * Checks if a trip matches the given search term.
 * @param trip The TripsInstances object to check.
 * @param term The search term to match against.
 * @param pipe A PipeTransform instance for number formatting.
 * @returns True if the trip matches the search term, false otherwise.
 */
function matches(trip: TripsInstances, term: string, pipe: PipeTransform) {
  const termLower = term.toLowerCase();

  return (
    (trip.bus && pipe.transform(trip.bus.vehicleNumber).includes(term)) ||
    pipe.transform(trip.lineNumber.toString()).includes(term) ||
    trip.tripRoute.toLowerCase().includes(termLower) ||
    trip.lineDirection.toLowerCase().includes(termLower) ||
    trip.plannedDeparture.toLowerCase().includes(termLower) ||
    trip.estimatedArrival.toLowerCase().includes(termLower) ||
    trip.dayOfWeek.toLowerCase().includes(termLower) ||
    (trip.driver &&
      (trip.driver.firstName.toLowerCase().includes(termLower) ||
        trip.driver.lastName.toLowerCase().includes(termLower))) ||
    (trip.receiver &&
      (trip.receiver.firstName.toLowerCase().includes(termLower) ||
        trip.receiver.lastName.toLowerCase().includes(termLower)))
  );
}

/**
 * Service for managing and querying trip instances.
 * This service provides functionality for searching, sorting, and filtering trip instances.
 */
@Injectable({
  providedIn: "root",
})
export class TripsInstancesService {
  private username: string | null;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _tripsList$ = new BehaviorSubject<TripsInstances[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  /**
   * A BehaviorSubject that holds the list of trips grouped by departure date.
   * It emits an array of GroupedTrips objects, where each object represents
   * trips for a specific departure date.
   */
  private _groupedTripsList$ = new BehaviorSubject<GroupedTrips[]>([]);
  private _count$ = new BehaviorSubject<GroupedTrips[]>([]);

  /**
   * A BehaviorSubject that holds a set of unique dates for which search results exist.
   * It emits a Set of strings, where each string represents a date in 'yyyy-MM-dd' format.
   * This is useful for showing which dates have trip data in calendar views or date pickers.
   */
  private _searchResultDates$ = new BehaviorSubject<Set<string>>(new Set());

  /**
   * A BehaviorSubject that holds the currently selected driver IDs for filtering.
   * It emits an array of numbers, where each number is a driver's unique identifier.
   * Used to filter trips based on specific drivers.
   */
  private _selectedDriverIds$ = new BehaviorSubject<number[]>([]);

  /**
   * A BehaviorSubject that holds the currently selected receiver IDs for filtering.
   * It emits an array of numbers, where each number is a receiver's unique identifier.
   * Used to filter trips based on specific receivers.
   */
  private _selectedReceiverIds$ = new BehaviorSubject<number[]>([]);

  /**
   * A BehaviorSubject that holds the currently selected line numbers for filtering.
   * It emits an array of numbers, where each number represents a bus line number.
   * Used to filter trips based on specific bus lines.
   */
  private _selectedLineNumbers$ = new BehaviorSubject<number[]>([]);

  /**
   * A BehaviorSubject that holds the currently selected trip statuses for filtering.
   * It emits an array of numbers, where each number represents a trip status code.
   * Used to filter trips based on their current status (e.g., scheduled, in progress, completed).
   */
  private _selectedStatuses$ = new BehaviorSubject<number[]>([]);

  /**
   * A BehaviorSubject that holds the start date for the date range filter.
   * It emits either a string representing a date in a specific format (e.g., 'yyyy-MM-dd'),
   * or null if no start date is set. Used to filter trips that occur on or after this date.
   */
  private _startDate$ = new BehaviorSubject<string | null>(null);

  /**
   * A BehaviorSubject that holds the end date for the date range filter.
   * It emits either a string representing a date in a specific format (e.g., 'yyyy-MM-dd'),
   * or null if no end date is set. Used to filter trips that occur on or before this date.
   */
  private _endDate$ = new BehaviorSubject<string | null>(null);

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: "",
    sortColumn: "",
    sortDirection: "asc",
  };

  /**
   * Constructor for TripsInstancesService.
   * Initializes the service and sets up the search mechanism.
   * @param http HttpClient for making API requests.
   * @param pipe DecimalPipe for number formatting.
   */
  constructor(
    private http: HttpClient,
    private pipe: DecimalPipe,
    private authService: AuthService
  ) {
    this.username = this.authService.getUsernameFromToken();
    if (!this.username) {
      console.error("No username found in token");
    }
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._tripsList$.next(result.trips);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  // Getters for the observables
  get tripList$() {
    return this._tripsList$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get groupedTripList$() {
    return this._groupedTripsList$.asObservable();
  }
  get count$() {
    return this._count$.asObservable();
  }
  get searchResultDates$() {
    return this._searchResultDates$.asObservable();
  }

  // Setters to update the state and trigger a search
  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: string) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  /**
   * Updates the state and triggers a new search.
   * @param patch Partial State object to update.
   */
  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  /**
   * Performs the search based on the current state.
   * @returns An Observable of SearchResult.
   */
  public _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } =
      this._state;
    return this.fetchTripsInstances(
      sortColumn,
      sortDirection,
      page,
      pageSize
    ).pipe(
      map((result: any) => {
        let trips = result.data;
        const total = result.count;

        trips = trips.filter((trip) => matches(trip, searchTerm, this.pipe));
        trips = sort(trips, sortColumn, sortDirection);

        const groupedTrips = this.groupTripsByDeparture(trips);
        this._groupedTripsList$.next(groupedTrips);
        this._count$.next(total);

        const searchResultDates = new Set<string>(
          trips.map((trip) =>
            formatDate(trip.plannedDeparture, "yyyy-MM-dd", "en-US")
          )
        );
        this._searchResultDates$.next(searchResultDates);

        return { trips, total };
      })
    );
  }

  /**
   * Fetches trip instances from the API.
   * @param sortColumn The column to sort by.
   * @param sortDirection The direction of sorting ('asc' or 'desc').
   * @param page The page number for pagination.
   * @param perPage Number of items per page.
   * @returns An Observable of the API response.
   */
  fetchTripsInstances(
    sortColumn: string,
    sortDirection: string,
    page: number,
    perPage: number
  ): Observable<any> {
    const apiUrl = `${OPN_BASE_URL}/trips-instance`;

    if (!this.username) {
      console.error("No username found in token");
      return new Observable();
    }

    let params = new HttpParams()
      .set("column", sortColumn)
      .set("order", sortDirection)
      .set("page", page.toString())
      .set("perPage", perPage.toString())
      .set("username", this.username);

    const driverIds = this._selectedDriverIds$.getValue();
    const receiverIds = this._selectedReceiverIds$.getValue();
    const lineNumbers = this._selectedLineNumbers$.getValue();
    const statuses = this._selectedStatuses$.getValue();
    const startDate = this._startDate$.getValue();
    const endDate = this._endDate$.getValue();

    if (driverIds && driverIds.length > 0) {
      driverIds.forEach((id) => {
        params = params.append("driverIds", id);
      });
    }
    if (receiverIds && receiverIds.length > 0) {
      receiverIds.forEach((id) => {
        params = params.append("receiverIds", id);
      });
    }
    if (lineNumbers && lineNumbers.length > 0) {
      lineNumbers.forEach((number) => {
        params = params.append("lineNumbers", number);
      });
    }
    if (statuses && statuses.length > 0) {
      statuses.forEach((status) => {
        params = params.append("statuses", status);
      });
    }
    if (startDate) params = params.set("startDate", startDate);
    if (endDate) params = params.set("endDate", endDate);

    return this.http.get<any>(apiUrl, { params });
  }

  /**
   * Fetches all trip instances.
   * @returns An Observable of all trip instances.
   */

  fetchAllTripsInstances(): Observable<TripsInstances[]> {
    if (!this.username) {
      console.error("No username found in token");
      return new Observable();
    }

    const params = new HttpParams().set("username", this.username);
    const apiUrl = `${OPN_BASE_URL}/trips-instance/list`;

    return this.http.get<TripsInstances[]>(apiUrl, { params });
  }

  /**
   * Groups trip instances by their departure dates.
   * @param trips An array of trip instances to be grouped.
   * @returns An array of grouped trips with departure dates as keys.
   */
  private groupTripsByDeparture(trips: TripsInstances[]): GroupedTrips[] {
    const grouped = trips.reduce((acc, trip) => {
      const key = formatDate(trip.plannedDeparture, "yyyy-MM-dd", "en-US");
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(trip);
      return acc;
    }, {} as Record<string, TripsInstances[]>);

    return Object.keys(grouped).map((departure) => ({
      departure,
      trips: grouped[departure],
    }));
  }

  /**
   * Fetches trip instances for a specific bus.
   * @param busId The ID of the bus.
   * @returns An Observable of trip instances for the specified bus.
   */
  getTripInstancesByBusId(busId: number): Observable<TripsInstances[]> {
    const url = `${OPN_BASE_URL}/trips-instance/bus/${busId}`;
    return this.http.get<TripsInstances[]>(url);
  }

  /**
   * Fetches trip instances based on a specific line number.
   * @param lineNumber The line number for which trip instances are fetched.
   * @returns An Observable of trip instances for the specified line number.
   */
  getTripInstancesByLineNumber(
    lineNumber: number
  ): Observable<TripsInstances[]> {
    const url = `${OPN_BASE_URL}/trips-instance/line/${lineNumber}`;
    return this.http.get<TripsInstances[]>(url);
  }

  regenerateTrips(startDate: string, endDate: string): Observable<any> {
    return this.http.post(`${OPN_BASE_URL}/trips-instance/regenerate-trips`, { startDate, endDate });
  }

  /**
   * Sets the selected driver IDs for filtering and triggers a search.
   * @param driverIds Array of selected driver IDs.
   */
  setDriverIds(driverIds: number[]) {
    this._selectedDriverIds$.next(driverIds);
    this._search$.next();
  }

  /**
   * Sets the selected receiver IDs for filtering and triggers a search.
   * @param receiverIds Array of selected receiver IDs.
   */
  setReceiverIds(receiverIds: number[]) {
    this._selectedReceiverIds$.next(receiverIds);
    this._search$.next();
  }

  /**
   * Sets the selected line numbers for filtering and triggers a search.
   * @param lineNumbers Array of selected line numbers.
   */
  setLineNumbers(lineNumbers: number[]) {
    this._selectedLineNumbers$.next(lineNumbers);
    this._search$.next();
  }

  /**
   * Sets the selected statuses for filtering and triggers a search.
   * @param statuses Array of selected statuses.
   */
  setStatuses(statuses: number[]) {
    this._selectedStatuses$.next(statuses);
    this._search$.next();
  }

  /**
   * Sets the date range for filtering and triggers a search.
   * @param startDate The start date of the range.
   * @param endDate The end date of the range.
   */
  setDateRange(startDate: string | null, endDate: string | null) {
    this._startDate$.next(startDate);
    this._endDate$.next(endDate);
    this._search$.next();
  }
}
